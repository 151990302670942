import React, { useState, useEffect, useRef, memo } from "react";
import { WebService } from "../../Services/WebService";
import { useDispatch } from "react-redux";
import { PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import AddEditUser from "./AddEditUser";
import AssignProjectToUserComponent from "./AssignProjectToUserComponent";
import { WSInfoAlert, WSSuccessAlert } from "../../Services/WSAlert";
// import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";

const apiUrl = "User/OfCustomer";
const User = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "User" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  //   const permission = {
  //     ManageAdd: ActionPermission("Customer - Add"),
  //     ManageEdit: ActionPermission("Customer - Edit"),
  //     ManageDelete: ActionPermission("Customer - Delete"),
  //   };
  const fetchUser = async () => {
    await WebService({
      endPoint: `${apiUrl}/Fetch`,
      dispatch,
    }).then(setRecords);
    // dispatch(ConfigurationflowStatusUpdate());
  };

  const onDelete = async (UserId) => {
    await WebService({
      endPoint: `${apiUrl}/${UserId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchUser();
  };
  const addEditModalRef = useRef();
  const assignProjectToUserRef = useRef();
  useEffect(() => {
    fetchUser();
  }, []);
  const columns = [
    {
      Text: "Company Name",
      Value: "CustomerName",
    },
    {
      Text: "Name",
      Value: "FullName",
    },
    {
      Text: "Address",
      Value: "Address",
    },
    {
      Text: "Phone",
      Value: "Phone",
    },
    {
      Text: "Email",
      Value: "Email",
    },
    {
      Text: "Action",
      //   isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <span className="d-flex">
          <ActionButton
            onClick={() => fnEdit(dr.UserId)}
            // disabled={!permission.ManageEdit}
            IconName="Edit"
            id={`btnUserEdit_${dr.CustomerId}`}
          />

          <ActionButton
            onClick={() =>
              ref.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure", // Text if Alert
                "Do you want to delete " + MasterPageName, // Message of Alert
                dr.UserId
              )
            }
            // disabled={!permission.ManageDelete}
            IconName="Delete"
            id={`btnUserDelete_${dr.UserId}`}
          />
          <ActionButton
            onClick={async () => {
              await WebService({
                dispatch,
                endPoint: `Customer/Notification/WelcomeMail/${dr.UserId}`,
              }).then((r) => {
                WSSuccessAlert("Success", "Email Sent Successfully");
              });
            }}
            // disabled={!permission.ManageEdit}
            IconTooltip='Invite'
            IconName="EmailResend"
            id={`btnUserNotifyWelcomeMail_${dr.CustomerId}`}
          />
          <ActionButton
            onClick={async () => assignProjectToUserRef.current.openModal(dr)}
            // disabled={!permission.ManageEdit}
            IconName="Assign"
            IconTooltip='Assign Project'
            id={`btnUserNotifyWelcomeMail_${dr.CustomerId}`}
          />
        </span>
      ),
    },
  ];
  const [bData] = React.useState([
    {
      title: "Company",
      hrefLink: "#",
    },
    {
      title: "Customer Team Members",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Customer Team Members";
  const confirmMessage = MasterPageName + " Deleted successfully";

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.CustomerheaderValueNoResults}
            subHeaderValue={StandardConst.CustomerSubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {/* {permission.ManageAdd && ( */}
                <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_add_${MasterPageName}`}>
                  Add {MasterPageName}
                </Button>
                {/* )} */}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        // IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditUser
        callBackEvent={() => fetchUser()}
        ref={addEditModalRef}
      ></AddEditUser>
      <AssignProjectToUserComponent ref={assignProjectToUserRef} />
    </>
  );
};
export default memo(User);
