import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { StandardConst } from "./StandardConst";
import Cookies from "universal-cookie";
import { Avatar } from "@mui/material";
const CompanyInfoWide = ({
  isMobileVisible,
  section1,
  section2,
  section3,
  ...rest
}) => {
  isMobileVisible ??= false;
  section1 ??= 2;
  section2 ??= 6;
  section3 ??= 4;
  const cookies = new Cookies();
  const [companyInfo, setCompanyInfo] = useState();
  useEffect(() => {
    setCompanyInfo(cookies.get("company") ?? {});
  }, []);
  
  useEffect(() => {
    getCompanyLogo();
  }, [companyInfo?.Logo]);
  const [logosrc, setLogoSrc] = useState("");
  const getCompanyLogo = async () => {
    const companyLogoName = companyInfo?.Logo;
        const logoData = await fetch(`${StandardConst.apiBaseUrl}/Visitor/getLogo/${companyLogoName}`);
        const logoText = await logoData.text();
        setLogoSrc(logoText);
  }
  
  return (
    <>
      <Row
        // sx={{
        //   alignItems: "center",
        // }}
      >
        <Col xs={4} md={section1} className="col-12 m-auto">
        <Avatar
            className="object-fit-cover "
            sx={{ width: 95, height: 50,marginLeft:'auto', marginRight:'auto' }}
            alt={companyInfo?.CompanyName ?? {}}
            variant="rounded"
            src={logosrc}
          />
        </Col>
        <Col xs={4} md={section2} className="col-12">
          <h4 className="text-center">{companyInfo?.CompanyName ?? ""}</h4>
          <h6 className="text-center">
            {`${companyInfo?.Address1 ?? ""}, ${companyInfo?.Address2 ?? ""}, ${
              companyInfo?.Address3 ?? ""
            }`
              .replaceAll(", ,", "")
              .trim()}
          </h6>
          <h6 className="text-center">
            {`${companyInfo?.city ?? " "}, ${companyInfo?.State ?? ""} - ${
              companyInfo?.pincode ?? ""
            }`
              .replaceAll(",  - ", ", ")
              .replaceAll(" , ", "")}
          </h6>
        </Col>
        {isMobileVisible === false && (
          <Col xs={4} md={section3} className="col-12">
            <h6></h6>
            <h6 className="text-center">
              {" "}
              <i className="fa fa-phone mx-1 "></i>
              {companyInfo?.Phone}
            </h6>
            <h6 className="text-center">
              {" "}
              <i className="fa fa-envelope mx-1 "></i>
              {companyInfo?.Email}
            </h6>
          </Col>
        )}
      </Row>
    </>
  );
};

export default CompanyInfoWide;
