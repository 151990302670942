import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { format } from 'date-fns';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormInputDropdown, FormInputText } from "../Form";
import { WebService } from "../../Services/WebService";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { Alert, Chip } from "@mui/material";
import { CalculatePostedDate } from "./JobListing";
import { CurrencyFormat } from "../../Services/DisplayCurrencyFormat";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ArrowBack, LocationOn, Search } from "@mui/icons-material";
import JobHeader from "./JobHeader";
import { WSSuccessAlert } from "../../Services/WSAlert";
import { beautifyLocalTime } from "../../Services/StandardFunctions";

const JobView = (props, ref) => {
  const [show, setShow] = useState(true);
  const [jobData, setJobData] = useState({});
  const dispatch = useDispatch();
  const Roles = useSelector((s) => s.auth.AssignRole ?? {});
  const location = useLocation();
  const [jobApplicantId, setJobApplicantId] = useState(null);
  const [AppliedDate,setAppliedDate]=useState(false)
  console.log(location)
  const isJobApplicant = Roles?.some(
    (element) => element === StandardConst.SystemRole.JobApplicant
  );
  const UserId = useSelector((s) => s.auth.LoggedCustomer ?? {});
  const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? {});
  
  const [isApplied, setIsApplied] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  console.log(params);
  const FetchJobDetails = async () => {
    await WebService({
      endPoint: `Recruitment/fetchJobDetailsByJobId?JobId=${params.JobId}`,
      method: "GET",
      dispatch,
    }).then((res) => (res.length > 0 ? setJobData(res[0]) : null));
  };

  console.log(jobData)
  useEffect(() => {
    FetchJobDetails();
    setIsApplied(location.state.isApplied)
  }, []);

  useEffect(()=>{
      if(UserId)
      { console.log('calling this function getJobApplicantIdByUserId ')
        getJobApplicantIdByUserId(UserId)
      }
  },[UserId])
  useEffect(()=>{
if(jobApplicantId && jobData.JobId)
{
  getJobApplicantData()

}
  },[jobApplicantId,jobData])

  const getJobApplicantData=async()=>{
    await WebService({
      endPoint: `CommonUtility/Edit/jobapplications?JobApplicantId=${jobApplicantId}&JobId=${jobData?.JobId}`,
  }).then(res =>
  {
  setAppliedDate(res[0].ApplicationDate);
  res?.length > 0 && setIsApplied(true)
  }
  );

  }
  const applyThisJob = async (JobId) => {
    jobApply(JobId);
  };


console.log(jobApplicantId)
  const getJobApplicantIdByUserId = async (userId) => {
      await WebService({
          endPoint: `CommonUtility/Edit/jobapplicants?UserId=${userId}`,
      }).then(res => res.length > 0 ? setJobApplicantId(res[0].JobApplicantId) : setJobApplicantId(null));
  };
  
       const setNotificationAndMail = async (JobId, jobApplicantId) => {
           const fetchJobData = await WebService({
               endPoint: `CommonUtility/Edit/jobs?jobId=${JobId}`,
               method: 'GET',
               dispatch
           }).then(res => res.length > 0 ? res[0] : null);
   
           const JobApplicantName = await WebService({
               endPoint: `Recruitment/fetchJobApplicantDetails?JobApplicantId=${jobApplicantId}`,
               method: 'GET',
               dispatch
           }).then(res => res.length > 0 ? res[0].FullName : null);
   
           // Here we send notification
           // if(fetchJobData !== null && fetchJobData.EmployeeId !== null && JobApplicantName !== null){
           //     await WebService({
           //         dispatch,
           //         endPoint: "Notification/sendSystemNotification",
           //         body: {
           //           Message: `${format(new Date(), 'dd-MM-yyyy')} - ${JobApplicantName} applied for ${fetchJobData.Title}`,
           //           SendTo: [fetchJobData.EmployeeId],
           //           Expire: addDays(new Date(), 30),
           //         },
           //     });
           // }
   
           // Here we send email
           if(fetchJobData !== null && fetchJobData.EmployeeId !== null && JobApplicantName !== null){
               await WebService({
                   dispatch,
                   endPoint: "Recruitment/SendMailForRecruiter",
                   method: "POST",
                   body: {
                       ApplicationDate: format(new Date(), 'dd-MM-yyyy'),
                       ApplicantName: JobApplicantName,
                       JobTitle : fetchJobData.Title,
                       EmployeeId: fetchJobData.EmployeeId
                   }
               });
           }
       }
      
   const jobApply = async(JobId) => {
    console.log(JobId)
          if(jobApplicantId !== null){
              const data = {
                  JobId : JobId,
                  JobApplicantId : jobApplicantId,
                  StatusId : StandardConst.Status.Applied,
                  ApplicationDate : format(new Date(), 'yyyy-MM-dd HH:mm:ss')
              }
  
              await WebService({
                  endPoint : `Recruitment/insertJobApplication`,
                  body : data,
                  method : 'POST',
                  dispatch
              }).then((res) => {
                  setNotificationAndMail(JobId, jobApplicantId);
                  setIsApplied(true)
                  getJobApplicantData()
                  WSSuccessAlert("Successfully Appplied");
              });
          }else{
              navigate("/NonCompanyUserLogin");
          }
      }
  const renderSalary = (data) => {
    switch (data.SalaryRange) {
      case "Exact Salary":
        return (
          <CurrencyFormat
            value={data.ExactSalary}
            CurrencyCode={data.CurrencyCode}
            LocaleCode={data.LocaleCode}
          />
        );

      case "Starting Salary":
        return (
          <CurrencyFormat
            value={data.MinimumSalary}
            CurrencyCode={data.CurrencyCode}
            LocaleCode={data.LocaleCode}
          />
        );

      case "Maximum Salary":
        return (
          <CurrencyFormat
            value={data.MaximumSalary}
            CurrencyCode={data.CurrencyCode}
            LocaleCode={data.LocaleCode}
          />
        );

      case "Range":
        return (
          <span>
            <CurrencyFormat
              value={data.MinimumSalary}
              CurrencyCode={data.CurrencyCode}
              LocaleCode={data.LocaleCode}
            />
            {" to "}
            <CurrencyFormat
              value={data.MaximumSalary}
              CurrencyCode={data.CurrencyCode}
              LocaleCode={data.LocaleCode}
            />
          </span>
        );
    }
  };

  return (
    <>
      {!UserId || !EmployeeId && <JobHeader />}
      <div className="container">
        <Form
          // defaultValues={{ Token: token }}
          onSubmit={(s) => {
            navigate(location?.state?.from || "/AllJobs", {
              state: { searchQuery: s },
            });
          }}
          // validationSchema={schema}
        >
          <div className=" py-1 d-flex justify-content-start text-align-center">
            <div
              className=" bg-white pt-2 pb-2 rounded m-0"
              style={{ width: "100%" }}
            >
              <div className="row m-0 d-flex justify-content-between text-align-center">
                <div className="col-md-3">
                  <div className="d-flex justify-content-center justify-content-md-start justify-content-lg-start align-items-center">
                    <Search fontSize="small" />
                    <FormInputText
                      name="TitleOrCompanySearch"
                      placeholder="Job Title or Company"
                      type="text"
                      isRequired="true"
                      className="border-0 form-control"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex justify-content-center justify-content-md-start justify-content-lg-start centerr align-items-center">
                    <LocationOn fontSize="small" />
                    <FormInputText
                      name="CityOrStateSearch"
                      placeholder="City, State"
                      type="text"
                      isRequired="true"
                      className="border-0"
                    />
                  </div>
                </div>
                {/* <div className="col-md-4">
                                            <div className="d-flex align-items-center">
                                                <PersonIcon fontSize="small"/>
                                                <div className="w-100">
                                                    <FormAutoCompleteDropdownCard
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                width: '100%',
                                                                padding: "1px 8px",
                                                                '& fieldset': {
                                                                    border: 'none', // Remove the default border
                                                                },
                                                                '&:hover fieldset': {
                                                                    border: 'none', // Remove border on hover
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    border: 'none', // Remove border when focused
                                                                },
                                                            },
                                                        }}
                                                        name="CustomerId"
                                                        data={chain(customerList ?? [])}
                                                        optionText="heading"
                                                        isRequired="true"
                                                        placeholder="Company"
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                <div className="col-md-2 d-flex justify-content-center justify-content-lg-end justify-content-md-end text-align-center">
                  <Button
                    type="submit"
                    style={{ borderRadius: "5px", background: "#2557a7" }}
                  >
                    Find Jobs
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className="container ">
        {Object.keys(jobData).length > 0 && (
          <div className="bg-white px-3 py-2 rounded-1">
            <p className="h1">{jobData?.Title}</p>
          
            <p>
              {jobData?.Location} &nbsp;
              {jobData?.OperatingArea && (
                <Chip
                  label={jobData?.OperatingArea}
                  sx={{
                    color: "grey",
                    padding: "0px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    backgroundColor: "rgba(0,125,0,0.1)",
                  }}
                  color="secondary"
                  size="small"
                />
              )}
            </p>

            {jobData?.JobLocationType && (
              <>
                <Chip
                  label={jobData?.JobLocationType}
                  sx={{
                    color: "grey",
                    padding: "0px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    backgroundColor: "rgba(0,125,0,0.1)",
                    marginBottom: "8px",
                  }}
                  color="secondary"
                  size="small"
                />
                &nbsp;
              </>
            )}

            {jobData?.JobTypes?.split(",").map((jobType, index) => (
              <Chip
                key={index}
                label={jobType}
                sx={{
                  color: "green",
                  padding: "0px",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  backgroundColor: "rgba(0,125,0,0.1)",
                  marginBottom: "8px",
                }}
                size="small"
              />
            ))}

            {jobData?.JobSchedules?.split(",").map((JobSchedule, index) => (
              <Chip
                key={index}
                label={JobSchedule}
                sx={{
                  color: "green",
                  padding: "0px",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  backgroundColor: "rgba(0,125,0,0.1)",
                  marginBottom: "8px",
                }}
                size="small"
              />
            ))}

            {(jobData?.SupplementalPays ||
              jobData?.Benifits ||
              jobData?.MinimumSalary ||
              jobData?.MaximumSalary ||
              jobData?.ExactSalary) && (
              <>
                <Alert severity="info" className="mb-3 w-100">
                  Pay and Benefits
                </Alert>
                <p className="mb-1">
                  {renderSalary(jobData)} {jobData?.Rate} &nbsp;
                  {jobData?.SupplementalPays?.split(",").map(
                    (SupplementalPay, index) => (
                      <Chip
                        key={index}
                        label={SupplementalPay}
                        sx={{
                          color: "green",
                          padding: "0px",
                          borderRadius: "5px",
                          fontWeight: "bold",
                          backgroundColor: "rgba(0,125,0,0.1)",
                        }}
                        size="small"
                      />
                    )
                  )}
                  {jobData?.Benifits?.split(",").map((Benifit, index) => (
                    <Chip
                      key={index}
                      label={Benifit}
                      sx={{
                        color: "green",
                        padding: "0px",
                        borderRadius: "5px",
                        fontWeight: "bold",
                        backgroundColor: "rgba(0,125,0,0.1)",
                      }}
                      size="small"
                    />
                  ))}
                </p>
              </>
            )}

            {jobData?.Skills && (
              <>
                <Alert severity="info" className="my-3 w-100">
                  Skills
                </Alert>
                {jobData?.Skills?.split(",").map((skill, index) => (
                  <Chip
                    key={index}
                    label={skill}
                    sx={{
                      color: "green",
                      padding: "0px",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      backgroundColor: "rgba(0,125,0,0.1)",
                      marginBottom: "8px",
                    }}
                    size="small"
                  />
                ))}
              </>
            )}

            {jobData?.Description && (
              <>
                <Alert severity="info" className="my-3 w-100">
                  Job Description
                </Alert>
                <div
                  dangerouslySetInnerHTML={{ __html: jobData?.Description }}
                />
              </>
            )}

            {jobData?.MinimumExperiance && (
              <>
                <Alert severity="info" className="my-3 w-100">
                  Experience
                </Alert>
                <p>
                  {parseInt(jobData?.MinimumExperiance) ===
                  parseInt(jobData?.MaximumExperiance)
                    ? jobData?.MinimumExperiance || jobData?.MaximumExperiance
                    : `${jobData?.MinimumExperiance} To ${jobData?.MaximumExperiance}`}
                  &nbsp;Years
                </p>
              </>
            )}

            {jobData?.NumberOfPeopleToHire && (
              <>
                <Alert severity="info" className="my-3 w-100">
                  No. of vacancies
                </Alert>
                <Chip
                  label={`${jobData?.NumberOfPeopleToHire} Position`}
                  sx={{
                    color: "grey",
                    padding: "0px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    backgroundColor: "rgba(0,125,0,0.1)",
                    marginBottom: "8px",
                  }}
                  color="success"
                  size="small"
                />
              </>
            )}
            <div className="d-flex text-secondary">
              Posted: &nbsp;
              <CalculatePostedDate dateTime={jobData?.PostedDate} />
            </div>

            <div className="mt-4 d-flex flex-row gap-1">
              {location?.state?.from && (
                <Button
                  id="btnback"
                  onClick={() => {
                    navigate(location?.state?.from, {
                      state: { searchQuery: {} },
                    });
                  }}
                >
                  <ArrowBack /> Back
                </Button>
              )}
                                {!EmployeeId && (
                        !isApplied ? (
                          <Button
                            id="btnApply"
                            type="submit"
                            onClick={() => {
                              applyThisJob(jobData?.JobId);
                            }}
                          >
                            Apply For This Job
                          </Button>
                        ) : (
                          <span className="mt-1 ms-1">
                            <Chip
                              label={`Applied on ${beautifyLocalTime(AppliedDate, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })}`}
                              sx={{ color: "white", padding: "0px" }}
                              color="success"
                              size="small"
                            />
                          </span>
                        )
                      )}

            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default forwardRef(JobView);
