
import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { WSErrorAlert, WSSuccessAlert, WSWarningAlert } from "../../Services/WSAlert";
import { StandardConst } from "../../Services/StandardConst";
import { AttachFileOutlined, Attachment, CloudUpload, Download, InfoOutlined } from "@mui/icons-material";
import { Alert } from "@mui/material";
import ActionButton from "../../Services/ActionButton";
import { Button as MuiButton } from '@mui/material';
const { forwardRef, useState, useImperativeHandle } = React;


const SignautureModal = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [openModalCount, setOpenModalCount] = useState(0);
  const [file, setFile] = useState(null);
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const [DocumentData,setDocumentData]=useState();
  const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });
  
  const companyInfo = useSelector((s) => s.auth.companyInfo ?? {});
  const regex = /\.([0-9a-z]+)$/i
  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setFile(null);
      // FetchEmployeeTableColoum();
      await  getCoordinates();
      setShow(true);
      if(id > 0){
        setShow(false);
        await fetch_Document_Details(id);
        setShow(true);
      }
    },
  }));
  

 const fetch_Document_Details=async(id)=>{
     const resultDocument= await WebService({
           endPoint: `Document/GetDocumentNameFromHistoryId/?Id=${id}`,
          method: "GET", 
          dispatch,
         }).then((res) => {
           if (res.DocumentData.length > 0) {
             setDocumentData(res.DocumentData[0]);
           }
         });
   }

 
  const handleClose = () => {
    setShow(false);
    setData({});
    setOpenModalCount(openModalCount+1);
  };
  const getCoordinates = async () => {
    console.log(navigator.geolocation)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ latitude, longitude });
        },
        (error) => {
          WSWarningAlert("",'Location is required for signature.')
        }
      );
    } else {
      WSWarningAlert("",'Location is required for signature.')
    }
  };
  
const SignDocument=async()=>{
  handleClose()
  let fileDetails
 

     // Upload the file and get the details
     if(file){ 
      fileDetails = await WebService({
       endPoint: "upload/File",
       body: file,
       isFile: true,
       dispatch,
     })
    }
     // Prepare data for updating document sent history
     let updateData = {
       SignDateTimestamp: new Date(),
       SignedDocumentFilePath: fileDetails?.filename || null,
     };
  
     updateData.Location=JSON.stringify(coordinates);
     // Update document sent history
     await WebService({
       endPoint: `CommonUtility/DocumentSentHistory?DocumentHistoryId=${DocumentData.DocumentHistoryId}`,
       method: "PUT",
       body: updateData,
       dispatch,
     });
 
     // Send notification
     const notificationData = {
       DocumentHistoryId: DocumentData?.DocumentHistoryId,
       EmployeeId: DocumentData.EmployeeId,
     };
 
     const notificationResult=await WebService({
       endPoint: `Document/SendNotification`,
       method: "POST",
       body: notificationData,
       dispatch,
     }).then((res)=>{
       WSSuccessAlert('',`Document '${DocumentData?.DocumentTemplateName}' has been  signed successfully `)
     })
     prop.callBackEvent();
}

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <div
          className="swal2-icon swal2-warning swal2-icon-show mt-0"
          style={{ display: "flex" }}
        >
          <div className="swal2-icon-content">!</div>
        </div>
        <h2
          class="swal2-title "
          id="swal2-title"
          style={{ display: "block", color: "#545454" }}
        >
          Confirm Document Signing
        </h2>
        <div
          className="swal2-html-container mx-0"
          id="swal2-html-container"
          style={{ display: "block", color: "#545454" }}
        >
          By clicking 'Yes, Sign it!', you confirm that you have reviewed the
          document 'appreciation letter' and agree to its terms.Your location will be recorded as part of your signature. This action is
          final and cannot be undone.
        </div>
        <div className="d-flex justify-content-center my-2">
          <label
            htmlFor="inputGroupFile04"
            className=" d-flex align-items-center gap-2 mb-2 mb-md-0"
          >
            <span>
              {file ? file.name : "Attach the Signed Document (if Required)"}
            </span>
            <input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              className="form-control d-none"
              id="inputGroupFile04"
            />
             {!file 
            &&
            <span className="btn btn-outline-primary ">
              <AttachFileOutlined />
            </span>
          }
          </label>
        {file && <ActionButton
            IconName="Close"
            IconTooltip="Remove file"
            onClick={() => {
              setFile();
            }}
          />}
         
        </div>
        <span></span>
        <span className="d-flex justify-content-center gap-2">
          <Button
            variant="secondary"
            style={{ fontWeight: "bold" }}
            onClick={() => {
              handleClose();
            }}
          >
            No, Review Again
          </Button>
          <Button
            variant="primary"
            style={{ fontWeight: "bold" }}
            onClick={async() => {
              SignDocument();

            }}
          >
            Yes, Sign it!
          </Button>
        </span>
      </Modal.Body>
    </Modal>
  );
};

export default forwardRef(SignautureModal);
