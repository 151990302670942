import React, { useEffect, useRef, useState } from "react";
import { StandardConst } from "../../Services/StandardConst";

import { useDispatch, useSelector } from "react-redux";
import SnackbarComponent from "../../Services/SnackbarComponent";
import { WebService } from "../../Services/WebService";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from "../../Services/ActionButton";
import { Container, Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { WSErrorAlert, WSSuccessAlert } from "../../Services/WSAlert";
import { render } from "react-dom";
import Swal from "sweetalert2";
import { TaskAlt, Verified } from "@mui/icons-material";
import SignautureModal from "./SignautureModal";
import Cookies from "universal-cookie";
import TableComponent, { NoRecordTemplate } from "../../Services/TableComponent";
const cookies = new Cookies();

const MyDocuments = () => {
  const [mydocuments, setmydocuments] = useState();
  const refSnackbar = useRef();
  const MasterPageName = "My Document";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const dispatch = useDispatch();
  const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });
  const SignModalRef = useRef();
  const FetchMyDocuments = async () => {
    await WebService({
      endPoint: `Document/MyDocuments`,
      method: "get",
    }).then((r) => setmydocuments(r));
  };
  const fnSignModal = async (id) => await SignModalRef.current.openModal(id || 0);

  useEffect(() => {
    FetchMyDocuments();
  }, []);

  const getCoordinates = async() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ latitude, longitude });
        },
        (err) => {
          WSErrorAlert(err.message);
          return;
        }
      );
    } else {
      WSErrorAlert("Geolocation is not supported by your browser.");
      return;
    }
  };
  function beautifyLocalTime(utcTimestamp) {
    // Convert the UTC timestamp string to a Date object
    const utcDate = new Date(utcTimestamp);
  
    // Use Intl.DateTimeFormat to format the date in the local time zone
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
  
    // Format the date
    return new Intl.DateTimeFormat(undefined, options).format(utcDate);
}

const SignDocument=async(dr)=>{
  let data={}
  getCoordinates()

  data.Location=JSON.stringify(coordinates);
  data.SignDateTimestamp=new Date()
   await WebService({
      endPoint: `CommonUtility/DocumentSentHistory?DocumentHistoryId=${dr.DocumentHistoryId}`,
      method: "PUT",
      body: data,
      dispatch,
  }).then(()=>{FetchMyDocuments();   WSSuccessAlert('',`The document '${dr.DocumentTemplateName}' has been signed successfully.`);
})

}  
  const ViewDocument = async (dr) => {
    let [placeholderValues] = await WebService({
             endPoint: `UserProfile/Fetch/${loggedUser || 0}`,
             dispatch,
    });


    // Preprocess placeholderValues to replace undefined with an empty string
    Object.keys(placeholderValues).forEach(key => {
      if (placeholderValues[key] == null) {
        placeholderValues[key] = ''; // Replace undefined with empty string
      }
    });


   
    await WebService({
      endPoint: `Document/DownloadDocx/?fileName=${dr?.DocumentTemplatesPath}`,
      method: "GET",

    })
      .then((content) => {
        const zip = new PizZip(content?.buffer?.data); 

        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });
        
        // Render the document by replacing placeholders with values
        doc.render(placeholderValues);
        // Generate the updated DOCX file as a Blob
        const updatedDoc = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        // Create a Blob URL
        const url = URL.createObjectURL(updatedDoc);

        

          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = "document";  // Specify the file name here (optional)
          downloadLink.click();  // Trigger the download   
               
        
      })
      .catch((error) => {
        WSErrorAlert("","Error processing document")
      });
  };
  const columns = [
    {
      Text: "Document",
      Value: "DocumentTemplateName",
    },
    
    {
      Text: "Sent By",
      Value: "FullName",
    },
    {
      Text: "Sent Date Time",   
      Value: "SentTimeStamp",
      render:(dr)=>(
        <>{dr.SentTimeStamp && beautifyLocalTime(dr.SentTimeStamp)} {" "} {dr.SignDateTimestamp} </>
      )
    },
    {
      Text: "Signature Required",
      Value: "RequiresSignature",
              render:(dr)=>(
        <>
          {dr.SignDateTimestamp ? (
            <span className="d-flex align-items-center">
              {beautifyLocalTime(dr.SignDateTimestamp)}{" "}
              <TaskAlt className=" ms-1" color="success" titleAccess="Signed" />
            </span>
          ) : dr.RequiresSignature === 1 ? (
            'Yes'
          ) : (
            'No'
          )}
        </>
      )
    },
    {
      Text: "Action",
      style: { width: "2px" }, 
      render: (dr) => (
        <span className="d-flex col-auto">
          <ActionButton
            IconName="Download"
            IconColor={"primary"}
            IconTooltip="Downlod Document"
            onClick={() => {
              ViewDocument(dr);
            }}
          />
        {dr.RequiresSignature==1 &&  
          dr.SignDateTimestamp  ? '' : dr.RequiresSignature==1 ?   <ActionButton
          IconName='Signature'
          IconTooltip='Sign Document'
          onClick={()=>{
           fnSignModal(dr.DocumentHistoryId)}}
          />:''
          }
        </span>
      ),
    },
  ];

  const [bData] = React.useState([
    {
      title: "Manage Doument",
      hrefLink: "#",
    },
    {
      title: "next Route",
      hrefLink: "#",
    },
  ]);

  return (
    <>
      <Container fluid className="base-container">
        <Box
          sx={{
            width: 1,
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <h5 className="ms-4 mt-2">{MasterPageName}</h5>
              <div className="ms-4">
                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
              </div>
            </div>
          </div>
        </Box>
        <div></div>
        {/* {filterComponent}  @shubham95shrivas please review this */}
        <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
        <TableComponent
          columns={columns}
          data={mydocuments}
          noRecordCss="p-0"
          noRecordFound={
            <NoRecordTemplate
              headerValue={StandardConst.ManageMyDocumentNoResult}
              subHeaderValue={StandardConst.ManageMyDocumetSubHeaderRole}
              imageUrl={StandardConst.imageNoRecordsFound}
            />
          }
          isSearchRequired={true}
          allowSerialNo={true}
        />
      </Container>
       <SignautureModal
                  callBackEvent={async() => await FetchMyDocuments()}
                  ref={SignModalRef}
                ></SignautureModal>
       
    </>
  );
};

export default MyDocuments;
