import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { WebService } from "../../Services/WebService";
import { map } from "underscore";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  LineChart,
  Line,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { Box } from "@mui/material";
import {
    DateRange,
  ProfileSummary,
} from "../Dashboard/Company/CompanyDashboard";
import { CustomPieChart } from "../VehicleManagement/CustomPieChart";
import TableComponent from "../../Services/TableComponent";
import { format } from "date-fns";

const RecruiterDashboard = () => {
  return (
      <div className='container'>
        {/* <div className="row mt-3">
          <div className="col-12">
            <div className="card card-cd m-0">
              <div className="card-body p-0 p-2">
                <div className="d-flex flex-column flex-lg-row flex-sm-row">
                  <ProfileSummary className="col-md-5 col-sm-9" />
                  <div className="vr my-2 col-md-1 col-sm-1 d-flex justify-content-end d-none d-md-inline"></div>
                  <div className="vr my-2 col-md-1 col-sm-1 d-flex justify-content-end d-none d-md-inline"></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}


        <div className="row mt-3">
          <div className="col-12 col-md-3">
            <AllOpenAndReopenJobsCount />
          </div>

          <div className="col-12 col-md-3">
            <AllPositionForJobs />
          </div>

          <div className="col-12 col-md-3">
            <AllJobApplicantCount />
          </div>

          <div className="col-12 col-md-3">
            <NumberOfAppliedApplication />
          </div>
        </div>
        
        <div className="row mt-3">
          <div className="col-12 col-md-3">
            <NumberOfScheduledApplication />
          </div>

          <div className="col-12 col-md-3">
            <NumberOfInterviewedApplication />
          </div>

          <div className="col-12 col-md-3">
            <NumberOfRejectedApplication />
          </div>

          <div className="col-12 col-md-3">
            <NumberOfHiredApplication />
          </div>

          {/* <div className="col-12 col-md-7 mt-3 mt-md-0">
            <UpcomingInterview />
          </div> */}
        </div>

        <div className="row mt-3">
          <div className="col-12 col-md-5">
            <JobApplicantSkill />
          </div>

          <div className="col-12 col-md-7 mt-3 mt-md-0">
            <UpcomingInterview />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 col-md-5">
            <CustomPieChart endPoint="Recruitment/fetchInterviewTypeCount" mappingFields={{name: "InterviewType", value: "InterviewTypeCount"}} graphHeading="Interview Types" graphRadius={80} showFilter={true}/>
          </div>

          <div className="col-12 col-md-7 mt-3 mt-md-0">
            <MostRecentInterview />
          </div>
        </div>

        <div className="row mt-3 mb-4">
          <div className="col-12 col-md-5">
            <CustomPieChart endPoint="Recruitment/fetchInterviewForAllStatus" mappingFields={{name: "Status", value: "InterviewCount"}} graphHeading="Interviews by status" graphRadius={80} showFilter={true} />
          </div>

        </div>
        <div className="row mt-3 mb-4">
          <div className="col-12 mt-3 mt-md-0">
            <JobApplicantsForJob />
          </div>
        </div>

        <div className="row mt-3 mb-4">
          <div className="col-12 mt-3 mt-md-0">
            <NumberOfApplicantForAllMonths />
          </div>
        </div>
      </div>
  )
};

const NumberOfAppliedApplication = () => {

  const dispatch = useDispatch();
  const [numberOfAppliedApplicationData, setNumberOfAppliedApplicationData] = useState([]);

  const fetchNumberOfAppliedApplicationData = async (SelectedYearFilter) => {
    SelectedYearFilter ??= null;
    
    const data = await WebService({
      endPoint: `Recruitment/fetchNumberOfApplicationWithStatus?StatusId=${StandardConst.Status.Applied}&YearMonthFilter=${SelectedYearFilter !== null ? SelectedYearFilter : format(new Date(), 'yyyy-MM')}`,
      dispatch,
    });
    if(data.length > 0) {
      setNumberOfAppliedApplicationData(data);
    }else{
      setNumberOfAppliedApplicationData([]);
    }
  }

  useEffect(() => {
    fetchNumberOfAppliedApplicationData();
  }, []);

  const handleChangeFilters = async (selectedYear) => {
    await fetchNumberOfAppliedApplicationData(selectedYear);
  };

  return (
    <>
      <div className="card card-cd m-0 p-3 d-flex flex-column align-items-center h-100">
            <div className="row w-100">
                <div className="col-12 d-flex justify-content-center align-items-center">
                    <DateRange 
                        ButtonColor="inherit"
                        setDateRange={({ StartDate, EndDate }) => {
                            if (StartDate) {
                                handleChangeFilters(format(new Date(StartDate), "yyyy-MM"))
                            }
                        }}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center" style={{fontSize:"70px"}}>
                <b>{numberOfAppliedApplicationData[0]?.ApplicationCount}</b>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center mb-2">
                <h5 className="card-title">Applications Received</h5>
            </div>
        </div>
    </>
  );
};

const NumberOfInterviewedApplication = () => {

  const dispatch = useDispatch();
  const [numberOfInterviewedApplicationData, setNumberOfInterviewedApplicationData] = useState([]);

  const fetchNumberOfInterviewedApplicationData = async (SelectedYearFilter) => {
    SelectedYearFilter ??= null;
    
    const data = await WebService({
      endPoint: `Recruitment/fetchNumberOfApplicationWithStatus?StatusId=${StandardConst.Status.Interviewed}&YearMonthFilter=${SelectedYearFilter !== null ? SelectedYearFilter : format(new Date(), 'yyyy-MM')}`,
      dispatch,
    });
    if(data.length > 0) {
      setNumberOfInterviewedApplicationData(data);
    }else{
      setNumberOfInterviewedApplicationData([]);
    }
  }

  useEffect(() => {
    fetchNumberOfInterviewedApplicationData();
  }, []);

  const handleChangeFilters = async (selectedYear) => {
    await fetchNumberOfInterviewedApplicationData(selectedYear);
  };

  return (
    <>
      <div className="card card-cd m-0 p-3 d-flex flex-column align-items-center" style={{height: "150px"}}>
            <div className="row w-100">
                
                <div className="col-12 d-flex justify-content-center align-items-center">
                    <DateRange 
                        ButtonColor="inherit"
                        setDateRange={({ StartDate, EndDate }) => {
                            if (StartDate) {
                                handleChangeFilters(format(new Date(StartDate), "yyyy-MM"))
                            }
                        }}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center" style={{fontSize:"36px"}}>
                <b className="text-primary">{numberOfInterviewedApplicationData[0]?.ApplicationCount}</b>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center mb-2">
                <h5 className="card-title">Interviewed</h5>
            </div>
        </div>
    </>
  );
};

const NumberOfRejectedApplication = () => {

  const dispatch = useDispatch();
  const [numberOfRejectedApplicationData, setNumberOfRejectedApplicationData] = useState([]);

  const fetchNumberOfRejectedApplicationData = async (SelectedYearFilter) => {
    SelectedYearFilter ??= null;
    
    const data = await WebService({
      endPoint: `Recruitment/fetchNumberOfApplicationWithStatus?StatusId=${StandardConst.Status.Reject}&YearMonthFilter=${SelectedYearFilter !== null ? SelectedYearFilter : format(new Date(), 'yyyy-MM')}`,
      dispatch,
    });
    if(data.length > 0) {
      setNumberOfRejectedApplicationData(data);
    }else{
      setNumberOfRejectedApplicationData([]);
    }
  }

  useEffect(() => {
    fetchNumberOfRejectedApplicationData();
  }, []);

  const handleChangeFilters = async (selectedYear) => {
    await fetchNumberOfRejectedApplicationData(selectedYear);
  };

  return (
    <>
      <div className="card card-cd m-0 p-3 d-flex flex-column align-items-center" style={{height: "150px"}}>
            <div className="row w-100">
                
                <div className="col-12 d-flex justify-content-center align-items-center">
                    <DateRange 
                        ButtonColor="inherit"
                        setDateRange={({ StartDate, EndDate }) => {
                            if (StartDate) {
                                handleChangeFilters(format(new Date(StartDate), "yyyy-MM"))
                            }
                        }}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center" style={{fontSize:"36px"}}>
                <b className="text-primary">{numberOfRejectedApplicationData[0]?.ApplicationCount}</b>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center mb-2">
                <h5 className="card-title">Rejected</h5>
            </div>
        </div>
    </>
  );
};

const NumberOfHiredApplication = () => {

  const dispatch = useDispatch();
  const [numberOfHiredApplicationData, setNumberOfHiredApplicationData] = useState([]);

  const fetchNumberOfHiredApplicationData = async (SelectedYearFilter) => {
    SelectedYearFilter ??= null;
    
    const data = await WebService({
      endPoint: `Recruitment/fetchNumberOfApplicationWithStatus?StatusId=${StandardConst.Status.Hired}&YearMonthFilter=${SelectedYearFilter !== null ? SelectedYearFilter : format(new Date(), 'yyyy-MM')}`,
      dispatch,
    });
    if(data.length > 0) {
      setNumberOfHiredApplicationData(data);
    }else{
      setNumberOfHiredApplicationData([]);
    }
  }

  useEffect(() => {
    fetchNumberOfHiredApplicationData();
  }, []);

  const handleChangeFilters = async (selectedYear) => {
    await fetchNumberOfHiredApplicationData(selectedYear);
  };

  return (
    <>
      <div className="card card-cd m-0 p-3 d-flex flex-column align-items-center" style={{height: "150px"}}>
            <div className="row w-100">
                
                <div className="col-12 d-flex justify-content-center align-items-center">
                    <DateRange 
                        ButtonColor="inherit"
                        setDateRange={({ StartDate, EndDate }) => {
                            if (StartDate) {
                                handleChangeFilters(format(new Date(StartDate), "yyyy-MM"))
                            }
                        }}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center" style={{fontSize:"36px"}}>
                <b className="text-primary">{numberOfHiredApplicationData[0]?.ApplicationCount}</b>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center mb-2">
                <h5 className="card-title">Hired</h5>
            </div>
        </div>
    </>
  );
};

const NumberOfScheduledApplication = () => {

  const dispatch = useDispatch();
  const [numberOfScheduledApplicationData, setNumberOfScheduledApplicationData] = useState([]);

  const fetchNumberOfScheduledApplicationData = async (SelectedYearFilter) => {
    SelectedYearFilter ??= null;
    
    const data = await WebService({
      endPoint: `Recruitment/fetchNumberOfApplicationWithStatus?StatusId=${StandardConst.Status.Scheduled}&YearMonthFilter=${SelectedYearFilter !== null ? SelectedYearFilter : format(new Date(), 'yyyy-MM')}`,
      dispatch,
    });
    if(data.length > 0) {
      setNumberOfScheduledApplicationData(data);
    }else{
      setNumberOfScheduledApplicationData([]);
    }
  }

  useEffect(() => {
    fetchNumberOfScheduledApplicationData();
  }, []);

  const handleChangeFilters = async (selectedYear) => {
    await fetchNumberOfScheduledApplicationData(selectedYear);
  };

  return (
    <>
      <div className="card card-cd m-0 p-3 d-flex flex-column align-items-center" style={{height: "150px"}}>
            <div className="row w-100">
                
                <div className="col-12 d-flex justify-content-center align-items-center">
                    <DateRange 
                        ButtonColor="inherit"
                        setDateRange={({ StartDate, EndDate }) => {
                            if (StartDate) {
                                handleChangeFilters(format(new Date(StartDate), "yyyy-MM"))
                            }
                        }}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center" style={{fontSize:"36px"}}>
                <b className="text-primary">{numberOfScheduledApplicationData[0]?.ApplicationCount}</b>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center mb-2">
                <h5 className="card-title">Interview Scheduled</h5>
            </div>
        </div>
    </>
  );
};

const AllJobApplicantCount = () => {

    const dispatch = useDispatch();
    const [allApplicantCountData, setAllApplicantCountData] = useState([]);

    const fetchAllApplicantCount = async (SelectedYearFilter) => {
        SelectedYearFilter ??= null;
        await WebService({
            endPoint: `Recruitment/fetchAllApplicantsCount?YearMonthFilter=${SelectedYearFilter !== null ? SelectedYearFilter : format(new Date(), 'yyyy-MM')}`,
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? setAllApplicantCountData(res) : setAllApplicantCountData([]));
    };

    useEffect(() => {
        fetchAllApplicantCount();
    }, []);

    const handleChangeFilters = async (selectedYear) => {
        await fetchAllApplicantCount(selectedYear);
    };
  
    return (
      <>
        <div className="card card-cd m-0 p-3 d-flex flex-column align-items-center h-100">
            <div className="row w-100">
                
                <div className="col-12 d-flex justify-content-center align-items-center">
                    <DateRange 
                        ButtonColor="inherit"
                        setDateRange={({ StartDate, EndDate }) => {
                            if (StartDate) {
                                handleChangeFilters(format(new Date(StartDate), "yyyy-MM"))
                            }
                        }}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center" style={{fontSize:"70px"}}>
                <b>{allApplicantCountData[0]?.ApplicantCount}</b>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center mb-2">
                <h5 className="card-title">Total Applicants</h5>
            </div>
        </div>

      </>
    );
};

const AllOpenAndReopenJobsCount = () => {

  const dispatch = useDispatch();
  const [allOpenAndReopenJobCountData, setAllOpenAndReopenJobCountData] = useState([]);

  const fetchAllOpenAndReopenJobCount = async (SelectedYearFilter) => {
      SelectedYearFilter ??= null;
      await WebService({
          endPoint: `Recruitment/fetchAllOpenAndReopenJobsCount?YearMonthFilter=${SelectedYearFilter !== null ? SelectedYearFilter : format(new Date(), 'yyyy-MM')}`,
          method: 'GET',
          dispatch
      }).then(res => res.length > 0 ? setAllOpenAndReopenJobCountData(res) : setAllOpenAndReopenJobCountData([]));
  };

  useEffect(() => {
      fetchAllOpenAndReopenJobCount();
  }, []);

  const handleChangeFilters = async (selectedYear) => {
      await fetchAllOpenAndReopenJobCount(selectedYear);
  };

  return (
    <>
      <div className="card card-cd m-0 p-3 d-flex flex-column align-items-center h-100">
          <div className="row w-100">
              
              <div className="col-12 d-flex justify-content-center align-items-center">
                  <DateRange 
                      ButtonColor="inherit"
                      setDateRange={({ StartDate, EndDate }) => {
                          if (StartDate) {
                              handleChangeFilters(format(new Date(StartDate), "yyyy-MM"))
                          }
                      }}
                  />
              </div>
          </div>
          <div className="d-flex justify-content-center align-items-center" style={{fontSize:"70px"}}>
              <b>{allOpenAndReopenJobCountData[0]?.OpenJobCount}</b>
          </div>
          <div className="col-12 d-flex justify-content-center align-items-center mb-2">
              <h5 className="card-title">Number of Open Jobs</h5>
          </div>
      </div>

    </>
  );
};

const AllPositionForJobs = () => {

  const dispatch = useDispatch();
  const [allPositionCountData, setAllPositionCountData] = useState([]);

  const fetchAllPositionCount = async (SelectedYearFilter) => {
      SelectedYearFilter ??= null;
      await WebService({
          endPoint: `Recruitment/fetchAllPositionForJobs?YearMonthFilter=${SelectedYearFilter !== null ? SelectedYearFilter : format(new Date(), 'yyyy-MM')}`,
          method: 'GET',
          dispatch
      }).then(res => res.length > 0 ? setAllPositionCountData(res) : setAllPositionCountData([]));
  };

  useEffect(() => {
      fetchAllPositionCount();
  }, []);

  const handleChangeFilters = async (selectedYear) => {
      await fetchAllPositionCount(selectedYear);
  };

  return (
    <>
      <div className="card card-cd m-0 p-3 d-flex flex-column align-items-center h-100">
          <div className="row w-100">
              
              <div className="col-12 d-flex justify-content-center align-items-center">
                  <DateRange 
                      ButtonColor="inherit"
                      setDateRange={({ StartDate, EndDate }) => {
                          if (StartDate) {
                              handleChangeFilters(format(new Date(StartDate), "yyyy-MM"))
                          }
                      }}
                  />
              </div>
          </div>
          <div className="d-flex justify-content-center align-items-center" style={{fontSize:"70px"}}>
              <b>{allPositionCountData[0]?.PositionCount}</b>
          </div>
          <div className="col-12 d-flex justify-content-center align-items-center mb-2">
              <h5 className="card-title">Number of Positions</h5>
          </div>
      </div>

    </>
  );
};

const JobApplicantSkill = () => {

    const dispatch = useDispatch();
    const [jobApplicantSkillData, setJobApplicantSkillData] = useState([]);

    const fetchUpcomingInterview = async (SelectedYearFilter) => {
        SelectedYearFilter ??= null;
        await WebService({
            endPoint: `Recruitment/fetchJobApplicantSkillForRecruiterDashboard?YearFilter=${SelectedYearFilter !== null ? SelectedYearFilter : format(new Date(), 'yyyy')}`,
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? setJobApplicantSkillData(res) : setJobApplicantSkillData([]));
    };

    useEffect(() => {
        fetchUpcomingInterview();
    }, []);

    const handleChangeFilters = async (selectedYear) => {
        await fetchUpcomingInterview(selectedYear);
    };
  
    return (
      <>
        <div className="card card-cd m-0 p-3">
          <div className="d-flex flex-row">
              <div className="col-8 ms-2 d-flex justify-content-start align-items-center">
                <h5 className="card-title">Top Applicant Skills</h5>
              </div>
              <div className="col-4 d-flex justify-content-start align-items-center">
                  <DateRange 
                    type="year"
                    ButtonColor="inherit"
                    setDateRange={({ StartDate, EndDate }) => {
                      if(StartDate){
                        handleChangeFilters(format(new Date(StartDate), "yyyy"))
                      }
                    }}
                  />
            </div>
          </div>

            <ResponsiveContainer width="100%" height={300}>
                <BarChart width={600} height={400} data={jobApplicantSkillData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="SkillName" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="ApplicantsCount" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        </div>
      </>
    );
};

const JobApplicantsForJob = () => {

    const dispatch = useDispatch();
    const [ApplicantForJobData, setApplicantForJobData] = useState([]);

    const fetchApplicantForJobData = async (SelectedYearFilter) => {
        SelectedYearFilter ??= null;
        await WebService({
            endPoint: `Recruitment/fetchApplicantCountForJob?YearMonthFilter=${SelectedYearFilter !== null ? SelectedYearFilter : format(new Date(), 'yyyy-MM')}`,
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? setApplicantForJobData(res) : setApplicantForJobData([]));
    };

    useEffect(() => {
        fetchApplicantForJobData();
    }, []);

    const handleChangeFilters = async (selectedYear) => {
        await fetchApplicantForJobData(selectedYear);
    };
  
    return (
      <>
        <div className="card card-cd m-0 p-3">
          <div className="d-flex flex-row">
              <div className="col-8 ms-2 d-flex justify-content-start align-items-center">
                <h5 className="card-title">Applicant Count for all jobs</h5>
              </div>
              <div className="col-4 d-flex justify-content-start align-items-center">
                  <DateRange 
                    ButtonColor="inherit"
                    setDateRange={({ StartDate, EndDate }) => {
                      if(StartDate){
                        handleChangeFilters(format(new Date(StartDate), "yyyy-MM"))
                      }
                    }}
                  />
            </div>
          </div>

            <ResponsiveContainer width="100%" height={300}>
                <BarChart width={600} height={400} data={ApplicantForJobData} layout="vertical" margin={{left: 100}}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number"/>
                    <YAxis dataKey="JobTitle" type="category" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="ApplicantCount" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        </div>
      </>
    );
};

const UpcomingInterview = () => {

    const dispatch = useDispatch();
    const [upcomingInterviewData, setUpcomingInterviewData] = useState([]);

    const fetchUpcomingInterviewData = async () => {
        await WebService({
            endPoint: 'Recruitment/fetchUpcomingInterview',
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? setUpcomingInterviewData(res) : null);
    };

    useEffect(() => {
        fetchUpcomingInterviewData();
    }, []);

    var columns = [
        {
          Text: "Interview Date",
          DateFormat: "dd-MMM-yyyy",
          Value: "InterviewDate",
          render: (dr) => (
            <span>
                <span>{format(new Date(dr.InterviewDate), "dd-MMM-yyyy")}</span><br />
                <span style={{fontSize: "13px"}}>{format(new Date(`${dr.InterviewDate} ${dr.InterviewFromTime}`), "HH:mm")} - {format(new Date(`${dr.InterviewDate} ${dr.InterviewToTime}`), "HH:mm")}</span>
            </span>
          )
        },
        {
          Text: "Position",
          Value: "Position",
        },
        {
          Text: "Applicant Name",
          Value: "ApplicantName",
        },
        {
          Text: "Interviewer Name",
          Value: "InterviewerName",
        }
      ];
  
    return (
      <>
        <div className="card card-cd m-0 p-3">
          <div className="d-flex flex-row">
              <div className="col-12 ms-2 d-flex justify-content-start align-items-center">
                <h5 className="card-title">Upcoming Interview</h5>
              </div>
          </div>

          <TableComponent columns={columns} data={upcomingInterviewData} IsAddButtonVisible={false} isSearchRequired={false} isExcelExportButtonVisible={false} />
        </div>
      </>
    );
};

const MostRecentInterview = () => {

    const dispatch = useDispatch();
    const [mostRecentInterviewData, setMostRecentInterviewData] = useState([]);

    const fetchMostRecentInterviewData = async () => {
        await WebService({
            endPoint: 'Recruitment/fetchMostRecentInterview',
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? setMostRecentInterviewData(res) : null);
    };

    useEffect(() => {
        fetchMostRecentInterviewData();
    }, []);

    var columns = [
        {
          Text: "Interview Date",
          DateFormat: "dd-MMM-yyyy",
          Value: "InterviewDate",
          render: (dr) => (
            <span>
                <span>{format(new Date(dr.InterviewDate), "dd-MMM-yyyy")}</span><br />
                <span style={{fontSize: "13px"}}>{format(new Date(`${dr.InterviewDate} ${dr.InterviewFromTime}`), "HH:mm")} - {format(new Date(`${dr.InterviewDate} ${dr.InterviewToTime}`), "HH:mm")}</span>
            </span>
          )
        },
        {
          Text: "Position",
          Value: "Position",
        },
        {
          Text: "Applicant Name",
          Value: "ApplicantName",
        },
        {
          Text: "Interviewer Name",
          Value: "InterviewerName",
        }
      ];
  
    return (
      <>
        <div className="card card-cd m-0 p-3">
          <div className="d-flex flex-row">
              <div className="col-12 ms-2 d-flex justify-content-start align-items-center">
                <h5 className="card-title">Last 10 Interviews</h5>
              </div>
          </div>

          <TableComponent columns={columns} data={mostRecentInterviewData} IsAddButtonVisible={false} isSearchRequired={false} isExcelExportButtonVisible={false} />
        </div>
      </>
    );
};

const NumberOfApplicantForAllMonths = () => {

    const dispatch = useDispatch();
    const [numberOfApplicantsForMonthData, setNumberOfApplicantsForMonthData] = useState([]);
  
    const fetchNumberOfApplicantForAllMonthData = async (SelectedYearFilter) => {
      SelectedYearFilter ??= null;
      
      const data = await WebService({
        endPoint: `Recruitment/fetchApplicantCountStatusWiseInAllMonths?YearFilter=${SelectedYearFilter !== null ? SelectedYearFilter : format(new Date(), 'yyyy')}`,
        dispatch,
      });
      if(data.length > 0) {
        setNumberOfApplicantsForMonthData(data);
      }else{
        setNumberOfApplicantsForMonthData([]);
      }
    }
  
    useEffect(() => {
      fetchNumberOfApplicantForAllMonthData();
    }, []);

    const handleChangeFilters = async (selectedYear) => {
      await fetchNumberOfApplicantForAllMonthData(selectedYear);
    };
  
    return (
      <>
        <div className="card card-cd m-0 p-3">
          <div className="d-flex flex-row">
              <div className="col-md-6 col-sm-6 ms-2 d-flex justify-content-start align-items-center">
              <h5 className="card-title">Number of Applicant for all Months</h5>
              </div>
              <div className="col-md-6 col-sm-6 d-flex justify-content-end">
                <DateRange 
                  type='year'
                  ButtonColor="inherit"
                  setDateRange={({ StartDate, EndDate }) => {
                    if(StartDate){
                      handleChangeFilters(format(new Date(StartDate), "yyyy"))
                    }
                  }}
                />
              </div>
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart width={600} height={400} data={numberOfApplicantsForMonthData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Applied" stackId="a" fill="#8884d8" />
                {/* <LabelList dataKey="Month" position="top" />
              </Bar> */}
              <Bar dataKey="Selected" stackId="a" fill="#82ca9d" />
              <Bar dataKey="Rejected" stackId="a" fill="#FF5733" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </>
    );
};

export default RecruiterDashboard