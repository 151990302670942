import React, { useState } from "react";
import {
  Container,
  Card,
  Chip,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { WebService } from "../../Services/WebService";
import {  useParams } from "react-router-dom";
import { useEffect } from "react";
import { StandardConst } from "../../Services/StandardConst";
import TableComponent from "../../Services/TableComponent";
import AppbarComponent from "../../Services/AppbarComponent";
import { Check, CheckBox, CheckBoxOutlineBlank, Download, Error, Send, TaskAlt, Warning} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormInputDropdown } from "../Form";
import ProgressBar from "../../components/ProgressBar";
import { WSErrorAlert, WSSuccessAlert } from "../../Services/WSAlert";
import { beautifyLocalTime } from "../../Services/StandardFunctions";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import ActionButton from "../../Services/ActionButton";


const SendDocumentInternalUser = () => {
  const params = useParams();
  const [DocumentTemplate, setDocumentTemplate] = useState([]);
  const [ReceiverData, setReceiverData] = useState([]);
  const [SignatureYesorNo,setSignatureYesorNo]=useState(0)
  const [progress,setprogress]=useState(0)
  const [progressLoading,setprogressLoading]=useState(false)
  // this state handles the preview of the email template and its html  code
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const dispatch=useDispatch();
  const [bData] = useState([
    {
      title: "Document Template List",
      hrefLink: "/ManageDocument",
    },
    {
      title: "Send Document",
      hrefLink: "#",
    },
  ]);

  const MasterPageName = "Send Document";
  const fetchDocumentTempalete = async () => {
    const data = await WebService({
      endPoint: `Document/GetOneDocumentTempalete/?DocumentTempaleteId=${params.documentId}`,
      method: "GET",
    });
    setDocumentTemplate(data?.result[0]);
  };
    const fetchEmployees = async () => {
       WebService({
         dispatch,
         endPoint:`User/EmployeeList?where=StatusId eq ${StandardConst.Status.Approved}`
       }).then(r => setReceiverData(r));
     };

     const handleCheck = (data) => {
      setReceiverData((prev) => {
        const updatedEmployees = [...prev]; // Create a copy of the previous state
        updatedEmployees[data.index] = { 
          ...updatedEmployees[data.index], 
          Checked: !updatedEmployees[data.index]?.Checked ?? true // Toggle or set default to true
        };
        return updatedEmployees; // Return the updated array
      });
      
    
    };
  


  useEffect(()=>{
    fetchEmployees();
  },[]);


  useEffect(() => {
    if (params.documentId != 0) fetchDocumentTempalete();
  }, [params.documentId]);



  const handleSubmit = async () => {
    setprogressLoading(true)
    let values = {};
    values.documentName = DocumentTemplate.DocumentTemplateName;
    values.documentpath = DocumentTemplate.DocumentTemplatesPath;
    values.DocumentId=DocumentTemplate.DocumentTemplateId;
    values.SignatureYesorNo=Number(SignatureYesorNo);
    for (let i = 0; i < ReceiverData.length; i++) {
      
      const Receiver = ReceiverData[i];
      if(!Receiver.Checked){
      continue;
      }
      const entries = Object.entries(Receiver);
      values = {
        ...values,
        ...Receiver, // Merge the Receiver object
        ValuesToBeChangedWithDocumentPlaceholders: { ...Receiver }, // Example of additional processing
      };      values.ValuesToBeChangedWithDocumentPlaceholders =
        Object.fromEntries(entries);
        values.SentTimeStamp = new Date().toISOString();


      // Update the table status to 'sending' directly using index
      setReceiverData((prevTableData) => {
        const updatedData = [...prevTableData];
        updatedData[i].status = "sending";
        return updatedData;
      });

      try {
        // Send the email
        await WebService({
          endPoint: `Document/sendInternal`,
          method: "post",
          body: values,
        });

        // Update the table status to 'sent' directly using index
        setprogress((prev)=> (prev+1/ReceiverData.length)*100)
        setReceiverData((prevTableData) => {
          const updatedData = [...prevTableData];
          updatedData[i].status = "sent";
          return updatedData;
        });
      } catch (error) {
        // In case of failure, update the table status to 'failed'
        setReceiverData((prevTableData) => {
          const updatedData = [...prevTableData];
          updatedData[i].status = "failed";
          return updatedData;
        });
      }
    }
   WSSuccessAlert("","Document Sending process completed")
  setprogressLoading(false)  };
  const columns = [
    {
      Text:'Select Recipients',
      render: (dr) => {
        return <span>
           <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={dr?.Checked || false}
              onChange={()=>handleCheck(dr)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            {
              dr?.Checked &&  <ActionButton
              IconName="View"
              IconColor={"primary"}
              IconTooltip="Preview Document"
              onClick={() => {
                ViewDocument(dr);
              }}
            />
            }
        </span>
    }

    },
    {
      Text:'Full Name',
      Value:'FullName'
    },
    {
      Text:'Email',
      Value:'Email'
    },
    {
      Text:'Manager',
      Value:'Manager'
    },
    {
      Text:'Designation',
      Value:'Designation'
    },
    {
      Text:'Sent Status',
      render:(dr)=>(
        <span>
              {dr.status === 'sent' ? (
                <>sent {"  "}  <TaskAlt color="success" fontSize="small" /></>
              ) : dr.status === 'pending' ? (
                <>pending {"  "} <Warning color="warning" fontSize="small" /></>
              ) : dr.status === 'failed' ? (
                <>failed {"  "}  <Error color="error" fontSize="small" /></>
              ) : dr.status === 'sending' ? (
                <>sending {"  "}  <CircularProgress size={10} /></>
              ) : (
                <>pending {"  "} <Warning color="warning" fontSize="small" /></>
              )}
            </span>
      )

    }
  ]
  const ViewDocument = async (dr) => {
     let [placeholderValues] = await WebService({
              endPoint: `UserProfile/Fetch/${dr?.EmployeeId || 0}`,
              dispatch,
     });
 
 
     // Preprocess placeholderValues to replace undefined with an empty string
     Object.keys(placeholderValues).forEach(key => {
       if (placeholderValues[key] == null) {
         placeholderValues[key] = ''; // Replace undefined with empty string
       }
     });
     await WebService({
       endPoint: `Document/DownloadDocx/?fileName=${DocumentTemplate?.DocumentTemplatesPath}`,
       method: "GET",
 
     })
       .then((content) => {
         const zip = new PizZip(content?.buffer?.data); 
         const doc = new Docxtemplater(zip, {
           paragraphLoop: true,
           linebreaks: true,
         });
         
         // Render the document by replacing placeholders with values
         doc.render(placeholderValues);
         // Generate the updated DOCX file as a Blob
         const updatedDoc = doc.getZip().generate({
           type: "blob",
           mimeType:
             "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
         });
 
         // Create a Blob URL
         const url = URL.createObjectURL(updatedDoc);
 
           // Fallback: trigger file download
           const downloadLink = document.createElement("a");
           downloadLink.href = url;
           downloadLink.download = "document";  // Specify the file name here (optional)
           downloadLink.click();  // Trigger the download   
                
         
       })
       .catch((error) => {
         WSErrorAlert("","Error processing document")
       });
   };
  return (
    <Container fluid className="base-container">
      {/* Header Section */}

      <div className="d-flex justify-content-between align-items-center mt-3">
        <div>
          <h5 className="ms-4 mt-2">{MasterPageName}</h5>
          <div className="ms-4">
            <BreadcrumbsComponent bData={bData} />
          </div>
        </div>
      </div>
      
      <AppbarComponent
        title={MasterPageName}
        isSearchRequired={false}
        isAddButtonRequired={true}
        Exception={true}
      />
      {/* Document Details */}
      <Card className="shadow-lg pt-4 px-4 w-100 mx-auto ">
        <TableComponent
          IsAddButtonVisible={false}
          onAddEvent={null}
          isSearchRequired={false}
          isAddButtonRequired={false}
          isExcelExportButtonVisible={false}
          data={[
            {
              DocumentName: (
                <>
                  <span
                    title="view document"
                    className="text-primary text-capitalize cursor-pointer underline text-decoration-none hover-underline"
                    onClick={() =>
                      window.open(
                        `${StandardConst.apiBaseUrl}/${DocumentTemplate.DocumentTemplatesPath}`,
                        "_blank"
                      )
                    }
                  >
                    {DocumentTemplate.DocumentTemplateName}
                  </span>{" "}
                 
                    <i className="fa fa-download text-primary fa-1x cursor-pointer" />
                  
                  {DocumentTemplate.IsReserved ? <Chip label="Reserved" /> : ""}
                </>
              ),
              CreatedBy: (
                <span className="text-muted font-weight-bold">
                  {DocumentTemplate.CreaterName}
                </span>
              ),
              LastModifiedBy: (
                <span className="text-muted font-weight-bold">
                  {DocumentTemplate.LastModifiedBy}
                </span>
              )
              ,
              CreatedAt:(
                <span className="text-muted font-weight-bold">
                   {DocumentTemplate?.CreatedAt && beautifyLocalTime(DocumentTemplate.CreatedAt)}
                </span>
              )
            
            },
          ]}
          columns={[
            { Text: "Document Name", Value: "DocumentName" },
            { Text: "Created By", Value: "CreatedBy" },
            { Text: "Last Modified By", Value: "LastModifiedBy" },
            {Text:'Document Created on',Value:'CreatedAt'},

          ]}
        />
          <div className="mt-2">
            <TableComponent
              isSearchRequired={true}
              data={ReceiverData}
              columns={columns}
            />
          </div>
  
        {/* section for email template edit and view at same placeholder */}
        
      
        <div className=" col-md-12 w-100 d-flex flex-column align-items-end  justify-content-end gap-2 mb-2">
            <div className="">
              <label>
                Recipent is required to sign the document or upload the document with signatures ?
              </label>
              <Checkbox
                checked={SignatureYesorNo}
                onChange={() => setSignatureYesorNo(!SignatureYesorNo)}
                name="signature"
              />
            </div>
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleSubmit}
              id="inputGroupFileAddon04"
              // disabled={progress}
            >
              {!progressLoading ? (
                <>
                  <Send /> Send Document To Recipients
                </>
              ) : (
                <>
                Sending ...
                </>
              
              )}
            </button>

       </div>
    
      </Card>
    </Container>
  );
};

export default SendDocumentInternalUser
