import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormInputDropdown, FormInputText } from "../Form";
import { StandardConst } from "../../Services/StandardConst";
import { WebService } from "../../Services/WebService";
import { Link, useNavigate } from "react-router-dom";

const { forwardRef, useState, useImperativeHandle } = React;

const CloneMailTemplate = (prop, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [data, setData] = useState({ MailTemplate: {} });
    const [MailGroup,setMailGroups] = useState([])
    const navigate=useNavigate();
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    
    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id === 0) {
                setData({ MailTemplate: {} })
            } else {
                setShow(false);
                GetMailGroups()
                const data = {
                    MailTemplate: await WebService({
                        endPoint: `CommonUtility/Edit/staticemailtemplate?TemplateId=${id || 0}`,
                        dispatch,
                    }).then((c) => (c.length > 0 ? c[0] : {}))
                }
                setData(data);
                setShow(true);
            }
        },
    }));
    const handleClose = () => setShow(false);
  
    const GetMailGroups=async()=>{
      await WebService({
        endPoint: 'CommonUtility/StaticGroups',
        dispatch,
    }).then(res => {
      const group = res.map(item => ({
        text: item.GroupName,
        value: item.StaticGroupId,
      }));
      setMailGroups(group);
    });
    }

 

    const onSubmit = async (data) => {
      let modifiedObject={}
      if(Roles[0] === StandardConst.SystemRole.SuperAdmin){
        modifiedObject = {
          ...data, // Copy existing properties
          CompanyId: CompanyInfo.CompanyId, // Replace CompanyId with your value
        };
      }else{
        modifiedObject = {
          ...data, // Copy existing properties
          CompanyId: CompanyInfo.CompanyId, // Replace CompanyId with your value
          IsReserved: data.IsReserved !== 0 ? 0 : data.IsReserved // Ensure IsReserved is 0

        };

      }
      
      
      // Remove TemplateId from the new object
      delete modifiedObject.TemplateId;
            await WebService({
                endPoint: 'CommonUtility/staticemailtemplate',
                body: modifiedObject,
                dispatch,
            });
       
        handleClose();
        prop.callBackEvent();
    };
    console.log(MailGroup)
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
           Clone Template
          </Modal.Title>
        </Modal.Header>
        <Form defaultValues={data.MailTemplate} onSubmit={onSubmit}>
          <Modal.Body className="p-4">
            <div className="row">
              <div className="col-md-12">
                <FormInputText
                  label="Title"
                  name="Title"
                  type="text"
                  value=''
                  isRequired="true"
                />
              </div>
              <div className="col-md-12">
                <FormInputText
                  label="Subject"
                  name="Subject"
                  type="text"
                  isRequired="true"
                />
              </div>
              <div className="col-md-12">
                <FormInputText
                  label="Body"
                  name="Body"
                   as="textarea"
                  type="text"
                  isRequired="true"
                />
              </div>
              <div className="col-md-12">
                <FormInputDropdown
                  name="Status"
                  ddOpt={StandardConst.ActiveInActiveStatusIdDropdown}
                  label="Status"
                  isRequired="true"
                />
              </div>
              <div className="col-md-12">
                <FormInputText label="SqlQuery" name="SqlQuery" type="text" />
              </div>
             
             {Roles[0] === StandardConst.SystemRole.SuperAdmin && <div>
                <FormInputDropdown
                label='Add it to a group'
                name='MailTemplateGroupId'
                ddOpt={MailGroup}
                />
                <div className="d-flex  flex-row  justify-content-end hover:text-primary"><Link to='/ManageGroups'>Add new Group</Link></div>
              </div>}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button id="btnSubmit" variant="outline-primary" type="submit">
              {" "}
             Clone 
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
};

export default forwardRef(CloneMailTemplate);
