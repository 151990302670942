import Button from "react-bootstrap/esm/Button";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import { saveAs } from "file-saver";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { StandardConst } from "./StandardConst";
import { useSelector } from "react-redux";
import html2pdf  from "html2pdf.js";
import wiseofficedayLogo from '../../src/Images/WiseOfficeDayLogo.png'
const QRCodeGeneratorDownloadComponent = ({ data, showWodLogo, showLogo, FileName, EventLogo, Text }) => {
  Text ??= null;
  EventLogo ??= null;
  EventLogo = EventLogo === "" ? null : EventLogo;
  FileName ??= "QrCode";
  data ??= null;
  showLogo ??= false;
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo);
  const handleDownload = () => {
    const qrCodeNode = document.getElementById('qrcode');  // The HTML element to convert

    if (qrCodeNode) {
      const options = {
        margin: 1,
        filename: 'qrcode-content.pdf',  // File name for the downloaded PDF
        image: { type: 'jpeg', quality: 0.98 },  // Image quality for rendering
        html2canvas: { scale: 2 },  // Scale for rendering the HTML content
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }  // PDF format
      };

      html2pdf()
        .from(qrCodeNode)
        .set(options)
        .save();
    } else {
      console.error("QR Code container not found.");
    }
  };

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const [logo, setLogo] = useState("");

  const getLogoData = async() => {
    if(EventLogo !== null || CompanyInfo?.Logo !== null){
      const logoData = await fetch(`${StandardConst.apiBaseUrl}/Visitor/getLogo/${EventLogo !== null ? EventLogo : CompanyInfo?.Logo}`);
      const LogoHTMLContent = await logoData.text();
      setLogo(LogoHTMLContent === "Logo File Not Found" ? "" : LogoHTMLContent);
    }
  }

  

  useEffect(() => {
    if(showLogo){
      getLogoData();
    }
  }, [showLogo]);
  return (
    <>
      <div className="d-flex flex-column justify-content-center">
        <div
          id="qrcode"
          className={`d-flex flex-column`}
          style={{
            padding: "10px",
            background: "#fff",
          }}
        >
          {(logo !== "")  && (
                <div className="p-2 d-flex justify-content-center">
                  <div style={{ width: "100px" }}>
                    <img src={logo} alt=""/>
                  </div>
                </div>
            )
          }

          <div className="d-flex justify-content-center p-2">
            <QRCode value={data} viewBox={`0 0 256 256`} size={250} />
          </div>
          {(Text !== null) && (
            <label className="justify-content-center text-center">{Text}</label>
          )}

          {
            showWodLogo && (
                <div className="p-2 d-flex justify-content-center">
                  <div style={{ width: "100px"}}>
                    <img src={wiseofficedayLogo} alt=""/>
                  </div>
                </div>
            )
          }
        </div>

        <div className="d-flex justify-content-center">
          <Button
            onClick={handleDownload}
            className="mt-3 d-flex justify-content-center"
            variant="outline-primary"
            size="sm"
          >
            <CloudDownloadIcon color="inherit" sx={{ fontSize: 20 }} />
            &nbsp; Download QR
          </Button>
        </div>
      </div>
    </>
  );
};

export default QRCodeGeneratorDownloadComponent;
