import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormAutoCompleteDropdown, FormInputDropdown, FormInputText } from '../Form';
import { WebService } from '../../Services/WebService';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from '../../Services/StandardConst';
import { chain } from 'underscore';
import { Autocomplete, TextField } from "@mui/material";
import { WSSuccessAlert } from '../../Services/WSAlert';


const AddEditAgentTicketCategory = ({ callBackEvent }, ref) => {
    
    const [show, setShow] = useState(false);
    const [data, setData] = useState({});
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [autoErrorShow, setAutoErrorShow] = useState(false);
    const [selectedTicketCategory, setSelectedTicketCategory] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [ticketCategoriesList, setTicketCategoriesList] = useState([]);

    useEffect(() => {
        Promise.all([
            fetchAllAgents(),
            fetchAllTicketCategories()
        ])
    }, []);

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id === 0) {
                setData({});
                setIsDataLoaded(true);
            } else {
                setShow(false);
                await fetchAssoicatedData(id);
                setIsDataLoaded(true);
                setShow(true);
            }
        },
    }));

    const fetchAssoicatedData = async (id) => {
        const data = await WebService({
                endPoint: `CommonUtility/Edit/ticketcategoryAgents?AgentId=${id || 0}`,
                dispatch,
            })
            .then((res) => {
                return res.reduce((acc, item) => {
                    if (!acc.AgentId) {
                        acc.AgentId = AgentsList?.filter(val => val.AgentId === item.AgentId)
                        acc.CompanyId = item.CompanyId;
                        acc.AssociatedTicketCategoryIds = [];
                    }
                    acc.AssociatedTicketCategoryIds.push(item.TicketCategoryId);
                    return acc;
                }, {})
            });
            
        if(Object.keys(data).length > 0) {
            setData(data);
            setSelectedTicketCategory(ticketCategoriesList.filter(item => data.AssociatedTicketCategoryIds.includes(item.value)));
        }else{
            setData({});
            setSelectedTicketCategory([]);
        }
    }

    const handleClose = () => {
        setShow(false)
        setData({});
        setSelectedTicketCategory([]);
        setAutoErrorShow(false);
    };

    const schema = yup
        .object()
        .shape({
            AgentId: yup
                .array()
                .of(
                    yup.object().shape({}),
                )
                .min(1, StandardConst.ValidationMessages.RequiredFieldMsg)
                .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
                .required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
        .required();

    const onSubmit = async (data) => {
        data.CompanyId = companyInfo.CompanyId;
        data.AgentId = data.AgentId[0].AgentId;

        await WebService({
            endPoint: `CommonUtility/ticketcategoryAgents?AgentId=${data.AgentId}`,
            method: "DELETE",
            dispatch
        });
        if(selectedTicketCategory.length > 0){
            setAutoErrorShow(false);
            selectedTicketCategory.map(async (item) => {
                data.TicketCategoryId = item.value;
                    await WebService({
                        endPoint: "CommonUtility/ticketcategoryAgents",
                        body: data,
                        dispatch,
                    }).then(res => {
                        WSSuccessAlert("Success", "Assignment completed successfully");
                    });
            });
            callBackEvent();
            handleClose();
        }else {
            setAutoErrorShow(true);
        }
    }

    const [AgentsList, setAgentsList] = useState([]);

    const fetchAllAgents = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/agents?CompanyId=${StandardConst.YesNo.Yes}`,
            dispatch,
            requiredLoader: false
        });
        const modifiedData = data.filter((r) => r.FullName !== "");
        setAgentsList(modifiedData);
    }
    const fetchAllTicketCategories = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/ticketcategories?CompanyId=${StandardConst.YesNo.Yes}`,
            dispatch,
            requiredLoader: false
        });
        const list = data?.map((item) => {
            return { text: item.CategoryName, value: item.TicketCategoryId, ParentId: item.ParentId }
        });
        setTicketCategoriesList(list);
    }
    
    // Function to handle change in selected ticket categories
    const handleCategoryChange = (event, selectedOptions) => {
        (selectedOptions.length === 0) ? setAutoErrorShow(true) : setAutoErrorShow(false);
        setSelectedTicketCategory(selectedOptions);
    };
    if (!isDataLoaded) {
        return <></>;
    }
    
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data && data.TicketCategoryAgentId || 0) === 0
                        ? "Assign Ticket Category"
                        : "Assign Ticket Category"
                    }
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <FormAutoCompleteDropdown
                                name="AgentId"
                                data={chain(AgentsList ?? [])
                                    .sortBy((s) => s?.FullName)
                                    .value()}
                                label="Agent Name"
                                optionText="FullName"
                                isRequired="true"
                                setValue={(val) => fetchAssoicatedData(val[0].AgentId)}
                                // value={[AgentsList?.find(val => val.AgentId === data?.AssignTicketCategory?.AgentId)]}
                            />
                        </div>

                        <div className="col-md-12 mt-2">
                            <Autocomplete
                                multiple
                                id="grouped-demo"
                                options={ticketCategoriesList}
                                groupBy={(option) => option.ParentId === null ? option.text : ""}
                                getOptionLabel={(option) => option?.text}
                                value={selectedTicketCategory}
                                onChange={handleCategoryChange}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Ticket Categories"
                                        error={autoErrorShow} // Set error state of TextField
                                        helperText={(autoErrorShow) && "Select atleast one ticket category"} // Display error message if error is true
                                    />
                                )}
                            />
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAccessLevelSubmit" variant="outline-primary" type="submit">
                        {" "}
                        {(data && data.TicketCategoryAgentId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default forwardRef(AddEditAgentTicketCategory);