import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/esm/Button";

import { ActionPermission, PageInfo } from '../PageInfo';
import { WebService } from '../../Services/WebService';
import ActionButton from '../../Services/ActionButton';
import SnackbarComponent from '../../Services/SnackbarComponent';
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { StandardConst } from '../../Services/StandardConst';
import DeleteConfirmAlert from '../../Services/AlertComponent';
import AddEditManageGroup from './AddEditManageGroup';

const ManageGroup = () => {

    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    PageInfo({ pageTitle: "Manage Groups" });
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});

    // need to update permissions
    useEffect(() => {
        SetPermission({
            ManageAdd: ActionPermission("Group Generic - For All Tables - EDIT"),
            ManageEdit: ActionPermission("Group Generic - For All Tables - ADD"),
            ManageDelete: ActionPermission("Group Generic - For All Tables - DELETE"),
        });
    }, [permissionList]);
    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Manage Groups";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const addEditModalRef = useRef();

    const AddButtonText = "static Group";
    const dispatch = useDispatch();
    const [deletedName, setDeletedName] = useState(null);

    const fetchStaticGroups = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/StaticGroups/`,
            dispatch,
        });
        setRecords(data);
    }

    useEffect(() => {
        fetchStaticGroups();
    }, []);

    const [bData,] = React.useState([
        {
            title: "Master",
            hrefLink: "#",
        },
        {
            title: "Staic Groups",
            hrefLink: "#",
        },
    ]);

    const onDelete = async (Details) => {
        const data = Details.split(',');
        const StaticGroupId = data[0];
        setDeletedName(data[1]);
        await WebService({
            endPoint: `CommonUtility/StaticGroups?StaticGroupId=${StaticGroupId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchStaticGroups();
    };

    const columns = [
        {
            Text: "Group Name",
            Value: "GroupName"
        },
        {
            Text: "Group Description",
            Value:"GroupDescription"
        },
        {
            Text: "Group Made For",
            Value:"GroupMadeFor"
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
          
            isVisiable: permission.ManageEdit || permission.ManageDelete || permission.ManageAdd,
            render: (dr) => (
                <>
                {
                (dr.IsReserved === 0 || (dr.IsReserved === 1 && Roles[0] === StandardConst.SystemRole.SuperAdmin)) &&
                <span className='d-flex col-auto'>
                    <ActionButton
                        onClick={() => fnEdit(dr.StaticGroupId)}
                        IconName="Edit"
                        disabled={!permission.ManageEdit}
                        IconTooltip={"Edit static Group"}
                        id={`btnUserTypeEdit_${dr.StaticGroupId}`}
                    />
                    <ActionButton
                         disabled={!permission.ManageDelete}
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.GroupName, // Message of Alert
                                `${dr.StaticGroupId},${dr.GroupName}`  // Endpoint to hit for delete
                            )}
                        IconName="Delete"
                        IconTooltip={"Delete Manage Groups"}
                        id={`btnStaticGroup${dr.StaticGroupId}`}
                    />
                
                </span>}
                </>
            ),
        },
    ];

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={`static Group ${(deletedName !== null) ? deletedName : ""} Deleted successfully`} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.UserTypeheaderValueNoResults}
                        subHeaderValue={StandardConst.UserTypeSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {permission.ManageAdd && (
                                    <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_${AddButtonText}`}>
                                        Add {AddButtonText}
                                    </Button>
                                )}
                            </>
                        }
                    />
                }
                onAddEvent={() => fnEdit()}
                IsAddButtonVisible={permission.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
            <AddEditManageGroup
                callBackEvent={async () => await fetchStaticGroups()}
                ref={addEditModalRef}
            ></AddEditManageGroup>
        </>
    )
}
export default ManageGroup;