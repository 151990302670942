import Cookies from "universal-cookie";
import { createSlice } from "@reduxjs/toolkit";
import _ from "underscore";

const performancePermission = [
  {
    SystemCode: "Performance - Add",
    DBCode: "PA",
  },
  {
    SystemCode: "Performance - Edit",
    DBCode: "PE",
  },
  {
    SystemCode: "Performance - Delete",
    DBCode: "PD",
  },
  {
    SystemCode: "Performance - Modify Date",
    DBCode: "PMD",
  },
  { SystemCode: "Performance - All Employees", DBCode: "PSU" },
  { SystemCode: "Performance - My Team", DBCode: "PMT" },
  {
    SystemCode: "Performance - Employee Rating",
    DBCode: "PER",
  },
  {
    SystemCode: "Performance - Manager Rating",
    DBCode: "PMR",
  },
  {
    SystemCode: "Performance - Final Rating",
    DBCode: "PFR",
  },
  {
    SystemCode: "Performance - Final Review Comment",
    DBCode: "PFRC",
  },
  {
    SystemCode: "Performance - HR Comment",
    DBCode: "PHRC",
  },
  {
    SystemCode: "Performance - Employee Self Assessment",
    DBCode: "PESA",
  },
  {
    SystemCode: "Performance - Manager Assessment",
    DBCode: "PMA",
  },
];

const leavePermission = [
  {
    SystemCode: "Leave - Add",
    DBCode: "LA",
  },
  {
    SystemCode: "Leave - Edit",
    DBCode: "LE",
  },
  {
    SystemCode: "Leave - Cancel",
    DBCode: "LC",
  },
  {
    SystemCode: "Leave - Approve Reject",
    DBCode: "LAR",
  },
  {
    SystemCode: "Leave - Search All User",
    DBCode: "LSAU",
  },
  {
    SystemCode: "Leave - Allocation",
    DBCode: "LAC",
  },
];


const masterMappingPermission = [
  { SystemCode: "Roles - Add", DBCode: "RA" },
  { SystemCode: "Roles - Edit", DBCode: "RE" },
  { SystemCode: "Roles - Delete", DBCode: "RD" },
  { SystemCode: "Permission - Add", DBCode: "UPA" },
  { SystemCode: "Permission - Edit", DBCode: "UPE" },
  { SystemCode: "Permission - Delete", DBCode: "UPD" },
  { SystemCode: "Menu - Add", DBCode: "MA" },
  { SystemCode: "Menu - Edit", DBCode: "ME" },
  { SystemCode: "Menu - Delete", DBCode: "MD" },
  { SystemCode: "Status - Add", DBCode: "SA" },
  { SystemCode: "Status - Edit", DBCode: "SE" },
  { SystemCode: "Status - Delete", DBCode: "SD" },
  { SystemCode: "Tax - Add", DBCode: "TA" },
  { SystemCode: "Tax - Edit", DBCode: "TE" },
  { SystemCode: "Tax - Delete", DBCode: "TD" },
  { SystemCode: "Key Rating - Add", DBCode: "KRA" },
  { SystemCode: "Key Rating - Edit", DBCode: "KRE" },
  { SystemCode: "Key Rating - Delete", DBCode: "KRD" },
  { SystemCode: "Leave Type - Add", DBCode: "LTA" },
  { SystemCode: "Leave Type - Edit", DBCode: "LTE" },
  { SystemCode: "Leave Type - Delete", DBCode: "LTD" },
  { SystemCode: "Job Function - Add", DBCode: "JFA" },
  { SystemCode: "Job Function - Edit", DBCode: "JFE" },
  { SystemCode: "Job Function - Delete", DBCode: "JFD" },
  { SystemCode: "Document Type - Add", DBCode: "DTA" },
  { SystemCode: "Document Type - Edit", DBCode: "DTE" },
  { SystemCode: "Document Type - Delete", DBCode: "DTD" },
  { SystemCode: "User Role - Add", DBCode: "URA" },
  { SystemCode: "User Role - Delete", DBCode: "URD" },
  { SystemCode: "Menu Role - Add", DBCode: "MRA" },
  { SystemCode: "Menu Role - Delete", DBCode: "MRD" },
  { SystemCode: "Role Permission - Add", DBCode: "RPA" },
  { SystemCode: "Role Permission - Delete", DBCode: "RPD" },
  { SystemCode: "Registration - Approval", DBCode: "RAP" },
  { SystemCode: "Department - Add", DBCode: "DEPTA" },
  { SystemCode: "Department - Edit", DBCode: "DEPTE" },
  { SystemCode: "Department - Delete", DBCode: "DEPTD" },
];
const timeSheetReportPermission = [
  { SystemCode: "TimeSheet Report - Search All Users", DBCode: "SAU" },
  { SystemCode: "TimeSheet Report - Reporting User", DBCode: "TSRRU" },
];

const timeSheetApprovePermission = [
  { SystemCode: "Timesheet - Approve Everyones Timesheet", DBCode: "TAET" },
  { SystemCode: "Timesheet - Approve Team Timesheet", DBCode: "TATT" },
];
const payslipPermission = [
  { SystemCode: "PaySlip - Add", DBCode: "PSA" },
  { SystemCode: "PaySlip - Delete", DBCode: "PSD" },
  { SystemCode: "PaySlip - Edit", DBCode: "PSE" },
  { SystemCode: "PaySlip - Publish", DBCode: "PSP" },
  { SystemCode: "PaySlip - Search AllUser", DBCode: "PSSAU" },
  { SystemCode: "PaySlip - Add Payment", DBCode: "PSAP" },
  { SystemCode: "PaySlip - Delete Payment", DBCode: "PSDP" },
];
const projectPermission = [
  { SystemCode: "Project - Add", DBCode: "PRA" },
  { SystemCode: "Project - Edit", DBCode: "PRE" },
  { SystemCode: "Project - Delete", DBCode: "PRD" },
  { SystemCode: "Project - AssignTeam", DBCode: "PEAT" },
  { SystemCode: "Project - ManageTask", DBCode: "PRMT"},
];
const projectRolePermission = [
  { SystemCode: "Project Role - Add", DBCode: "PROLEA" },
  { SystemCode: "Project Role - Edit", DBCode: "PROLEE" },
  { SystemCode: "Project Role - Delete", DBCode: "PROLED" },
];
const holidayPermission = [
  { SystemCode: "Holiday - Add", DBCode: "HA" },
  { SystemCode: "Holiday - Edit", DBCode: "HE" },
  { SystemCode: "Holiday - Delete", DBCode: "HD" },
];
const badgeTemplatePermission = [
  { SystemCode: "Badge Template - Add", DBCode: "BTA" },
  { SystemCode: "Badge Template - Edit", DBCode: "BTE" },
  { SystemCode: "Badge Template - Delete", DBCode: "BTD" },
  { SystemCode: "Badge Template - Download", DBCode: "BTDD" },
];
const VisitorPermission = [
  { SystemCode: "Visitor - Add", DBCode: "VA" },
  { SystemCode: "Visitor - Edit", DBCode: "VE" },
  { SystemCode: "Visitor - Checkout", DBCode: "VCHKOUT" },
  { SystemCode: "Visitor - CheckIn", DBCode: "VCHKIN" },
  { SystemCode: "Visitor - PrintBadge", DBCode: "VPB" },
  { SystemCode: "Visitor - DownloadBadge", DBCode: "VDB" },
  { SystemCode: "Visitor - Delete", DBCode: "VD" },
];
const salaryTemplatePermission = [
  { SystemCode: "Salary Template - Add", DBCode: "STA" },
  { SystemCode: "Salary Template - Edit", DBCode: "STE" },
];

const InterviewPermissions =[
  {SystemCode: "Interview - Feedback",DBCode:'INTRVFK'}
]
const salaryComponentPermission = [
  { SystemCode: "Salary - Component Add", DBCode: "SCA" },
  { SystemCode: "Salary - Component Edit", DBCode: "SCE" },
  { SystemCode: "Salary - Component Delete", DBCode: "SCD" },
];
const salaryPackagePermission = [
  { SystemCode: "Salary Package - Add", DBCode: "SPA" },
  { SystemCode: "Salary Package - Edit", DBCode: "SPE" },
  { SystemCode: "Salary Package - Search AllUser", DBCode: "SPSAU" },
];
const UserProfilePermission = [
  { SystemCode: "User Profile - Add", DBCode: "UPFA" },
  { SystemCode: "User Profile - Edit", DBCode: "UPFE" },
  { SystemCode: "User Profile - View", DBCode: "UPFV" },
  { SystemCode: "Employee - All - Manager - Edit", DBCode: "EAME" },
  { SystemCode: "Employee - All - Office Location - Edit", DBCode: "EAOL" },
  { SystemCode: "Employee - Profile - Save", DBCode: "EPS" },
  { SystemCode: "Employee - All - Profile - Save", DBCode: "EAPS" },
  { SystemCode: "Employee - All - Profile Upload", DBCode: "EAPU" },
  { SystemCode: "Employee Custom Field - Add", DBCode: "ECFA" },
  { SystemCode: "Employee Custom Field - Edit", DBCode: "ECFE" },
  { SystemCode: "Employee Custom Field - Delete", DBCode: "ECFD" },
  { SystemCode: "Employee Payslip Custom Field - Add", DBCode: "EPCFA" },
  { SystemCode: "Employee Payslip Custom Field - Edit", DBCode: "EPCFE" },
  { SystemCode: "Employee Payslip Custom Field - Delete", DBCode: "EPCFD" },
  { SystemCode: "Edit Employee All Details", DBCode: "EEAD" },
  { SystemCode: "Employee Documents - delete", DBCode: "EDD" },
  {SystemCode: "Employee - Exit",DBCode:"EMPEXIT"},
  {SystemCode:'Employee - Block Employee Login',DBCode:"EMPBLOCK"}
];
const EmployeeKraPermission = [
  { SystemCode: "Employee KRA - Search All User", DBCode: "EKRASAU" },
];

const officelocationPermission = [
  { SystemCode: "Office Location - Add", DBCode: "OLA" },
  { SystemCode: "Office Location - Edit", DBCode: "OLE" },
  { SystemCode: "Office Location - Delete", DBCode: "OLD" },
];

const EmployeeDocumentPermission = [
  { SystemCode: "EmployeeDocument - Reject", DBCode: "EDRE" },
  { SystemCode: "EmployeeDocument - Approve", DBCode: "EDAPP" },
  { SystemCode: "EmployeeDocument - View", DBCode: "EDVI" },
  { SystemCode: "Employee - View Document", DBCode: "EVD" }, // it is for Document tab
  { SystemCode: "Employee - ALL - View Document", DBCode: "EAVD" }, // it is for Document tab
];

const ConfigActionPermission = [
  { SystemCode: "Config Action - Admin", DBCode: "CGADMIN" },
  { SystemCode: "Config Action - Company", DBCode: "CGCOMPANY" },
  { SystemCode: "Config Action - HR", DBCode: "CGHR" },
  { SystemCode: "Config Action - Manager", DBCode: "CGMAN" },
  { SystemCode: "Config Action - Employee", DBCode: "CGEMP" },
];

const AssignTeamPermission = [
  { SystemCode: "Assign Team - Add", DBCode: "ASSTA" },
  { SystemCode: "Assign Team - Edit", DBCode: "ASSTE" },
  { SystemCode: "Assign Team - Delete", DBCode: "ASSTD" },
];

const CustomerDetails = [
  { SystemCode: "Customer - Add", DBCode: "ACUST" },
  { SystemCode: "Customer - Edit", DBCode: "ECUST" },
  { SystemCode: "Customer - Delete", DBCode: "DCUST" },
];



const BankDetailsPermission = [
  { SystemCode: "Bank Details - Search All User", DBCode: "BDSAU" },
  { SystemCode: "Bank Detail Custom Field - Add", DBCode: "BCFA" },
  { SystemCode: "Bank Detail Custom Field - Edit", DBCode: "BCFE" },
  { SystemCode: "Bank Detail Custom Field - Delete", DBCode: "BCFD" },
];
const AttendanceReportPermission = [
  { SystemCode: "Attendance Report - Search All Users", DBCode: "ARSAU" },
];
const DesignationPermission = [
  { SystemCode: "Designation - Add", DBCode: "ADESI" },
  { SystemCode: "Designation - Edit", DBCode: "EDESI" },
  { SystemCode: "Designation - Delete", DBCode: "DDESI" },
];
const DashboardPermission = [
  { SystemCode: "Company Dashboard", DBCode: "CDASH" },
  { SystemCode: "HR Dashboard", DBCode: "HRDASH" },
  { SystemCode: "Employee Dashboard", DBCode: "EDASH" },
  { SystemCode: "Job Applicant Dashboard", DBCode: "JADASH" },
];

const VisitorTypePermission = [
  { SystemCode: "Visitor Type - Add", DBCode: "VITYA" },
  { SystemCode: "Visitor Type - Edit", DBCode: "VITYE" },
  { SystemCode: "Visitor Type - Delete", DBCode: "VITYD" },
];

const InvoicePermission = [
  { SystemCode: "Invoice - Generate", DBCode: "IG" },
  { SystemCode: "Invoice - Edit", DBCode: "IE" },
  { SystemCode: "Invoice - View", DBCode: "IV" },
  { SystemCode: "Invoice - Delete", DBCode: "ID" },
  { SystemCode: "Invoice - Print", DBCode: "IP" },
  { SystemCode: "Invoice - Download", DBCode: "INVD" },
  { SystemCode: "Invoice - Mail", DBCode: "IM" },
  { SystemCode: "Invoice - Publish", DBCode: "IPUB" },
  { SystemCode: "Invoice - Add Payment", DBCode: "IAP" },
  { SystemCode: "Invoice - Delete Payment", DBCode: "IDP" },
];
const SendMailPermission=[
  { SystemCode: "Send Mail To Users", DBCode: "sendmailtousers" },

  
]
const ContactsForOfficeLocation = [
  { SystemCode: "Office Location Contact - Add", DBCode: "OLCA" },
  { SystemCode: "Office Location Contact - Edit", DBCode: "OLCE" },
  { SystemCode: "Office Location Contact - Delete", DBCode: "OLCD" },
];

const Event = [
  { SystemCode: "Event - Add", DBCode: "EA" },
  { SystemCode: "Event - Edit", DBCode: "EE" },
  { SystemCode: "Event - Delete", DBCode: "ED" },
  { SystemCode: "Event - View and Download QR Code", DBCode: "EQR" },
  { SystemCode: "Event - Add Co-ordinator", DBCode: "EACO" },
  { SystemCode: "Event - Manage Attributes", DBCode: "EMA" },
  { SystemCode: "Event - Manage Visitor", DBCode: "EMV" },
];

const AccessLevelTypePermission = [
  { SystemCode: "Access Level Type - Add", DBCode: "ALTA" },
  { SystemCode: "Access Level Type - Edit", DBCode: "ALTE" },
  { SystemCode: "Access Level Type - Delete", DBCode: "ALTD" },
];

const AssetTypePermission = [
  { SystemCode: "Asset Type - Add", DBCode: "ASTA" },
  { SystemCode: "Asset Type - Edit", DBCode: "ASTE" },
  { SystemCode: "Asset Type - Delete", DBCode: "ASTD" },
];

const AssetCustomFieldsPermission = [
  { SystemCode: "Asset Custom Field - Add", DBCode: "ACFA" },
  { SystemCode: "Asset Custom Field - Edit", DBCode: "ACFE" },
  { SystemCode: "Asset Custom Field - Delete", DBCode: "ACFD" },
];

const ManageAgents = [
  { SystemCode: "Manage Agents - Add", DBCode: "MAGA" },
  { SystemCode: "Manage Agents - Edit", DBCode: "MAGE" },
  { SystemCode: "Manage Agents - Delete", DBCode: "MAGD" },
];

const AssetsPermission = [
  { SystemCode: "Assets - Add", DBCode: "ASA" },
  { SystemCode: "Assets - Edit", DBCode: "ASE" },
  { SystemCode: "Assets - Delete", DBCode: "ASD" },
  { SystemCode: "Assets - QR Code", DBCode: "ASQR" },
];

const FaceBiometricsPermission = [
  { SystemCode: "Employee - Face Biometric - View", DBCode: "EFBV" },
  { SystemCode: "Employee - Face Biometric - Delete", DBCode: "EFBD" },
  { SystemCode: "Employee - All - Face Biometric - View", DBCode: "EAFV" },
  { SystemCode: "Employee - All - Face Biometric - Delete", DBCode: "EAFD" },
  { SystemCode: "Employee - Face Biometric - Registration", DBCode: "EFBR" },
  { SystemCode: "Employee - All - Face Biometric - Registration", DBCode: "EAFR" },
  { SystemCode: "Employee-All-Face Biometric - Approve", DBCode: "EFBA" },
  { SystemCode: "Employee-All-Face Biometric - Reject", DBCode: "EFRJ" },
  { SystemCode: "Employee - Face Biometric", DBCode: "EFB" }, // it is for face biometric tab
  { SystemCode: "Employee - All - Face Biometric", DBCode: "EAFB" }, // it is for face biometric tab
];

const FingerPrintBiometricPermission = [
  { SystemCode: "Employee - Finger Print Biometric", DBCode: "EFPB" }, // it is for Finger Print Biometric tab
  { SystemCode: "Employee - All - Finger Print Biometric", DBCode: "EAFP" }, // it is for Finger Print Biometric tab
];

const FamiliesPermission = [
  { SystemCode: "Employee - View Families", DBCode: "EVF" }, // it is for Families tab
  { SystemCode: "Employee - ALL - View Families", DBCode: "EAVF" }, // it is for Families tab
  { SystemCode: "Employee - Family - Add", DBCode: "EFA" },
  { SystemCode: "Employee - All - Family - Add", DBCode: "EAFA" },
  { SystemCode: "Employee - Family - Edit", DBCode: "EFE" },
  { SystemCode: "Employee - All - Family - Edit", DBCode: "EAFE" },
  { SystemCode: "Employee - Family - Delete", DBCode: "EFD" },
  { SystemCode: "Employee - All - Family - Delete", DBCode: "EADF" },
];

const VehicleCustomFieldsPermission = [
  { SystemCode: "Vehicle Custom Field - Add", DBCode: "VCFA" },
  { SystemCode: "Vehicle Custom Field - Edit", DBCode: "VCFE" },
  { SystemCode: "Vehicle Custom Field - Delete", DBCode: "VCFD" },
];

const SubscriptionType = [
  { SystemCode: "Subscription Type - Add", DBCode: "SBSTA" },
  { SystemCode: "Subscription Type - Edit", DBCode: "SBSTE" },
  { SystemCode: "Subscription Type - Delete", DBCode: "SBSTD" },
];

const UserType = [
  { SystemCode: "User Type - Add", DBCode: "UTTA" },
  { SystemCode: "User Type - Edit", DBCode: "UTTE" },
  { SystemCode: "User Type - Delete", DBCode: "UTTD" },
];

const ManageJobPostingType = [
  { SystemCode: "Manage Job Posting - Add", DBCode: "MJPA" },
  { SystemCode: "Manage Job Posting - Edit", DBCode: "MJPE" },
  { SystemCode: "Manage Job Posting - Delete", DBCode: "MJPD" },
  { SystemCode: "View Job Applicant List", DBCode: "VJAL" },
];

const ManageSubscription = [
  { SystemCode: "Manage Subscription - Add", DBCode: "MSBSTA" },
  { SystemCode: "Manage Subscription - Edit", DBCode: "MSBSTE" },
  { SystemCode: "Manage Subscription - Delete", DBCode: "MSBSTD" },
];

const EmployeeIdCardPermission = [
  { SystemCode: "Employee Id Card - Edit", DBCode: "EICE" },
  { SystemCode: "Employee Id Card - Delete", DBCode: "EICD" },
];

const AllMemberShowPermission = [
  { SystemCode: "My Team - All Employees", DBCode: "MTAE" },
];

const ApplicantPermission = [
  { SystemCode: "Profile - Job Applicant", DBCode: "PJA" },
];

const EmployeePermission = [
  { SystemCode: "Approved Employees - Delete", DBCode: "AED" },
  { SystemCode: "Non-Approved Employees - Delete", DBCode: "NAED" },
];

const HeaderPermissions = [
  { SystemCode: "Employee - See all Attendance Summary", DBCode: "ESAS" },
];


const DocumentTemplatesPermission = [

  { SystemCode: "Document Templates - Add", DBCode: "DTEMPA" },
  { SystemCode: "Document Templates - Edit", DBCode: "DTEMPE" },
  { SystemCode: "Document Templates - Delete", DBCode: "DTEMD" },
  { SystemCode: "Document Templates - Download", DBCode: "DTEMPD" },
  { SystemCode: "Document Templates - View", DBCode: "DTEMPV" },
  { SystemCode: "Document Templates - History", DBCode: "DTEMPH" },


 ]


 const GenericGroupPermission = [
  { SystemCode: "Group Generic - For All Tables - ADD", DBCode: "GROUP_GENERIC_ADD" }, 
  { SystemCode: "Group Generic - For All Tables - ADD", DBCode: "GROUP_GENERIC_EDIT" },
  { SystemCode: "Group Generic - For All Tables - ADD", DBCode: "GROUP_GENERIC_DELETE" },
 ]


 const ManageEmailTemplatePermission = [
  { SystemCode: "Email Template - Add", DBCode: "ETA" },  
  { SystemCode: "Email Template - Edit", DBCode: "ETE" },
  { SystemCode: "Email Template - Delete", DBCode: "ETD" },
  { SystemCode: "Email Template - View", DBCode: "ETV" },
 ]

 const ManageSkillPermissions=[
  {SystemCode:'Manage Skills - Add',DBCode:'MSKILLA'},
  {SystemCode:'Manage Skills - Edit',DBCode:'MSKILLE'},
  {SystemCode:'Manage Skills - Delete',DBCode:'MSKILLPD'},
 ]


const cookies = new Cookies();
export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loged: true,
    PermissionList: [],
    MenuList: [
      {
        MenuId: 0,
        MenuText: "Dashboard",
        Icon: "fa fa-dashboard",
        Route: "Dashboard",
        ParentId: null,
        OrderNo: 1,
      },
    ],
    PermissionCodeList: timeSheetReportPermission
      .concat(performancePermission)
      .concat(timeSheetApprovePermission)
      .concat(projectPermission)
      .concat(projectRolePermission)
      .concat(leavePermission)
      .concat(payslipPermission)
      .concat(holidayPermission)
      .concat(badgeTemplatePermission)
      .concat(VisitorPermission)
      .concat(salaryTemplatePermission)
      .concat(salaryComponentPermission)
      .concat(salaryPackagePermission)
      .concat(UserProfilePermission)
      .concat(EmployeeKraPermission)
      .concat(officelocationPermission)
      .concat(EmployeeDocumentPermission)
      .concat(masterMappingPermission)
      .concat(ConfigActionPermission)
      .concat(AssignTeamPermission)
      .concat(InterviewPermissions)
      .concat(CustomerDetails)
      .concat(AttendanceReportPermission)
      .concat(BankDetailsPermission)
      .concat(DesignationPermission)
      .concat(DashboardPermission)
      .concat(VisitorTypePermission)
      .concat(InvoicePermission)
      .concat(ContactsForOfficeLocation)
      .concat(Event)
      .concat(AccessLevelTypePermission)
      .concat(AssetTypePermission)
      .concat(AssetsPermission)
      .concat(FaceBiometricsPermission)
      .concat(FingerPrintBiometricPermission)
      .concat(FamiliesPermission)
      .concat(AssetCustomFieldsPermission)
      .concat(ManageAgents)
      .concat(VehicleCustomFieldsPermission)
      .concat(SubscriptionType)
      .concat(ManageSubscription)
      .concat(EmployeeIdCardPermission)
      .concat(UserType)
      .concat(ManageJobPostingType)
      .concat(AllMemberShowPermission)
      .concat(ApplicantPermission)
      .concat(EmployeePermission)
      .concat(HeaderPermissions)
      .concat(DocumentTemplatesPermission)
      .concat(GenericGroupPermission)
      .concat(ManageEmailTemplatePermission)
      .concat(ManageSkillPermissions)
      .concat(SendMailPermission),



    CompanyInfo: {},
    AssignRole: [],
    LoggedUser: 0,
    LoggedCustomer: 0,
    ChildUsers: [],
    UserType: ''
  },
  reducers: {
    logout: (state) => {
      cookies.remove("token");
      state.loged = false;
    },
    login: (state, { payload }) => {
      cookies.set("token", payload, { path: "/" });
      state.loged = true;
    },
    setPermission: (state, { payload }) => {
      state.PermissionList = payload;
    },
    setMenu: (state, { payload }) => {
      state.MenuList = _.where(state.MenuList, { MenuId: 0 }).concat(payload);
    },
    setCompanyInfo: (state, { payload }) => {
      state.CompanyInfo = payload;
    },
    setAssignRole: (state, { payload }) => {
      state.AssignRole = payload;
    },
    setLoggedUser: (state, { payload }) => {
      state.LoggedUser = payload;
    },
    setLoggedCustomer: (state, { payload }) => {
      state.LoggedCustomer = payload;
    },
    setChildUsers: (state, { payload }) => {
      state.ChildUsers = payload;
    },
    setUserType: (state, { payload }) => {
      state.UserType = payload;
    },
  },
});
export const {
  logout,
  login,
  setPermission,
  setMenu,
  setCompanyInfo,
  setAssignRole,
  setLoggedUser,
  setLoggedCustomer,
  setChildUsers,
  setUserType
} = authSlice.actions;
export default authSlice.reducer;
