import * as yup from "yup";
import React, {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  useForm,
  useFormContext,
  FormProvider,
  Controller,
  useFieldArray,
} from "react-hook-form";
import { useAsyncDebounce } from "react-table";
import BootstrapForm from "react-bootstrap/Form";
import { ErrorMessage } from "@hookform/error-message";
import "../asset/Form.css";
import { yupResolver } from "@hookform/resolvers/yup";
import _, { chain, filter, range } from "underscore";
import Tooltip from "@mui/material/Tooltip";
import InputGroup from "react-bootstrap/InputGroup";
import { DateTime } from "luxon";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dataClone } from "../Services/UtilityService";
import { getMonth, set } from "date-fns";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CurrencyInput from 'react-currency-input-field';
import { Avatar, Box, InputAdornment, TextField } from "@mui/material";
import { CalendarMonthOutlined } from "@mui/icons-material";


export const Context = createContext(null);

export const ComposableContext = ({ children, ...otherProps }) => {
  const context = useContext(Context);
  return (
    <Context.Provider {...context} value={{ ...context, ...otherProps }}>
      {children}
    </Context.Provider>
  );
};

export const Form = forwardRef(
  ({ defaultValues, children, onSubmit, validationSchema, ...rest }, ref) => {
    defaultValues ??= {};
    onSubmit ??= (_) => {};
    validationSchema ??= yup.object().shape({}).required();
    const methods = useForm({
      defaultValues,
      mode: "onChange",
      resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
      const firstError = Object.keys(methods.formState.errors).reduce(
        (field, a) => {
          return !!methods.formState.errors[field] ? field : a;
        },
        null
      );
      if (firstError) {
        methods.setFocus(firstError);
      }
    }, [methods.formState.errors, methods.setFocus]);
    useImperativeHandle(
      ref,
      () => ({
        fnReset: methods.reset,
      }),
      []
    );
    const fnSubmit = (data) => {
      var x1 = _.mapObject(data, (value, key) => {
        if ((value ?? "") === "") return null;
        return value;
      });
      onSubmit(x1);
    };
    return (
      <FormProvider {...methods}>
        <BootstrapForm
          onSubmit={methods.handleSubmit((d) => fnSubmit(d))}
          {...rest}
          className="needs-validation"
          noValidate
        >
          {children}
        </BootstrapForm>
      </FormProvider>
    );
  }
);

export const FormCheckRadioInput = ({ name, setValue, isRadio, ...rest }) => {
  const {
    register,
    formState: { errors },
    control,
    trigger,
  } = useFormContext();
  return (
    <>
      <BootstrapForm.Check
        {...register(name)}
        error={errors[name] !== undefined}
        className={errors[name] !== undefined ? "error d-flex align-text-center" : "d-flex align-text-center"}
        id={`control_${name}`}
        type={isRadio ? "radio" : "checkbox"}
      >
        <Controller
          name={name}
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <CheckRadioInput
              type={isRadio ? "radio" : "checkbox"}
              value={value}
              onBlur={async () => await trigger(name, { shouldFocus: false })}
              setValue={(val) => {
                onChange(val);
                if(setValue) setValue(val);
              }}
              {...rest}
            />
          )}
        />

        <div className="float-start col-md-5">&nbsp;</div>
        <div className="errCls">
          <div className="float-end mb-2 col-md-7 text-truncate">
            <ErrorMessage className="marginLeft" errors={errors} name={name} />{" "}
          </div>{" "}
        </div>
      </BootstrapForm.Check>
    </>
  );
};
export const CheckRadioInput = forwardRef(
  ({ value, setValue, timeSpan, label, name, ...rest }, ref) => {
    const [filter, setFilter] = useState(value);
    var classNameMdNone = "";
    var classNameMd = "";
    if ((label || "") == "") {
      classNameMdNone = "float-start col-md-5 text-wrap d-none";
      classNameMd = "float-end col-md-12";
    } else {
      classNameMdNone = "float-start col-md-5 text-wrap";
      classNameMd = "float-end col-md-7";
    }

    const onChange = useAsyncDebounce((val) => {
      setValue(val ?? undefined);
    }, timeSpan || 0);
    useImperativeHandle(ref, () => ({
      fnReset: (data) => {
        setFilter(data);
        setValue(data || null);
      },
    }));

    useEffect(() => {
      if (value !== filter) {
        setFilter(value);
      }
    }, [value]);

    return (
      <>
        <div className={classNameMdNone}>
          {" "}
          <BootstrapForm.Check.Label style={{color: "#5a5a5a", fontFamily: "sans-serif", fontSize: "14px"}}>{label}</BootstrapForm.Check.Label>{" "}
        </div>

        <div className={classNameMd}>
          {" "}
          <BootstrapForm.Check.Input
            onChange={(e) => {
              setFilter(e.target.checked);
              onChange(e.target.checked);
            }}
            checked={filter || false}
            {...rest}
          />
        </div>
      </>
    );
  }
);

export const FormFields = ({ name, children, ...rest }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <>
      {fields.map((item, index) => (
        <>
          <ComposableContext indexNo={index} parentItem={item} listOf={name}>
            {children}
          </ComposableContext>
        </>
      ))}
    </>
  );
};

export const FormInputText = forwardRef(
  ({ name, setValue, label, dynamicLabel, ...rest }, ref) => {
    setValue ??= (v) => {};
    const otherProps = { ...rest };
    const context = useContext(Context);
    if ((context?.indexNo ?? -1) > -1) {
      name = `${context?.listOf ?? "list"}.${context?.indexNo}.${name}`;
      if ((dynamicLabel ?? "") != "") {
        label ??= context.parentItem[dynamicLabel];
      }
    }
    const {
      register,
      formState: { errors },
      control,
      trigger,
    } = useFormContext();

    const [open, setOpen] = React.useState(false);
    const handleTooltipClose = () => {
      setOpen(false);
    };

    const handleTooltipOpen = () => {
      setOpen(true);
    };

    return (
      <>
        <BootstrapForm.Group
          {...register(name)}
          // error={errors[name] !== undefined}
          controlId={`control_${name}`}
        >
          <Controller
            name={name}
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onBlur, onChange },
              fieldState: { invalid, isTouched, isDirty, error },
            }) => (
              <InputGroup
                className={`d-flex align-items-center ${
                  error !== undefined ? "error" : null
                }`}
              >
                {(otherProps?.type ?? "") !== "MonthYear" && (otherProps?.type ?? "") !== "date" && (otherProps?.type ?? "") !== "dateYear" && (otherProps?.type ?? "") !== "datetime" && (otherProps?.type ?? "") !== "currency" && (
                  <InputText
                    value={value}
                    onBlur={onBlur}
                    setValue={(val) => {
                      onChange(val);
                      setValue(val);
                    }}
                    label={label}
                    {...rest}
                  />
                )}
                {(otherProps?.type ?? "") === "date" && (
                  <InputDatePicker
                    value={value ?? null}
                    setValue={(val) => {
                      onChange(val);
                      setValue(val);
                    }}
                    label={label}
                    ref={ref}
                    id={name}
                    {...rest}
                  />
                )}
                {(otherProps?.type ?? "") === "datetime" && (
                  <InputDateTimePicker
                    value={value ?? null}
                    setValue={(val) => {
                      onChange(val);
                      setValue(val);
                    }}
                    label={label}
                    ref={ref}
                    id={name}
                    {...rest}
                  />
                )}
                {(otherProps?.type ?? "") === "dateYear" && (
                  <InputDateYearPicker
                    value={value ?? null}
                    setValue={(val) => {
                      onChange(val);
                      setValue(val);
                    }}
                    label={label}
                    ref={ref}
                    id={name}
                    {...rest}
                  />
                )}

                {(otherProps?.type ?? "") === "MonthYear" && (
                  <>
                    <InputMonthYearPicker
                      value={value ?? null}
                      setValue={(val) => {
                        onChange(val);
                        setValue(val);
                      }}
                      label={label}
                      ref={ref}
                      id={name}
                      {...rest}
                    />
                  </>
                )}
                
                {(otherProps?.type ?? "") === "currency" && (
                  <>
                    <InputCurrency
                      value={value}
                      onBlur={onBlur}
                      setValue={(val) => {
                        onChange(val);
                        setValue(val);
                      }}
                      label={label}
                      {...rest}
                    />
                  </>
                )}

                
                <div className="search-icon">
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div className={`${error !== undefined ? "" : "d-none"}`}>

                      <Tooltip
                        arrow
                        disableFocusListener
                        onClose={handleTooltipClose}
                        open={open}
                        title={
                          <ErrorMessage
                            className="marginLeft"
                            errors={errors}
                            name={name}
                          />
                        }
                      >
                        <i className="fa fa-exclamation-circle me-2" onClick={handleTooltipOpen} onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}></i>
                      </Tooltip>
                  </div>
                  </ClickAwayListener>
                </div>
              </InputGroup>
            )}
          />
        </BootstrapForm.Group>
      </>
    );
  }
);
export const FormInputFile = ({
  name,
  setValue,
  label,
  dynamicLabel,
  ...rest
}) => {
  const context = useContext(Context);
  if ((context?.indexNo ?? -1) > -1) {
    name = `${context?.listOf ?? "list"}.${context?.indexNo}.${name}`;
    if ((dynamicLabel ?? "") != "") {
      label ??= context.parentItem[dynamicLabel];
    }
  }
  const {
    register,
    formState: { errors },
    control,
    trigger,
  } = useFormContext();

  return (
    <>
      <BootstrapForm.Group
        {...register(name)}
        // error={errors[name] !== undefined}
        className={errors[name] !== undefined ? "error" : null}
        controlId={`control_${name}`}
      >
        <Controller
          name={name}
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onBlur, onChange } }) => (
            <InputGroup className={`d-flex align-items-center`}>
              <InputFile
                value={value}
                //onBlur={onBlur}
                setValue={(val) => {
                  onChange(val);
                  if(setValue) setValue(val);
                }}
                label={label}
                {...rest}
              />
              {/* <BootstrapForm.Control type="file" /> */}
              <div className="search-icon">
                <div
                  className={`${errors[name] !== undefined ? "" : "d-none"}`}
                >
                  <Tooltip
                    arrow
                    title={
                      <ErrorMessage
                        className="marginLeft"
                        errors={errors}
                        name={name}
                      />
                    }
                  >
                    <i className="fa fa-exclamation-circle me-2"></i>
                  </Tooltip>
                </div>
              </div>
            </InputGroup>
          )}
        />

        {/* <div className="float-start col-md-5">&nbsp;</div> */}
      </BootstrapForm.Group>
    </>
  );
};

export const InputMonthYearPicker = forwardRef(
  (
    {
      value,
      setValue,
      label,
      name,
      labelCss,
      isRequired,
      max,
      min,
      ...rest
    },
    ref
  ) => {
    labelCss ??= '';
    const [filter, setFilter] = useState(null);
    var isRequiredClass = isRequired === "true" ? "text-danger" : "d-none";
    var classNameMdNone = (label || '') === '' ? 'd-none' : 'col-md-5';
    var classNameMd = (label || '') === '' ? 'col-md-12' : 'col-md-7';

    useImperativeHandle(ref, () => ({
      fnReset: (data) => {
        if (data === null) {
          setFilter(null);
          setValue(null);
        } else {
          setFilter(DateTime.fromISO(data).toJSDate());
          setValue(data);
        }
      },
    }));

    useEffect(() => {
      if (value !== null && DateTime.fromISO(value).toJSDate() !== filter) {
        setFilter(DateTime.fromISO(value).toJSDate());
      }
    }, [value]);

    const years = Array.from({ length: 81 }, (_, i) => DateTime.now().year - 70 + i);
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const otherProps = { ...rest };

    return (
      <>
        <div className={classNameMdNone}>
          <BootstrapForm.Label>
            <div className={`d-flex justify-content-start ${labelCss}`}>
              <div style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>
                {label}
              </div>
              <div className={isRequiredClass}>＊</div>
            </div>
          </BootstrapForm.Label>
        </div>
        <div className={classNameMd}>
          <ReactDatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div style={{ margin: 10, display: 'flex', justifyContent: 'center' }}>
                <button
                  type="button"
                  color="primary"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <ArrowCircleLeftIcon />
                </button>
                <select
                  className="form-control"
                  value={DateTime.fromJSDate(date).year}
                  onChange={({ target: { value } }) => changeYear(Number(value))}
                >
                  {years.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <span className="mx-2"></span>
                <select
                  className="form-control"
                  value={months[date.getMonth()]}
                  onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                >
                  {months.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <ArrowCircleRightIcon />
                </button>
              </div>
            )}
            selected={filter}
            onChange={(date) => {
              setFilter(date);
              setValue(DateTime.fromJSDate(date).toFormat('yyyy-MM'));
            }}
            showMonthYearPicker
            dateFormat="MMM-yyyy"
            minDate={min ? new Date(min) : undefined}
            maxDate={max ? new Date(max) : undefined}
            {...rest}
          />
        </div>
      </>
    );
  }
);

export const InputDatePicker = forwardRef(
  (
    {
      value,
      setValue,
      timeSpan,
      label,
      name,
      labelCss,
      isRequired,
      max,
      min,
      ...rest
    },
    ref
  ) => {
    labelCss ??= "";
    const [filter, setFilter] = useState(null);
    var classNameMdNone = "";
    var isRequiredClass = "";
    if (isRequired === "true") {
      isRequiredClass = "text-danger";
    } else {
      isRequiredClass = "d-none";
    }
    var classNameMd = "";
    if ((label || "") == "") {
      classNameMdNone = "col-25  float-start col-md-5 text-wrap d-none";
      classNameMd = "float-end col-md-12 col-75 ";
    } else {
      classNameMdNone = "col-75 float-start col-md-5 text-wrap";
      classNameMd = "col-75  float-end col-md-7";
    }

    useImperativeHandle(ref, () => ({
      fnReset: (data) => {
        if ((data || null) === null) {
          setFilter(null);
          setValue(null);
        } else {
          setFilter(new Date(data));
          setValue(data);
        }
      },
    }));

    useEffect(() => {
      if ((value || null) !== null && new Date(value) !== filter) {
        setFilter(new Date(value));
      }
    }, [value]);
    const years = range(DateTime.now().year - 70, DateTime.now().year + 10, 1);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const otherProps = { ...rest };
    return (
      <>
      <div className={classNameMdNone}>
        <BootstrapForm.Label>
          <div className={`d-flex justify-content-start ${labelCss}`}>
            <div style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
              {label}
            </div>
            <div className={isRequiredClass}>＊</div>
          </div>
        </BootstrapForm.Label>
      </div>
      <div className={`${classNameMd} position-relative`}>
        <ReactDatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                type="button"
                color="primary"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <ArrowCircleLeftIcon />
              </button>
              <select
                className="form-control"
                value={DateTime.fromJSDate(date).year}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <span className="mx-2"></span>
              <select
                className="form-control"
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
    
              <button
                type="button"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <ArrowCircleRightIcon />
              </button>
            </div>
          )}
          selected={filter}
          onChange={(date) => {
            setFilter(date);
            setValue(DateTime.fromJSDate(date).toISODate());
          }}
          className={"form-control pe-5"} // Adds padding to the end of the input to make room for the icon
          dateFormat={otherProps?.dateFormat ?? "dd/MM/yyyy"}
          minDate={(min ?? null) === null ? undefined : new Date(min)}
          maxDate={(max ?? null) === null ? undefined : new Date(max)}
          withPortal
          {...rest}
        />
        <CalendarMonthOutlined
          className="position-absolute"
          style={{
            right: "10px", // Adjust as necessary for spacing
            top: "50%",
            transform: "translateY(-50%)",
            pointerEvents: "none",
            color: "#6c757d",
          }}
        />
      </div>
    </>
    
    );
  }
);
export const InputDateTimePicker = forwardRef(
  (
    {
      value,
      setValue,
      timeSpan,
      label,
      name,
      labelCss,
      isRequired,
      max,
      min,
      ...rest
    },
    ref
  ) => {
    labelCss ??= "";
    const [filter, setFilter] = useState(null);
    var classNameMdNone = "";
    var isRequiredClass = "";
    if (isRequired === "true") {
      isRequiredClass = "text-danger";
    } else {
      isRequiredClass = "d-none";
    }
    var classNameMd = "";
    if ((label || "") == "") {
      classNameMdNone = "col-25  float-start col-md-5 text-wrap d-none";
      classNameMd = "float-end col-md-12 col-75 ";
    } else {
      classNameMdNone = "col-75 float-start col-md-5 text-wrap";
      classNameMd = "col-75  float-end col-md-7";
    }

    useImperativeHandle(ref, () => ({
      fnReset: (data) => {
        if ((data || null) === null) {
          setFilter(null);
          setValue(null);
        } else {
          setFilter(new Date(data));
          setValue(data);
        }
      },
    }));

    useEffect(() => {
      if ((value || null) !== null && new Date(value) !== filter) {
        setFilter(new Date(value));
      }
    }, [value]);
    const years = range(DateTime.now().year - 70, DateTime.now().year + 10, 1);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const otherProps = { ...rest };
    return (
      <>
        <div className={classNameMdNone}>
          <BootstrapForm.Label>
            <div className={`d-flex justify-content-start  ${labelCss}`}>
              <div style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
                {label}
              </div>
              <div className={isRequiredClass}>＊</div>
            </div>
          </BootstrapForm.Label>{" "}
        </div>
        <div className={classNameMd}>
          <ReactDatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  type="button"
                  color="primary"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <ArrowCircleLeftIcon />
                </button>
                <select
                  className="form-control"
                  value={DateTime.fromJSDate(date).year}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <span className="mx-2"></span>
                <select
                  className="form-control"
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  type="button"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <ArrowCircleRightIcon />
                </button>
              </div>
            )}
            selected={filter}
            onChange={(date) => {
              setFilter(date);
              setValue(DateTime.fromJSDate(date).toISO());
            }}
            className={"form-control"}
            showTimeSelect
            timeIntervals={1}
            dateFormat={otherProps?.dateFormat ?? "dd/MM/yyyy hh:mm a"}
            minDate={(min ?? null) === null ? undefined : new Date(min)}
            maxDate={(max ?? null) === null ? undefined : new Date(max)}
            withPortal
            {...rest}
          />
        </div>
      </>
    );
  }
);
export const InputDateYearPicker = forwardRef(
  (
    {
      value,
      setValue,
      timeSpan,
      label,
      name,
      labelCss,
      isRequired,
      max,
      min,
      ...rest
    },
    ref
  ) => {
    labelCss ??= "";
    const [filter, setFilter] = useState(null);
    var classNameMdNone = "";
    var isRequiredClass = "";
    if (isRequired === "true") {
      isRequiredClass = "text-danger";
    } else {
      isRequiredClass = "d-none";
    }
    var classNameMd = "";
    if ((label || "") == "") {
      classNameMdNone = "col-25  float-start col-md-5 text-wrap d-none";
      classNameMd = "float-end col-md-12 col-75 ";
    } else {
      classNameMdNone = "col-75 float-start col-md-5 text-wrap";
      classNameMd = "col-75  float-end col-md-7";
    }

    useImperativeHandle(ref, () => ({
      fnReset: (data) => {
        if ((data || null) === null) {
          setFilter(null);
          setValue(null);
        } else {
          setFilter(new Date(data));
          setValue(data);
        }
      },
    }));

    useEffect(() => {
      if ((value || null) !== null && new Date(value) !== filter) {
        setFilter(new Date(value));
      }
    }, [value]);

    const years = range(DateTime.now().year - 70, DateTime.now().year + 10, 1);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const otherProps = { ...rest };
    return (
      <>
        <div className={classNameMdNone}>
          <BootstrapForm.Label>
            <div className={`d-flex justify-content-start  ${labelCss}`}>
              <div style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
                {label}
              </div>
              <div className={isRequiredClass}>＊</div>
            </div>
          </BootstrapForm.Label>{" "}
        </div>
        <div className={classNameMd}>
          <ReactDatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              increaseYear,
              decreaseYear,
              prevYearButtonDisabled,
              nextYearButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <button
                  type="button"
                  color="primary"
                  onClick={decreaseYear}
                  disabled={prevYearButtonDisabled}
                >
                  <ArrowCircleLeftIcon />
                </button>
                <button
                  type="button"
                  onClick={increaseYear}
                  disabled={nextYearButtonDisabled}
                >
                  <ArrowCircleRightIcon />
                </button>
              </div>
            )}
            selected={filter}
            onChange={(date) => {
              setFilter(date);
              setValue(DateTime.fromJSDate(date).toISODate());
            }}
            className={"form-control"}
            showYearPicker
            dateFormat="yyyy"
            minDate={(min ?? null) === null ? undefined : new Date(min)}
            maxDate={(max ?? null) === null ? undefined : new Date(max)}
            withPortal
            {...rest}
          />
        </div>
      </>
    );
  }
);
export const InputText = forwardRef(
  (
    { value, setValue, timeSpan, label, labelCss, name, isRequired, ...rest },
    ref
  ) => {
    labelCss ??= "";
    const [filter, setFilter] = useState(value);
    var classNameMdNone = "";
    var isRequiredClass = "";
    if (isRequired === "true") {
      isRequiredClass = "text-danger";
    } else {
      isRequiredClass = "d-none";
    }
    var classNameMd = "";
    if ((label || "") == "") {
      classNameMdNone = "col-25 float-start col-md-5 text-wrap d-none";
      classNameMd = "float-end col-md-12 col-75 ";
    } else {
      classNameMdNone = "col-75 float-start col-md-5 text-wrap";
      classNameMd = "col-75 float-end col-md-7";
    }

    const onChange = useAsyncDebounce((val) => {
      setValue(val ?? undefined);
    }, timeSpan || 0);
    useImperativeHandle(ref, () => ({
      fnReset: (data) => {
        setFilter(data);
        setValue(data || null);
      },
    }));

    useEffect(() => {
      if (value !== filter) {
        setFilter(value);
      }
    }, [value]);

    return (
      <>
        <div className={`${classNameMdNone}`}>
          <BootstrapForm.Label>
            <div className={`d-flex justify-content-start ${labelCss}`}>
              <div style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
                {label}
              </div>
              <div className={isRequiredClass}>＊</div>
            </div>
          </BootstrapForm.Label>{" "}
        </div>

        <div className={classNameMd}>
          <BootstrapForm.Control
            onChange={(e) => {
              setFilter(e.target.value);
              onChange(e.target.value);
            }}
            value={filter || ""}
            {...rest}
            id={name}
          />
        </div>
      </>
    );
  }
);

// if you want to get value in your InputCurrency so you can set predefaultValue={true} in your InputCurrency component
export const InputCurrency = forwardRef(
  (
    { value, setValue, timeSpan, label, labelCss, name, isRequired, predefaultValue,...rest },
    ref
  ) => {
    labelCss ??= "";
    const [filter, setFilter] = useState(value);
    var classNameMdNone = "";
    var isRequiredClass = "";
    if (isRequired === "true") {
      isRequiredClass = "text-danger";
    } else {
      isRequiredClass = "d-none";
    }
    var classNameMd = "";
    if ((label || "") == "") {
      classNameMdNone = "col-25 float-start col-md-5 text-wrap d-none";
      classNameMd = "float-end col-md-12 col-75 ";
    } else {
      classNameMdNone = "col-75 float-start col-md-5 text-wrap";
      classNameMd = "col-75 float-end col-md-7";
    }

    const onChange = useAsyncDebounce((val) => {
      setValue(val ?? undefined);
    }, timeSpan || 0);
    useImperativeHandle(ref, () => ({
      fnReset: (data) => {
        setFilter(data);
        setValue(data || null);
      },
    }));

    useEffect(() => {
      if (value !== filter && predefaultValue) {
        setFilter(value);
      }
    }, [value]);

    return (
      <>
        <div className={`${classNameMdNone}`}>
          <BootstrapForm.Label>
            <div className={`d-flex justify-content-start ${labelCss}`}>
              <div style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
                {label}
              </div>
              <div className={isRequiredClass}>＊</div>
            </div>
          </BootstrapForm.Label>{" "}
        </div>
        <div className={classNameMd}>
          <CurrencyInput
            className="text-start"
            value={filter}
            onValueChange={(val) => {
              setFilter(val);
              onChange(val);
            }}
            intlConfig={{ locale: rest?.locale, currency: rest?.currencyCode }}
            allowNegativeValue={false}
            {...rest}
            id={name}
            style={{padding: "6px 12px"}}
          />
        </div>
      </>
    );
  }
);
export const InputFile = forwardRef(
  (
    { value, setValue, timeSpan, label, labelCss, name, isRequired, extensionsAccepted, selectMultipleFiles, ...rest },
    ref
  ) => {
    labelCss ??= "";
    timeSpan ??= 0;
    label ??= "";
    name ??= "";
    isRequired ??= "false";
    value ??= null;
    setValue ??= () => {};
    selectMultipleFiles ??= false;

    const [filter, setFilter] = useState(value);
    var classNameMdNone = "";
    var isRequiredClass = "";
    // if (isRequired === "true") {
    //   isRequiredClass = "text-danger";
    // } else {
    //   isRequiredClass = "d-none";
    // }
    var classNameMd = "";
    // if ((label || "") == "") {
    //   classNameMdNone = "float-start col-md-5 text-wrap d-none";
    //   classNameMd = "float-end col-md-12";
    // } else {
    //   classNameMdNone = "float-start col-md-5 text-wrap";
    //   classNameMd = "float-end col-md-7";
    // }

    const onChange = useAsyncDebounce((val) => {
      setValue(val ?? undefined);
    }, timeSpan || 0);
    useImperativeHandle(ref, () => ({
      fnReset: (data) => {
        setFilter(data);
        setValue(data || null);
      },
    }));

    useEffect(() => {
      if (value !== filter) {
        setFilter(value);
      }
    }, [value]);

    return (
      <>
        {/* <div className={classNameMdNone}>
          <BootstrapForm.Label>
            <div className={`d-flex justify-content-start  ${labelCss}`}>
              <div style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
                {label}
              </div>
              <div className={isRequiredClass}>＊</div>
            </div>
          </BootstrapForm.Label>{" "}
        </div> */}

        <div className={classNameMd}>
          <BootstrapForm.Control
            onChange={(e) => {
              if(selectMultipleFiles){
                setFilter(e.target.files);
                onChange(e.target.files);
              }else{
                setFilter(e.target.files[0]);
                onChange(e.target.files[0]);
              }
            }}
            type="file"
            accept={extensionsAccepted}
            files={filter ?? null == null ? [] : [filter]}
            {...rest}
            multiple={selectMultipleFiles}
          />
        </div>
      </>
    );
  }
);

export const FormInputDropdown = ({ name, setValue,...rest }) => {
  setValue ??= (_) => {};
  const {
    register,
    formState: { errors },
    control,
    trigger,
  } = useFormContext();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <BootstrapForm.Group
        {...register(name)}
        // error={errors[name] !== undefined}
        className={errors[name] !== undefined ? "error" : null}
        controlId={`control_${name}`}
      >
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputGroup className={`d-flex align-items-center`}>
              <InputDropdown
                setValue={(val) => {
                  onChange(val);
                  setValue(val);
                }}
                value={value ?? null}
                onBlur={async () => await trigger(name, { shouldFocus: false })}
                {...rest}
              />

              <div className="search-icon">
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div className={`${error !== undefined ? "" : "d-none"}`}>

                      <Tooltip
                        arrow
                        disableFocusListener
                        onClose={handleTooltipClose}
                        open={open}
                        title={
                          <ErrorMessage
                            className="marginLeft"
                            errors={errors}
                            name={name}
                          />
                        }
                      >
                        <i className="fa fa-exclamation-circle me-2" onClick={handleTooltipOpen} onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}></i>
                      </Tooltip>
                  </div>
                </ClickAwayListener>
              </div>
            </InputGroup>
          )}
          control={control}
          name={name}
        />
      </BootstrapForm.Group>
    </>
  );
};

export const FormInputSelect = (props) => {
  var Id = 'Select_'+props.name;

  const Style = {
    fontFamily: 'sans-serif',
    fontSize: '14px'
  }
  return (
    <>
    <div className="d-flex align-items-center input-group mb-1">
      <div className="col-75 float-start col-md-5 text-wrap">
        <label className="form-label" style={Style}>Leave Duration
          {
            props.isRequired && (
              <span className="text-danger">＊</span>
            )
          }
        </label>
      </div>
      <div className="col-75 float-end col-md-7 pt-1">
        <select className="form-control form-select" name={props.name} id={Id} onChange={props.onChange}>
          {
            Object.keys(props.optionArr).map((key) => (
                <option value={props.optionArr[key]}>{key}</option>
            ))
          }
        </select>
      </div>
    </div>
    </>
  );
};

const generateSingleOptions = (ddOpt) =>
ddOpt && ddOpt.map((option, index) => (
    <option key={index} value={option?.value ?? null}>
      {option?.text ?? ""}
    </option>
  ));
export const InputDropdown = forwardRef(
  (
    {
      timeSpan,
      name,
      label,
      value,
      setValue,
      ddOpt,
      isRequired,
      labelCss,
      changeEvent,
      className,
      ...rest
    },
    ref
  ) => {
    labelCss ??= "";
    className ??= "";
    className += " form-control";
    var isRequiredClass = "";
    if (isRequired === "true") {
      isRequiredClass = "text-danger";
    } else {
      isRequiredClass = "d-none";
    }

    const [filter, setFilter] = useState(value);
    var classNameMdNone = "";
    var classNameMd = "";
    if ((label || "") == "") {
      classNameMdNone = "col-25  float-start col-md-5  text-wrap d-none";
      classNameMd = "float-end col-md-12 col-75 ";
    } else {
      classNameMdNone = "col-75 float-start col-md-5 text-wrap";
      classNameMd = "col-75 float-end col-md-7 pt-1";
    }
    const onChange = useAsyncDebounce((val) => {
      setValue(val ?? undefined);
    }, timeSpan || 0);
    useImperativeHandle(ref, () => ({
      fnReset: (data) => {
        setFilter(data);
        setValue(data || "");
      },
    }));
    useEffect(() => {
      if (
        (filter ?? null) === null &&
        (ddOpt ?? []).length > 0 &&
        (ddOpt[0].value ?? "") !== ""
      ) {
        setTimeout(() => setValue(ddOpt[0].value ?? ""));
      }
    }, [ddOpt, filter]);
    useEffect(() => {
      if (value !== filter) {
        setFilter(value);
      }
    }, [value]);
    return (
      <>
        <div className={classNameMdNone}>
          {" "}
          <BootstrapForm.Label>
            {" "}
            <div className={`d-flex justify-content-center  ${labelCss}`}>
              <div style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
                {label}
              </div>
              <div className={isRequiredClass}>＊</div>
            </div>
          </BootstrapForm.Label>
        </div>
        <div className={classNameMd}>
          <BootstrapForm.Select
            className={className}
            onChange={(e) => {
              onChange(e.target.value ?? "");
              setFilter(e.target.value ?? "");
            }}
            value={filter ?? ""}
            {...rest}
          >
            {generateSingleOptions(ddOpt)}
          </BootstrapForm.Select>
        </div>
      </>
    );
  }
);

export const FormAutoCompleteDropdown = ({ name, setValue, freeText, autoSelectAttributeValue, ...rest }) => {
  setValue ??= (val) => {};
  freeText ??= false;
  autoSelectAttributeValue ??= false;
  const {
    register,
    formState: { errors },
    control,
    trigger,
  } = useFormContext();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <BootstrapForm.Group
        {...register(name)}
        className={errors[name] !== undefined ? "error" : null}
      >
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputGroup className={`d-flex align-items-center `}>
              <AutoCompleteDropdown
              
                setValue={(val) => {
                  onChange(val);
                  setValue(val);
                }}
                value={value}
                freeText={freeText}
                autoSelectAttributeValue={autoSelectAttributeValue}
                // onBlur={async () => await trigger(name, { shouldFocus: false })}
                {...rest}
              />

              <div className="search-icon">
                <div
                  className={`${errors[name] !== undefined ? "" : "d-none"}`}
                >
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div className={`${error !== undefined ? "" : "d-none"}`}>

                      <Tooltip
                        arrow
                        disableFocusListener
                        onClose={handleTooltipClose}
                        open={open}
                        title={
                          <ErrorMessage
                            className="marginLeft"
                            errors={errors}
                            name={name}
                          />
                        }
                      >
                        <i className="fa fa-exclamation-circle me-2" onClick={handleTooltipOpen} onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}></i>
                      </Tooltip>
                  </div>
                  </ClickAwayListener>
                </div>
              </div>
            </InputGroup>
          )}
          control={control}
          name={name}
        />
      </BootstrapForm.Group>
    </>
  );
};

export const AutoCompleteDropdown = ({
  labelCss,
  className,
  isRequired,
  data,
  optionText,
  label,
  name,
  value,
  setValue,
  freeText,
  autoSelectAttributeValue,
  ...rest
}) => {
  var { multiple } = { ...rest };
  multiple ??= false;
  data ??= [];
  label ??= "";
  name ??= "";
  optionText ??= "";
  setValue ??= (v) => {};
  value ??= multiple ? [] : null;
  isRequired ??= "";

  labelCss ??= "";
  className ??= "";
  className += " form-control";
  var isRequiredClass = "";
  if (isRequired === "true") {
    isRequiredClass = "text-danger";
  } else {
    isRequiredClass = "d-none";
  }

  var classNameMdNone = "";
  var classNameMd = "";
  if ((label || "") == "") {
    classNameMdNone = "col-25  float-start col-md-5  text-wrap d-none";
    classNameMd = "float-end col-md-12 col-75 ";
  } else {
    classNameMdNone = "col-75 float-start col-md-5 text-wrap";
    classNameMd = "col-75 float-end col-md-7 pt-1";
  }

  const isOptionEqualToValue = (option, value) => {
    // Check if they have a common key to compare, e.g., AgentId or UserId
    const optionKeys = Object.keys(option);
    const valueKeys = Object.keys(value);
  
    // Find a common unique key dynamically, for example, checking for AgentId or UserId
    const commonKey = optionKeys.find(key => valueKeys.includes(key) && option[key] === value[key]);
  
    return commonKey !== undefined; // Return true if common unique key is found
  };

  return (
    <>
      <div className={classNameMdNone}>
        {" "}
        <BootstrapForm.Label>
          {" "}
          <div className={`d-flex justify-content-center  ${labelCss}`}>
            <div style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
              {label}
            </div>
            <div className={isRequiredClass}>＊</div>
          </div>
        </BootstrapForm.Label>
      </div>
      <div className={classNameMd}>
        <Autocomplete
          sx={{
            display: "inline-block",
            width: "100%",
          }}
          id={`dd_${name}`}
          options={dataClone(data)}
          multiple={multiple}
          getOptionLabel={(option) => option[optionText] ?? option}
          isOptionEqualToValue={isOptionEqualToValue}
          onInputChange={(event, value, reason) => {
            // Input = userInput (MUI Autocomplete help)
            if(reason == "input"){
              setValue([value]);
            }
          }}
          onChange={(event, value, reason) => {
            // selectOption = userInput (MUI Autocomplete help)
            if(reason == "selectOption" || reason == "reset") {
              setValue([value]);
            }
          }}
          value={
            multiple ? value ?? [] : (value ?? []).length > 0 ? value[0] : null
          }
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <BootstrapForm.Control {...params.inputProps} />
            </div>
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option[optionText], inputValue, {
              insideWords: true,
            });
            const parts = parse(option[optionText], matches);

            return (
              <li {...props} key={JSON.stringify(option)}>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
          freeSolo={freeText}
          autoSelect={autoSelectAttributeValue}
        />
      </div>
    </>
  );
};

export const FormAutoCompleteDropdownCard = ({ name, setValue, freeText, autoSelectAttributeValue, ...rest }) => {
  setValue ??= (val) => {};
  freeText ??= false;
  autoSelectAttributeValue ??= false;
  const {
    register,
    formState: { errors },
    control,
    trigger,
  } = useFormContext();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <BootstrapForm.Group
        {...register(name)}
        className={errors[name] !== undefined ? "error" : null}
      >
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputGroup className={`d-flex align-items-center `}>
              <AutoCompleteDropdownCard
                setValue={(val) => {
                  onChange(val);
                  setValue(val);
                }}
                value={value}
                freeText={freeText}
                autoSelectAttributeValue={autoSelectAttributeValue}
                {...rest}
              />

              <div className="search-icon">
                <div
                  className={`${errors[name] !== undefined ? "" : "d-none"}`}
                >
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div className={`${error !== undefined ? "" : "d-none"}`}>

                      <Tooltip
                        arrow
                        disableFocusListener
                        onClose={handleTooltipClose}
                        open={open}
                        title={
                          <ErrorMessage
                            className="marginLeft"
                            errors={errors}
                            name={name}
                          />
                        }
                      >
                        <i className="fa fa-exclamation-circle me-2" onClick={handleTooltipOpen} onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}></i>
                      </Tooltip>
                  </div>
                  </ClickAwayListener>
                </div>
              </div>
            </InputGroup>
          )}
          control={control}
          name={name}
        />
      </BootstrapForm.Group>
    </>
  );
};

export const AutoCompleteDropdownCard = ({
  labelCss,
  className,
  isRequired,
  data,
  optionText,
  label,
  name,
  value,
  setValue,
  freeText,
  autoSelectAttributeValue,
  placeholder,
  ...rest
}) => {
  var { multiple } = { ...rest };
  multiple ??= false;
  data ??= [];
  label ??= "";
  name ??= "";
  optionText ??= "";
  setValue ??= (v) => {};
  value ??= multiple ? [] : null;
  isRequired ??= "";

  labelCss ??= "";
  className ??= "";
  className += " form-control";
  var isRequiredClass = isRequired === "true" ? "text-danger" : "d-none";

  var classNameMdNone = (label || "") === "" 
    ? "col-25 float-start col-md-5 text-wrap d-none" 
    : "col-75 float-start col-md-5 text-wrap";
  var classNameMd = (label || "") === "" 
    ? "float-end col-md-12 col-75" 
    : "col-75 float-end col-md-7 pt-1";

  // Conditionally render only when data and value are ready
  // if (!data.length) {
  //   return null; // Render nothing until both data and value are ready
  // }

  return (
    <>
      <div className={classNameMdNone}>
        <BootstrapForm.Label>
          <div className={`d-flex justify-content-center ${labelCss}`}>
            <div style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
              {label}
            </div>
            <div className={isRequiredClass}>＊</div>
          </div>
        </BootstrapForm.Label>
      </div>
      <div className={classNameMd}>
        <Autocomplete
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: "1px 8px",
            },
          }}
          id={`dd_${name}`}
          options={dataClone(data)}
          autoHighlight
          getOptionLabel={(option) => option[optionText] ?? option}
          onChange={(e, newValue) => setValue([newValue])}
          defaultValue={value}
          {...rest}
          renderOption={(props, option) => (
            <Box {...props}>
              <div className="d-flex">
                <div className="me-2">
                  {option.avatar ? (
                    <Avatar alt={option.heading.charAt(0)} src={option.avatar} />
                  ) : option.heading ? (
                    <Avatar alt="no-image">{option.heading.charAt(0)}</Avatar>
                  ) : null}
                </div>
                <div>
                  <div>{option.heading}</div>
                  <div>{option.description}</div>
                </div>
              </div>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              inputProps={{
                ...params.inputProps,
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  border: "none",
                },
              }}
            />
          )}
        />
      </div>
    </>
  );
};

