import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormInputDropdown, FormInputText } from '../Form';
import { WebService } from '../../Services/WebService';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from '../../Services/StandardConst';
import { ConfigurationShowYellowBar } from '../Dashboard/Company/CompanyDashboard';


const AddEditAssets = (props, ref) => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ Assets: {} });
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [AssestTypeName, setAssestTypeName] = useState([]);

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            fetchAssetTypes();
            if (id === 0) {
                const data = {
                    Assets: {},
                };
                setData(data);
            } else {
                setShow(false);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    const fetchEditValue = async (id) => {
        const data = {
            Assets: await WebService({
                endPoint: `CommonUtility/Edit/assets?AssetId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
        setData(data);
    }


    const fetchAssetTypes = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/assettype`,
            dispatch,
        });
        const list = data.map((r) => {
            return { text: r.AssetTypeName, value: r.AssetTypeId}
        })
        setAssestTypeName(list);
    }

    const handleClose = () => {
        setShow(false)
        setData({Assets : {}});
    };

    const schema = yup
        .object()
        .shape({
            AssetName: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            Status: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            AssetTypeId: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
        .required();

    const onSubmit = async (data) => {
        data.CompanyId = companyInfo.CompanyId;
        if (data.AssetId === undefined) {
            await WebService({
                endPoint: "CommonUtility/assets",
                body: data,
                dispatch,
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/assets?AssetId=${data.AssetId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
        }
        handleClose();
        props.callBackEvent();
    }

    console.log(data.Assets)
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data && data.Assets && data.Assets.AssetId || 0) === 0
                        ? "Add Asset Type"
                        : "Edit Asset Type"
                    }
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.Assets}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <FormInputText
                                label="Asset Name"
                                name="AssetName"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                name="Description"
                                label="Description"
                                as="textarea"
                                rows="2"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputDropdown
                                name="AssetTypeId"
                                ddOpt={AssestTypeName}
                                label="Asset Type Name"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Asset Number"
                                name="AssetNumber"
                                type="text"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Asset Tag Id"
                                name="AssetTagId"
                                type="text"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputDropdown
                                name="Status"
                                ddOpt={StandardConst.StatusDropdown}
                                label="Status"
                                isRequired="true"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAccessLevelSubmit" variant="outline-primary" type="submit">
                        {" "}
                        {(data && data.Assets && data.Assets.AssetId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default forwardRef(AddEditAssets);