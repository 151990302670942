import React, { useEffect, useRef } from "react";
import jsPDF from "jspdf";
import ReportTemplate from "./ReportTemplate";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import "./Payslip.css";
import { Form, FormInputDropdown, FormInputText } from "../Form";
import * as yup from "yup";
import { DateTime } from "luxon";
import { extendOwn, filter, map, reduce } from "underscore";
import {
  Paper,
  Alert,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  IconButton,
  Box,
} from "@mui/material";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { pink } from "@mui/material/colors";
import { Delete, Edit } from "@mui/icons-material";
import { ActionPermission } from "../PageInfo";
import Cookies from "universal-cookie";
import DisplayNumberFormatComponent from "../../Services/DisplayNumberFormatComponent";
import { StandardConst } from "../../Services/StandardConst";
import { WSErrorAlert, WSSuccessAlert } from "../../Services/WSAlert";
import { format } from "date-fns/esm";
import { NoRecordTemplate } from "../../Services/TableComponent";
import html2pdf from 'html2pdf.js';
// import notoFont from '../../fonts/NotoSans_Condensed-Regular.ttf'; 
import notoFont from '../../fonts/NotoSans_Condensed-Regular.ttf'
const { forwardRef, useState, useImperativeHandle } = React;
const ViewPaySlip = (prop, ref) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ Payslip: {} });
  const dispatch = useDispatch();

  const [permission] = useState({
    ManageAddPayment: ActionPermission("PaySlip - Add Payment"),
    ManageDeletePayment: ActionPermission("PaySlip - Delete Payment"),
  });
  const cookies = new Cookies();
  const Currency = (cookies.get("company") ?? {}).Symbol;
  const [isPublish, setIsPublish] = useState(false);
  const [payslipId, setPayslipId] = useState(null);
  useImperativeHandle(ref, () => ({
    openModal: async (id, isPublish) => {
      isPublish ??= false;
      setPayslipId(id);
      setIsPublish(isPublish);
      const data = await WebService({
        dispatch,
        endPoint: `Payslip/View/${id || 0}`,
      });
      if ((data?.FromDate ?? "") !== "") {
        data.PayMonth = DateTime.fromJSDate(new Date(data.FromDate)).toFormat(
          "MMM-yy"
        );
      }
      if ((data?.DOJ ?? "") !== "") {
        data.DOJ = DateTime.fromJSDate(new Date(data.DOJ)).toFormat(
          "dd-MMM-yy"
        );
      }
      setData(data);
      setShow(true);
    },
  }));
  let margin = 18; // narrow margin - 1.27 cm (36);
  const reportTemplateRef = useRef();
 

  const handleGeneratePdf = async () => {
    try {
      const reportTemplateDiv = document.getElementById("report-content");
  
      if (!reportTemplateDiv) {
        console.error("Report template not found");
        return;
      }
  
      const screenWidth = window.innerWidth;
  
      // Adjust the width and height based on screen size
      // if (screenWidth < 768) {
      //   reportTemplateDiv.style.width = "800px";
      //   reportTemplateDiv.style.height = "1000px";
      // }
  
      // Define custom settings for html2pdf.js
      const options = {
        margin: 1,
        filename: "payslip.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true }, // Handles external resources
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
  
      // Generate PDF
      const pdf = await html2pdf().from(reportTemplateDiv).set(options).toPdf().get("pdf");
  
      if (isPublish && payslipId !== null) {
        const PublishedPayslipPath = `${data.FullName}-${format(
          new Date(data.FromDate),
          "yyyy-MM-dd"
        )}-${format(new Date(data.ToDate), "yyyy-MM-dd")}-${format(
          new Date(),
          "yyyy-MM-dd hhmmss"
        )}.pdf`;
  
        const pdfFile = pdf.output();
        await WebService({
          dispatch,
          endPoint: `Payslip/${payslipId}`,
          method: "PUT",
          body: {
            StatusId: 7,
            pdfFile: pdfFile,
            PublishedPayslipPath,
          },
        });
  
        // Callback and alert
        prop.callBackEvent();
        handleClose();
        WSSuccessAlert("Success", "Payslip Successfully Published.");
      }
  
      // Reset styles if you modified them
      if (screenWidth < 768) {
        reportTemplateDiv.style.width = "";
        reportTemplateDiv.style.height = "";
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      WSErrorAlert("Error", "An error occurred while generating the PDF.");
    }
  };
  
  const Downlodpayslip = () => {
    const link = `${StandardConst.payslipPDF}/${data.PublishedPayslipPath}?download=true`;
  
    // Create an anchor element
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank'; // This opens the file in a new tab
    a.download = 'payslip.pdf'; // Optional: specify the filename for download
    
    // Append the anchor to the document body (required for some browsers)
    document.body.appendChild(a);
    
    // Trigger the download
    a.click();
    
    // Remove the anchor from the document after downloading
    document.body.removeChild(a);
  };
  
  
  
  const addPayment = async (dr) => {
    extendOwn(dr, { PayslipId: data.PayslipId });
    dr = await WebService({ dispatch, endPoint: "Payslip/Payment", body: dr });
    var paymentDetails = data?.PaymentDetails ?? [];
    paymentDetails.push(dr);
    setData({ ...data, PaymentDetails: paymentDetails });
    formRef.current.fnReset({});
  };
  const roundOf = (num, position) => {
    return (
      Math.round((num + Number.EPSILON) * Math.pow(10, position)) /
      Math.pow(10, 2)
    );
  };
  const totalDueAmt = () =>
    roundOf(
      (data?.NetSalary ?? 0) -
        (reduce(
          data?.PaymentDetails ?? [],
          (m, v) => m + parseFloat(v?.PaidAmount ?? "0"),
          0
        ) ?? 0),
      2
    );
  const requiredMessage = "Value is required";
  const schema = yup
    .object()
    .shape({
      PaymentTrasactionNo: yup.string().required(requiredMessage),
      PaymentDate: yup
        .date()
        .label("Payment Date")
        .typeError("Enter valid date")
        .required(requiredMessage)
        .when([], (_, passSchema) =>
          passSchema.min(DateTime.fromISO(data.ToDate).toFormat("yyyy-MM-dd"))
        ),
      PaidAmount: yup
        .number()
        .label("Paid Amount")
        .typeError(requiredMessage)
        .required(requiredMessage)
        .min(0.01)
        .when([], (_, passSchema) => passSchema.max(totalDueAmt())),
    })
    .required();

  const formRef = useRef();
  const MyDocument = (
    <div>
      <div ref={reportTemplateRef}>
        <ReportTemplate data={data} />
      </div>
    </div>
  );
  const fnRemove = async (obj) => {
    await WebService({
      dispatch,
      endPoint: `Payslip/Payment/${obj.EmployeePayslipPaymentId}`,
      method: "DELETE",
    });
    setData({
      ...data,
      PaymentDetails: filter(
        data?.PaymentDetails ?? [],
        (f) => f.EmployeePayslipPaymentId !== obj.EmployeePayslipPaymentId
      ),
    });
  };
  const boxCommonStyles = {
    "& > :not(style)": {
      m: 1,
    },
  };
  const commonStyles = {
    border: 1,
    borderBottom: 1,
    borderColor: "#A6A6A6",
    p: 2,
  };
  const displayDateFormat = (dt, format) => {
    if (dt === undefined || dt === null) return null;
    return DateTime.fromISO(dt).toFormat(format);
  };

  const Payment = (
    <>
      <Box
        sx={{
          ...boxCommonStyles,
        }}
      >
        <Paper
          square
          elevation={0}
          sx={{
            ...commonStyles,
            p: 0,
          }}
        >
          <Alert severity="info" sx={{}}>
            Payment Details
          </Alert>
          <div className="table-responsive p-0" style={{ overflow: "none" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>PaymentDate</TableCell>
                  <TableCell align="right">TrasactionNo</TableCell>
                  <TableCell align="right">Method</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  {permission.ManageDeletePayment && (
                    <TableCell align="right"> Action</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {map(data?.PaymentDetails ?? [], (m, i) => (
                  <TableRow>
                    <TableCell align="left">
                      {displayDateFormat(m.PaymentDate, "dd-MM-yyyy")}
                    </TableCell>
                    <TableCell align="right">{m.PaymentTrasactionNo}</TableCell>
                    <TableCell align="right">{m.PaymentMethod}</TableCell>
                    <TableCell align="right">
                      {" "}
                      <DisplayNumberFormatComponent Number={m.PaidAmount} Currency={data?.Symbol}/>
                    </TableCell>
                    {permission.ManageDeletePayment && (
                      <TableCell align="right">
                        {" "}
                        <IconButton
                          size="small"
                          sx={{ color: pink[500] }}
                          onClick={() => fnRemove(m)}
                          id={`btn_delete_${i}`}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>         
        </Paper>
      </Box>

      {permission.ManageAddPayment && totalDueAmt() > 0 && (
        <Box
          sx={{
            ...boxCommonStyles,
          }}
        >
          <Paper
            square
            elevation={0}
            sx={{
              ...commonStyles,
            }}
          >
            <Form
              defaultValues={{}}
              onSubmit={addPayment}
              validationSchema={schema}
              ref={formRef}
            >
              <Row>
                <Col className="col-md-6 col-12">
                  <FormInputText
                    name="PaymentDate"
                    type="date"
                    min={DateTime.fromISO(data.ToDate).toFormat("yyyy-MM-dd")}
                    label="Payment Date"
                  />
                </Col>
                <Col className="col-md-6 col-12">
                  {" "}
                  <FormInputText
                    label="Transaction No."
                    name="PaymentTrasactionNo"
                    type="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="col-md-6 col-12">
                  <FormInputDropdown
                    label="Method"
                    name="PaymentMethod"
                    ddOpt={[
                      { value: "NEFT", text: "NEFT" },
                      { value: "Cash", text: "Cash" },
                    ]}
                  />
                </Col>
                <Col className="col-md-6 col-12">
                  {" "}
                  <FormInputText
                    label="Amount"
                    type="number"
                    name="PaidAmount"
                  />
                </Col>
              </Row>
              <Row>
                <Col></Col>
                <Col className="text-end">
                  {" "}
                  <Button type="submit" variant="outline-primary">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Paper>
        </Box>
      )}
      <p className="text-center italic">
        *This is machine generated payslip and doesn't require signature
      </p>
    </>
  );
  
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal
        className="modal-fullscreen p-0"
        show={show}
        onHide={handleClose}
        size={"lg"}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pay Slip</Modal.Title>
        </Modal.Header>
          {(data.PublishedPayslipPath !== null || isPublish) ? (
            <>
              <Modal.Body>
                {MyDocument}
                {(data?.StatusId ?? 0) == 7 && Payment}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-danger" onClick={handleClose}>
                  Close
                </Button>
                {(isPublish) ? (
                  <Button onClick={handleGeneratePdf} variant="outline-primary">
                    <i className="fa fa-upload" aria-hidden="true"></i> Publish
                  </Button>
                ) : (
                  <Button onClick={Downlodpayslip} variant="outline-primary">
                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i> Download Payslip
                  </Button>
                )}
              </Modal.Footer>
            </>
          ) : (
            <NoRecordTemplate
              headerValue={"Published payslip not found."}
              imageUrl={StandardConst.imageNoRecordsFound}
            />
          )}
        
      </Modal>
    </>
  );
};

export default forwardRef(ViewPaySlip);