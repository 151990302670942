import DeleteIcon from "@mui/icons-material/Delete";
import React, {
  useEffect,
  useRef,
  forwardRef,
  useState,
  useImperativeHandle,
} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import {
  Form,
  FormInputText,
  InputText,
  FormAutoCompleteDropdown,
  FormAutoCompleteDropdownCard,
  InputCurrency,
} from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import "./Payslip.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  chain,
  each,
  extend,
  findWhere,
  map,
  mapObject,
  omit,
  reduce,
  where,
} from "underscore";
import { DateTime } from "luxon";
import { Box, IconButton, Paper } from "@mui/material";
import CompanyInfoWide from "../../Services/CompanyInfoWide";
import { StandardConst } from "../../Services/StandardConst";
import {
  dataClone,
  generateQueryStringByObject,
} from "../../Services/UtilityService";
import ActionButton from "../../Services/ActionButton";
import { WSSuccessAlert } from "../../Services/WSAlert";

const AddEditPayslip = (prop, ref) => {
  const [show, setShow] = useState(false);
  const [empList, setEmpList] = useState([]);
  const [ActiveEmpList,setActiveEmpList]=useState([]);
  const [componentList, setComponentList] = useState([]);
  const [payslipData, setPayslipData] = useState({});
  const [calculationDays, setCalculationDays] = useState({});
  const [total, setTotal] = useState({});
  const [EmployeeId,setEmployeeId]=useState('')
  const dispatch = useDispatch();
  const formRef = useRef();
  const otherComponentAddRef = useRef();
  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

  const fetchExistingPayslip = async (id = 0) => {
    var data = await WebService({
      dispatch,
      endPoint: `Payslip/Fetch/${id || 0}`,
      // endPoint: `CommonUtility/Edit/employeepayslip?PayslipId=${id || 0}`
    }).then((d) =>
      mapObject(d, (value, key) => {
      // mapObject(d[0], (value, key) => {
        if (key === "FromDate" || key === "ToDate")
          return DateTime.fromISO(value).toFormat("yyyy-MM-dd");
        return value;
      })
    );
    setEmployeeId(data.EmployeeId)

    data.EmployeeId = [empList?.find(item => item?.EmployeeId === data.EmployeeId)];
    setPayslipData(omit(data, ["GrossSalary", "TotalDeductions", "NetSalary"]));
    setTotal({
      totalEarning: data?.GrossSalary,
      totalDeduction: data?.TotalDeductions,
      netAmount: data?.NetSalary,
    });
  };
  const fetchUsers = async () => {
    const empDDOpt = await WebService({
      endPoint: "EmployeePackage/ActiveUsers",
      dispatch,
    }).then((result) =>
      map(result, (m) =>
        extend(m, {
          EmployeeId: m.EmployeeId,
          heading: m.FullName,  
            description: m.Designation, 
          avatar: `${StandardConst.apiBaseUrl}/uploads/${m.ProfileImage}`,
          status:m.StatusId
        })
      )
    );
    setEmpList(empDDOpt);
    setActiveEmpList(empDDOpt.filter(i=>i.status==StandardConst.Status.Approved))
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setShow(true);
      if(id > 0){
        setShow(false);
        await fetchUsers();
        await fetchExistingPayslip(id);
        setShow(true);
      }
    },
  }));
  const handleClose = () => {
    setShow(false);
    setTotal({});
    setPayslipData({});
  }
  const requiredMessage = "This is a required field";
  const ratingRangeErrorMessage = "Range between 1 to 5";
  const NumericvaluesError = "Please Insert Numeric values";
  const WorkingdayError = "Please insert days less then working days";

  const staticEmployeePayslipSchema = {
    TotalWorkingDays: yup
      .number()
      .typeError(NumericvaluesError)
      .min(1, requiredMessage)
      .max(31, requiredMessage)
      .required(requiredMessage),
    EmployeeId: yup
      .array()
      .of(
        yup.object().shape({
          heading: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
      )
      .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
      .min(1, StandardConst.ValidationMessages.RequiredFieldMsg),
    FromDate: yup
      .date()
      .typeError("Enter valid date")
      .required(requiredMessage)
      .max("9999-12-31", "Please select valid date"),
    // PaymentDate: yup
    //   .date()
    //   .typeError("Enter valid date")
    //   .required(requiredMessage),
    ToDate: yup
      .date()
      .typeError("Enter valid date")
      .required(requiredMessage)
      .max("9999-12-31", "Please select valid date"),

    IncludeKRAScoreInPayslip: yup.number()
    .nullable()
    .transform((value, originalValue) => originalValue === '' ? null : value)
    .min(1, ratingRangeErrorMessage)
    .max(5, ratingRangeErrorMessage),

    UnpaidAbsenceDays: yup
      .number()
      .typeError(NumericvaluesError)
      .nullable(true)
      .min(0, requiredMessage)
      .when("TotalWorkingDays", (days, passSchema) =>
        (days ?? 0) === 0 ? passSchema : passSchema.max(days, WorkingdayError)
      ),
  }
  const [schemaState, setSchemaState] = useState({});
  const [customFieldsData, setCustomFieldsData] = useState([]);
  const schema = yup
    .object()
    .shape(schemaState)
    .required();

    const fetchCustomfields = async () => {
      return await WebService({
        endPoint: `Payslip/fetchEmployeePayslipCustomFields?CountryId=${companyInfo?.CountryId}`,
        dispatch,
      }).then((res) => {
        res.map(item => {
            if(item.FieldIsRequired === StandardConst.YesNo.Yes) {
                if(item.FieldType === StandardConst.dataTypes.String){
                    staticEmployeePayslipSchema[item.FieldName] = yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg);
                }else if(item.FieldType === StandardConst.dataTypes.Date){
                    staticEmployeePayslipSchema[item.FieldName] = yup.date().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg);
                }else if(item.FieldType === StandardConst.dataTypes.Number || item.FieldType === StandardConst.dataTypes.Number){
                    staticEmployeePayslipSchema[item.FieldName] = yup.number().typeError(StandardConst.ValidationMessages.InvalidCharMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg);
                }
            }
        })
        setSchemaState(staticEmployeePayslipSchema);
        const sortedDataToAscendingOrder = res.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
        setCustomFieldsData(sortedDataToAscendingOrder);
        return res;
      });
    };

  useEffect(() => {
    if(companyInfo?.CountryId) fetchCustomfields();
  }, [companyInfo]);

  const onSubmit = async (data) => {
    let PayslipId = data.PayslipId;

    if(currencyData?.CurrencyId){
      data.CurrencyId = currencyData?.CurrencyId;
    }

    data.EmployeeId = data.EmployeeId[0].EmployeeId;
    const body = extend(omit(data, "PaymentDate", "PayslipId", "CreatedAt"), {
      GrossSalary: total.totalEarning,
      TotalDeductions: total.totalDeduction,
      NetSalary: total.netAmount,
      employeepayslipcomponents: chain(componentList ?? [])
        .map((m) => ({
          SalaryComponentId: m.SalaryComponentId,
          CalculatedAmount: m.ManualValue ?? m.CalculatedValue ?? m.Amount,
        }))
        .value(),
    });
    let opt = { endPoint: "Payslip", dispatch, body };
    let msg = "Payslip added successfully";
    if ((data?.PayslipId ?? 0) != 0) {
      opt.endPoint += `/${data.PayslipId}`;
      opt.method = "PUT";
      msg = "Payslip updated successfully";
    }
    const payslipdata = await WebService(opt);
    PayslipId = payslipdata.PayslipId;
    if(payslipdata){
      customFieldsData.map(item => item.FieldValue = data[item.FieldName]);
      await saveCustomFieldData(PayslipId);
      WSSuccessAlert("Success", msg);
    }
    handleClose();
    prop.callBackEvent();
  };

  const saveCustomFieldData = async (PayslipId) => {
    if((customFieldsData.length > 0) && (PayslipId !== null)){
        const AllCustomFieldsValueDelete = await WebService({
            endPoint: `CommonUtility/employeepayslipcustomfieldvalues?PayslipId=${PayslipId}`,
            method: "DELETE",
            dispatch,
        }).then((res) => true);
        
        if(AllCustomFieldsValueDelete){
            customFieldsData.map(async (item) => {
                item['PayslipId'] = PayslipId;
                await WebService({
                    endPoint: `CommonUtility/employeepayslipcustomfieldvalues`,
                    body: item,
                    method: 'POST',
                    dispatch,
                })
            })
        }
    }
  };

  const fnComponentFetch = async (date, empId) => {
    const data = await WebService({
      dispatch,
      endPoint: `Payslip/Component/${empId}/${date}?${generateQueryStringByObject({
        PayslipId: payslipData?.PayslipId??0
      })}`,
    }).then((d) =>
      (payslipData?.Details ?? []).length == 0
        ? d
        : map(d, (m) =>
            extend(m, {
              ManualValue: findWhere(payslipData?.Details ?? [], {
                SalaryComponentId: m.SalaryComponentId,
              })?.CalculatedAmount,
            })
          )
    );
    setComponentList(data);
    setPayslipData({ ...payslipData, Details: [] });
  };
  const roundOf = (num, position) => {
    return (
      Math.round((num + Number.EPSILON) * Math.pow(10, position)) /
      Math.pow(10, 2)
    );
  };
  useEffect(() => {
    if (
      (payslipData?.FromDate ?? null) != null &&
      (payslipData?.EmployeeId ?? null) != null
    ) {
      fnComponentFetch(payslipData?.FromDate, payslipData?.EmployeeId);
    } else {
      setComponentList([]);
    }
  }, [payslipData?.FromDate, payslipData?.EmployeeId]);
  useEffect(() => {
    if (
      (payslipData?.FromDate ?? null) != null &&
      (payslipData?.ToDate ?? null) != null
    ) {
      var i1 = DateTime.fromISO(payslipData?.FromDate),
        i2 = DateTime.fromISO(payslipData?.ToDate).plus({ days: 1 });
      var diff = i2.diff(i1, ["days", "hours"]).toObject();
      payslipData.TotalWorkingDays = diff.days;
      setPayslipData(payslipData);
      formRef.current.fnReset(payslipData);
    }
  }, [payslipData?.FromDate, payslipData?.ToDate]);
  useEffect(() => {
    if (
      (payslipData?.FromDate ?? null) != null &&
      (payslipData?.ToDate ?? null) != null &&
      (payslipData?.TotalWorkingDays ?? 0) != 0
    ) {
      const totalDays = DateTime.fromSQL(payslipData.FromDate).daysInMonth;
      const workingDays =
        (payslipData?.TotalWorkingDays ?? 0) -
        (payslipData?.UnpaidAbsenceDays ?? 0);
      setCalculationDays({ totalDays, workingDays });
    }
  }, [
    payslipData?.FromDate,
    payslipData?.ToDate,
    payslipData?.TotalWorkingDays,
    payslipData?.UnpaidAbsenceDays,
  ]);
  useEffect(() => {
    if (
      (calculationDays?.totalDays ?? 0) != 0 &&
      (calculationDays?.workingDays ?? -1) != -1 &&
      componentList.length > 0
    ) {
      var temp = JSON.parse(JSON.stringify(componentList));
      each(temp, (m) => {
        if ((m.Amount ?? 0) > 0) {
          m.CalculatedValue = roundOf(
            (m.Amount / calculationDays.totalDays) *
              calculationDays.workingDays,
            2
          );
        } else {
          m.CalculatedValue = 0;
        }
      });
      if (JSON.stringify(temp) != JSON.stringify(componentList)) {
        setComponentList(temp);
      }
    }
  }, [calculationDays, componentList]);
  useEffect(() => {
    totalValueCalculation();
  }, [componentList]);

  const totalValueCalculation = (dataList = componentList) => {
    var totalEarning = roundOf(
      reduce(
        where(dataList, { EarningOrDeductionType: "Earning" }),
        (m, v) =>
          m + parseFloat(v.ManualValue ?? v.CalculatedValue ?? v.Amount),
        0
      ),
      2
    );
    var totalDeduction = roundOf(
      reduce(
        where(componentList, { EarningOrDeductionType: "Deduction" }),
        (m, v) =>
          m + parseFloat(v.ManualValue ?? v.CalculatedValue ?? v.Amount),
        0
      ),
      2
    );
    var netAmount = roundOf(totalEarning - totalDeduction, 2);
    setTotal({ totalEarning, totalDeduction, netAmount });
  };
  const updateAmtByUserEnty = (value, toObject) => {
    setComponentList(
      map(componentList, (obj) => {
        if (obj.SalaryComponentId == toObject.SalaryComponentId) {
          if (value == "") value = null;
          else value = parseFloat(value);
          return { ...obj, ManualValue: value };
        } else {
          return obj;
        }
      })
    );
  };
  const commonStyles = {
    border: 1,
    borderBottom: 1,
    borderColor: "primary.main",
    p: 2,
  };

  const boxCommonStyles = {
    "& > :not(style)": {
      m: 1,
    },
  };
  const fromDateMinValue = () => {
    let minDate =
      findWhere(empList, {
        value: parseInt(payslipData?.EmployeeId??StandardConst.defaultIntValue.toString()),
      })?.minDate ?? null;
    if (minDate !== null) {
      minDate = new Date(minDate);
    }
    if ((payslipData?.ToDate ?? "") !== "") {
      var toDate = new Date(
        DateTime.fromSQL(payslipData.ToDate).startOf("month").toISO()
      );
      if (minDate === null || toDate > minDate) minDate = toDate;
    }
    if (minDate === null) return undefined;
    return DateTime.fromJSDate(minDate).toFormat("yyyy-MM-dd");
  };
  const fromDateMaxValue = () => {
    let maxDate =
      findWhere(empList, {
        value: parseInt(
          payslipData?.EmployeeId ?? StandardConst.defaultIntValue.toString()
        ),
      })?.maxDate ?? null;
    if (maxDate !== null) {
      maxDate = new Date(maxDate);
    }
    if ((payslipData?.ToDate ?? "") !== "") {
      const toDate = new Date(payslipData.ToDate);
      if (toDate < maxDate) maxDate = toDate;
    }
    if (maxDate === null) return undefined;
    return DateTime.fromJSDate(maxDate).toFormat("yyyy-MM-dd");
  };
  const toDateMaxValue = () => {
    let maxDate =
      findWhere(empList, {
        value: parseInt(
          payslipData?.EmployeeId ?? StandardConst.defaultIntValue.toString()
        ),
      })?.maxDate ?? null;
    if(maxDate!==null)maxDate=new Date(maxDate);
    if ((payslipData?.FromDate ?? "") !== "") {
      var s1 = new Date(
        DateTime.fromSQL(payslipData.FromDate).endOf("month").toISO()
      );
      maxDate ??= s1;
      if (s1 < maxDate) maxDate = s1;
    }
    if (maxDate === null) return undefined;
    return DateTime.fromJSDate(maxDate).toFormat("yyyy-MM-dd");
  };


  const [currencyData, setCurrencyData] = useState({})
  const fetchEmployeeCurrencyData = async (EmployeeId) => {
    console.log(EmployeeId)
    await WebService({
      endPoint : `Payslip/fetchEmployeeCurrencyForPayslip?EmployeeId=${EmployeeId}`,
      method : 'GET',
    }).then(res => res.length > 0 ? setCurrencyData(res[0]) : null);
  };

  useEffect(() => {
    if(EmployeeId){
      fetchEmployeeCurrencyData(EmployeeId);
    }
  },[EmployeeId]);
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter "
      centered
      show={show}
      onHide={handleClose}
      className="container-fluid"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {(payslipData.PayslipId || 0) === 0 ? "Add Payslip" : "Edit Payslip"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={payslipData}
        onSubmit={onSubmit}
        validationSchema={schema}
        ref={formRef}
      >
        <Modal.Body>
          <Box
            sx={{
              ...boxCommonStyles,
              display: "flex",
              alignItems: "center",
              display: {
                xs: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            <Paper
              square
              elevation={0}
              sx={{
                ...commonStyles,
              }}
            >
              <Row
                sx={{
                  alignItems: "center",
                }}
              >
                <CompanyInfoWide
                  section1={3}
                  section2={9}
                  isMobileVisible={true}
                />
              </Row>
            </Paper>
          </Box>
          <Box
            sx={{
              ...boxCommonStyles,
            }}
          >
            <Paper square elevation={0} sx={{ ...commonStyles }}>
              <div className="row">
                <div className="col-md-6">
                  <FormInputText
                    label="From Date"
                    name="FromDate"
                    type="date"
                    setValue={(v) =>
                      setPayslipData({ ...payslipData, FromDate: v })
                    }
                    max={fromDateMaxValue()}
                    min={fromDateMinValue()}
                    pattern="[0-8]{4}"
                    isRequired="true"
                  />
                </div>
                <div className="col-md-6">
                  <FormInputText
                    label="To Date"
                    name="ToDate"
                    isRequired="true"
                    type="date"
                    setValue={(v) =>
                      setPayslipData({ ...payslipData, ToDate: v })
                    }
                    min={
                      (payslipData?.FromDate ?? "") == ""
                        ? undefined
                        : payslipData.FromDate
                    }
                    max={toDateMaxValue()}
                    // min={fromDateMinValue()}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormAutoCompleteDropdownCard
                    name="EmployeeId"
                    data={ActiveEmpList}
                    label="Employee"
                    optionText="heading"
                    value={payslipData.EmployeeId !== undefined ? payslipData.EmployeeId[0] : ''}
                    isRequired="true"
                    setValue={(v) =>
                  {  setPayslipData({ ...payslipData, EmployeeId: v[0].EmployeeId });   
                  setEmployeeId(v[0].EmployeeId)                  
                  }

                    }
                  />
                </div>
                <div className="col-md-6">
                  <FormInputText
                    label="KRA Score"
                    name="IncludeKRAScoreInPayslip"
                    type="number"
                    min={1}
                    max={5}
                  />
                </div>
                {/* <div className="col-md-6">
              <FormInputText
                label="Payment Date"
                name="PaymentDate"
                type="date"
                setValue={(v) =>
                  setPayslipData({ ...payslipData, PaymentDate: v })
                }
              />
            </div> */}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormInputText
                    label="Total Working Days"
                    name="TotalWorkingDays"
                    type="number"
                    isRequired="true"
                    setValue={(v) =>
                      setPayslipData({ ...payslipData, TotalWorkingDays: v })
                    }
                  />
                </div>
                <div className="col-md-6">
                  <FormInputText
                    label="Not Working Days"
                    name="UnpaidAbsenceDays"
                    type="number"
                    isRequired="true"
                    setValue={(v) =>
                      setPayslipData({ ...payslipData, UnpaidAbsenceDays: v })
                    }
                    min={0}
                  />
                </div>
              </div>

              <div className="row">
                {(customFieldsData.length > 0) && (
                  customFieldsData.map(CustomField => {
                    const FieldType = CustomField.FieldType;
                    if(FieldType === StandardConst.dataTypes.Date){
                        return <div className="col-md-6" key={CustomField?.EmployeePayslipCustomFieldId}>
                            <FormInputText
                                label={CustomField.FieldLabel}
                                name={CustomField.FieldName}
                                type="date"
                                portalId="root"
                                isRequired={CustomField.FieldIsRequired === StandardConst.YesNo.Yes ? "true" : "false"}
                                setValue={(val) => CustomField['FieldValue'] = val}
                            />
                            
                        </div>
                    }else if(FieldType === StandardConst.dataTypes.Currency){
                      return <div className="col-md-6" key={CustomField?.EmployeePayslipCustomFieldId}>
                            <InputCurrency
                                locale={currencyData?.LocaleCode}
                                currencyCode={currencyData?.CurrencyCode}
                                label={CustomField.FieldLabel}
                                name={CustomField.FieldName}
                                isRequired={CustomField.FieldIsRequired === StandardConst.YesNo.Yes ? "true" : "false"}
                                setValue={(val) => CustomField['FieldValue'] = val}
                            />
                        </div>
                    }else{
                        return <div className="col-md-6" key={CustomField?.EmployeePayslipCustomFieldId}>
                            <FormInputText
                                label={CustomField.FieldLabel}
                                name={CustomField.FieldName}
                                type={FieldType}
                                isRequired={CustomField.FieldIsRequired === StandardConst.YesNo.Yes ? "true" : "false"}
                                setValue={(val) => CustomField['FieldValue'] = val}
                            />
                        </div>
                    }
                  })
                )}
              </div>
            </Paper>
          </Box>

          <Box
            sx={{
              ...boxCommonStyles,
            }}
          >
            <Paper
              square
              elevation={0}
              sx={{
                ...commonStyles,
                borderColor: "error.main",
              }}
            >
              <Row>
                <Col md={6} className="text-center square border-end">
                  <ActionButton
                    onClick={async () =>
                      await otherComponentAddRef.current.openModal(
                        chain(componentList ?? [])
                          .map((m) => m.SalaryComponentId)
                          .value()
                      )
                    }
                    IconName="Assign"
                  ></ActionButton>

                  <strong> Earnings </strong>
                </Col>
                <Col md={6} className="text-center bg-color:red">
                  <strong>Deduction</strong>
                </Col>
              </Row>
            </Paper>
            <Paper
              square
              elevation={0}
              sx={{
                ...commonStyles,
              }}
            >
              <Row>
                <Col>
                  {map(
                    where(componentList, { EarningOrDeductionType: "Earning" }),
                    (m) => (
                      <>
                        <Row className="square border-end">
                          <Col>
                            <span>{m.EarningOrDeductionName} </span>
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() =>
                                setComponentList((s) =>
                                  chain(s ?? [])
                                    .filter(
                                      (f) =>
                                        f.SalaryComponentId !==
                                        m.SalaryComponentId
                                    )
                                    .value()
                                )
                              }
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </Col>
                          <Col>

                            <InputCurrency
                              locale={currencyData?.LocaleCode}
                              currencyCode={currencyData?.CurrencyCode}
                              predefaultValue={true}
                              className="text-end"
                              value={
                                m.ManualValue ?? m.CalculatedValue ?? m.Amount
                              }
                              setValue={(v) => updateAmtByUserEnty(v, m)}
                              id={`txt_earning_from_${m.EarningOrDeductionName.replace(
                                " ",
                                "_"
                              )}`}
                            />
                          </Col>
                        </Row>
                      </>
                    )
                  )}
                </Col>
                <Col>
                  {map(
                    where(componentList, {
                      EarningOrDeductionType: "Deduction",
                    }),
                    (m) => (
                      <>
                        <Row>
                          <Col>
                            <span>{m.EarningOrDeductionName} </span>
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() =>
                                setComponentList((s) =>
                                  chain(s ?? [])
                                    .filter(
                                      (f) =>
                                        f.SalaryComponentId !==
                                        m.SalaryComponentId
                                    )
                                    .value()
                                )
                              }
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </Col>
                          <Col>
                            <InputCurrency
                              locale={currencyData?.LocaleCode}
                              currencyCode={currencyData?.CurrencyCode}
                              predefaultValue={true}
                              className="text-end"
                              value={
                                m.ManualValue ?? m.CalculatedValue ?? m.Amount
                              }
                              setValue={(v) => updateAmtByUserEnty(v, m)}
                              id={`txt_deduction_by_${m.EarningOrDeductionName.replace(
                                " ",
                                "_"
                              )}`}
                            />
                          </Col>
                        </Row>
                      </>
                    )
                  )}
                </Col>
              </Row>
            </Paper>
          </Box>

          <Box
            sx={{
              ...boxCommonStyles,
            }}
          >
            <Paper
              square
              elevation={0}
              sx={{
                ...commonStyles,
              }}
            >
              <Row>
                <Col>
                  <Row>
                    <div>
                      <Col>
                        <InputCurrency
                          locale={currencyData?.LocaleCode}
                          currencyCode={currencyData?.CurrencyCode}
                          predefaultValue={true}
                          className="text-end"
                          label="Gross Salary"
                          name="GrossSalary"
                          value={total.totalEarning}
                        />
                      </Col>
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <InputCurrency
                        locale={currencyData?.LocaleCode}
                        currencyCode={currencyData?.CurrencyCode}
                        className="text-end"
                        label="Net Salary"
                        name="NetSalary"
                        value={total.netAmount}
                        predefaultValue={true}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <div>
                      <Col>
                        <InputCurrency
                          locale={currencyData?.LocaleCode}
                          currencyCode={currencyData?.CurrencyCode}
                          className="text-end"
                          label="Total Deduction"
                          name="TotalDeductions"
                          value={total.totalDeduction}
                          predefaultValue={true}
                        />
                      </Col>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Paper>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            className="me-4"
            onClick={handleClose}
            id="closeButton"
          >
            Close
          </Button>
          <Button variant="outline-primary" type="submit" id="submitButton">
            {(payslipData.PayslipId || 0) === 0 ? "Submit" : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Form>
      <OtherComponentAdd
        ref={otherComponentAddRef}
        callBackEvent={(dt) =>
          setComponentList((s) => [
            ...s,
            chain(dt ?? {})
              .extend({
                Amount: dt.Amt,
                ManualValue: dt.Amt,
              })
              .omit("Amt")
              .value(),
          ])
        }
      />
    </Modal>
  );
};

export const OtherComponentAdd = forwardRef(({ callBackEvent }, ref) => {
  callBackEvent ??= (_) => {};
  const formRef = useRef();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [state, setState] = useState({});
  const handleClose = () => setShow(false);
  const onSubmit = async (dt) =>
    await Promise.all([
      callBackEvent({
        ...chain(dt.Component ?? [{}])
          .first()
          .value(),
        Amt: dt.Value ?? 0,
      }),
      handleClose(),
    ]);
  useImperativeHandle(ref, () => ({
    openModal: async (addedComponentList) => {
      setState((s) => ({ ...s, added: addedComponentList }));
      setShow(true);
      await formRef.current.fnReset();
    },
  }));
  useEffect(() => {
    Promise.all([
      WebService({
        dispatch,
        endPoint: `CommonUtility/staticsalarycomponents?${generateQueryStringByObject(
          {
            select: `SalaryComponentsId SalaryComponentId, EarningOrDeductionName, EarningOrDeductionType, PreTaxORPostTax`,
          }
        )}`,
        requiredLoader: false,
      }).then((r) => setState((s) => ({ ...s, masterList: r }))),
    ]);
  }, []);
  const validationSchema = yup
    .object()
    .shape({
      Value: yup
        .number()
        .typeError(StandardConst.requiredMessage)
        .label("Amount")
        .required(StandardConst.requiredMessage)
        .positive(),
      Component: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string(),
            label: yup.string(),
          })
        )
        .min(1, StandardConst.requiredMessage),
    })
    .required();
  return (
    <Modal
      size="sm"
      aria-labelledby="contained-modal-title-vcenter "
      centered
      show={show}
      onHide={handleClose}
      className="container-fluid"
    >
      <Form
        // defaultValues={payslipData}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        ref={formRef}
      >
        <Modal.Body>
          <FormAutoCompleteDropdown
            name="Component"
            data={chain(dataClone(state?.masterList ?? []))
              .filter(
                (f) =>
                  chain(state.added ?? [])
                    .filter((f1) => f1 === f.SalaryComponentId)
                    .value().length < 1
              )
              .sortBy((s) => s.EarningOrDeductionName)
              .value()}
            optionText="EarningOrDeductionName"
            label="Component"
          />
          <FormInputText label="Value" name="Value" type="number" />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            className="me-4"
            onClick={handleClose}
            id="closeButton"
          >
            Close
          </Button>
          <Button variant="outline-primary" type="submit" id="AddButton">
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});
export default forwardRef(AddEditPayslip);
