import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditDesignation from "./AddEditDesignation";
import { useDispatch, useSelector } from "react-redux";
import "./Static.css";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { sortBy } from "underscore";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import { useLocation } from "react-router-dom";

const Designation = () => {
  const { state } = useLocation();
  const ref = useRef();
  const refSnackbar = useRef();
  const [appData, setAppData] = React.useState({
    searchPlaceHolder: "Search Designation",
    addMenuTitle: "Add Designation",
  });
  PageInfo({ pageTitle: "Designation" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  // const [permission] = useState({
  //   ManageAdd: true, //ActionPermission("Designation - Add"),
  //   ManageEdit: true, //ActionPermission("Designation - Edit"),
  //   ManageDelete: true, // ActionPermission("Designation - Delete"),
  // });
  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: true, //ActionPermission("Designation - Add"),
      ManageEdit: true, //ActionPermission("Designation - Edit"),
      ManageDelete: true, // ActionPermission("Designation - Delete"),
    });
  }, [permissionList]);
  const fetchDesignation = async () => {
    const data = await WebService({
      endPoint: "CommonUtility/staticdesignation",
      dispatch,
    });
    setRecords(sortBy(data, (s) => s.Designation));
    dispatch(ConfigurationflowStatusUpdate());
  };
  const onDelete = async (DesignationId) => {
    await WebService({
      endPoint: `CommonUtility/staticdesignation?DesignationId=${DesignationId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchDesignation();
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchDesignation();
    }
    renderAfterCalled.current = true;
  }, []);
  const columns = [
    {
      Text: "Designation Name",
      Value: "Designation",
    },
    {
      Text: "Action",
      cssClass: "text-center w-2",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <>
          {dr.IsReserved != 1 && (
            <span className="d-flex col-auto">
              <ActionButton
                onClick={() => fnEdit(dr.DesignationId)}
                disabled={!permission.ManageEdit}
                IconName="Edit"
                id="btnDesignationEdit"
              />

              <ActionButton
                onClick={(e) =>
                  ref.current.confirmAlert(
                    "Delete", //Confirm button text
                    "Are You Sure", // Text if Alert
                    "Do you want to delete " + MasterPageName, // Message of Alert
                    dr.DesignationId // Endpoint to hit for delete
                  )
                }
                disabled={!permission.ManageDelete}
                IconName="Delete"
                id="btnDesignationDelete"
              />
            </span>
          )}
        </>
      ),
    },
  ];
  const [bData, setBData] = React.useState([
    {
      title: "Employee",
      hrefLink: "#",
    },
    {
      title: "Designation",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Designation";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Designation";

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        BackBtnReturnPath={(state?.SourcePageName === StandardConst.ReturnPagePaths.ActionCenter) ? StandardConst.ReturnPagePaths.ActionCenter : ""}
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.DesignationheaderValueNoResults}
            subHeaderValue={StandardConst.DesignationSubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button id="NoRecordFoundAddDesignation" variant="outline-primary" onClick={() => fnEdit(0)}>
                    Add {AddButtonText}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditDesignation
        callBackEvent={() => fetchDesignation()}
        ref={addEditModalRef}
      ></AddEditDesignation>
    </>
  );
};
export default Designation;
