import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../../Services/StandardConst";
import { WebService } from "../../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import MuiButton from "@mui/material/Button";
import ReactTimeAgo from "react-time-ago";
import {
  Avatar,
  Badge,
  Divider,
  IconButton,
  Menu,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { findWhere, map, where } from "underscore";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { useNavigate } from "react-router-dom";
import { PageInfo } from "../../PageInfo";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CloseIcon from '@mui/icons-material/Close';

const JobApplicantDashboard = () => {
  PageInfo({ pageTitle: "Dashboard" });

  const loggedUser = useSelector((s) => s.auth.LoggedCustomer ?? 0);

  const [jobApplicantId, setJobApplicantId] = useState(null);

  const fetchJobApplicantIdFromUserId = async (userId) => {
    await WebService({
        endPoint: `CommonUtility/Edit/jobapplicants?UserId=${userId}`,
        method: 'GET',
    }).then(res => res.length > 0 ? setJobApplicantId(res[0].JobApplicantId) : null)
  }
  useEffect(() => {
    if (loggedUser !== 0) Promise.all([fetchJobApplicantIdFromUserId(loggedUser)]);
  }, [loggedUser]);

  return (
    <>
      <div className="container">
        {/* This is welcome bar */}
        {/* <div className="card card-cd mb-3 mt-3">
          <div className="card-body p-0 p-2">
            <div className="d-flex flex-column flex-lg-row flex-sm-row">
              <ProfileSummary className="col-md-8 col-sm-9" />
              <div className="vr my-2 col-md-1 col-sm-1 d-flex justify-content-end d-none d-md-inline"></div>
              <NotificationIcon className="col-md-3 col-sm-3 d-flex justify-content-end pe-3" />
            </div>
          </div>
        </div> */}

        <div className="row ">
            <div className="col-md-6">
                <JobsInfo JobApplicantId={jobApplicantId} />
            </div>
            <div className="col-md-6">
                <ProfileCard JobApplicantId={jobApplicantId} />
            </div>
        </div>
      </div>
    </>
  );
};

const JobsInfo = ({ JobApplicantId, ...rest }) => {
    const loggedUser = useSelector((s) => s.auth.LoggedCustomer ?? 0);
    const dispatch = useDispatch();
    const [state, setState] = useState({});
    const fetchJobsInfoData = async () => {
      await WebService({
        dispatch,
        endPoint: `Recruitment/fetchJobsInfo?JobApplicantId=${JobApplicantId}`,
      }).then((r) => setState(r.length > 0 ? r[0] : {}));
    };
    useEffect(() => {
      if (JobApplicantId !== null) Promise.all([fetchJobsInfoData()]);
    }, [JobApplicantId]);

    return (
        <div {...rest}>
            <div className="">
                <div className="row m-0">
                    {Object.keys(state).map((key, index) => (
                        <div className="col-md-6" style={{paddingLeft: "0px", paddingBottom: "12px"}}>
                            <div className="card p-3 m-0 d-flex justify-content-center align-items-center" style={{backgroundColor: `${StandardConst.ColorPalette[index]}`}}>
                                <p className="h1 m-0">{state[key]}</p>
                                <p className="h5 m-0" style={{color : "#fff"}}><b>{key.replace(/([A-Z])/g, ' $1').trim()}</b></p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export const ProfileSummary = ({ ...rest }) => {
    const loggedUser = useSelector((s) => s.auth.LoggedCustomer ?? 0);
    const UserType = useSelector((s) => s.auth?.initialState?.UserType);
        const Roles = useSelector((s) => s.auth.AssignRole ?? {});    
    const dispatch = useDispatch();
    const [state, setState] = useState({});
    const fetchProfile = async () => {
      await WebService({
        dispatch,
        endPoint: `CommonUtility/users?select=FullName, ProfileImage, "Job Applicant" Designation&where=UserId eq ${loggedUser}`,
      //   endPoint: `CommonUtility/employees?select=FullName, ProfileImage, fn_Designation(DesignationId) Designation&where=EmployeeId eq ${loggedUser}`,
      }).then((r) => setState(r.length > 0 ? r[0] : {}));
    };
    useEffect(() => {
      if (loggedUser !== 0) Promise.all([fetchProfile()]);
    }, [loggedUser]);
    return (
      <div {...rest}>
        <div className="d-flex flex-column flex-lg-row flex-sm-row justify-content-center align-items-center ms-2">
          <Avatar
            alt={state.FullName}
            src={`${StandardConst.uploadImages}${
              state.ProfileImage ?? "user_placeholder.jpg"
            }`}
            sx={{ width: 40, height: 40 }}
          />
          <div className="row flex-fill">
            <div className="col-sm-12 ms-2 d-flex flex-column">
              <div>
                <span className="d-none d-md-inline h6">Welcome,</span>
                <span className="h6"> {state.FullName}</span>
              </div>
              <span className="text-muted font-size-13">{state.Designation}</span>
            </div>
          </div>
        </div>
      </div>
    );
};

export const ProfileCard = ({ JobApplicantId, ...rest }) => {
    const loggedUser = useSelector((s) => s.auth.LoggedCustomer ?? 0);
    const dispatch = useDispatch();
    const [state, setState] = useState({});
    const [applicantSkill, setApplicantSkill] = useState([]);
    const navigate = useNavigate();
    const fetchProfile = async () => {
      await WebService({
        dispatch,
        endPoint: `CommonUtility/users?select=FullName, ProfileImage,Address, "Job Applicant" Designation&where=UserId eq ${loggedUser}`,
      }).then((r) => setState(r.length > 0 ? r[0] : {}));
    };

    const fetchSkills = async () => {
        await WebService({
          dispatch,
          endPoint: `Recruitment/fetchSkillForLoggedInApplicant?JobApplicantId=${JobApplicantId}`,
        }).then((r) => setApplicantSkill(r.length > 0 ? r : []));
      };

    useEffect(() => {
      if (loggedUser !== 0) Promise.all([fetchProfile()]);
    }, [loggedUser]);

    useEffect(() => {
        if (JobApplicantId !== null) Promise.all([fetchSkills()]);
      }, [JobApplicantId]);

    return (
      <div {...rest}>
        <div className="w-full">
            <div className="card">
                <div className="p-4 flex-auto">
                    <div className="row ">
                        <div className="col-md-8">
                            <div className="update-profile d-flex">
                                <Avatar
                                    alt={state.FullName}
                                    src={`${StandardConst.uploadImages}${
                                    state.ProfileImage ?? "user_placeholder.jpg"
                                    }`}
                                    sx={{ width: 100, height: 100 }}
                                />
                                <div className="p-3">
                                    <h3 className="mb-0">{state.FullName}</h3>
                                    <span className="text-primary block xl:mb-4 mb-1 text-sm">{state.Designation}</span><br />
                                    <span className="text-sm text-body-text" style={{color: "#888888"}}><i className="fa fa-map-marker" ></i>&nbsp; {state.Address}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-4" >
                            <Button
                                className="px-3 rounded-lg"
                                onClick={() => navigate("/Common/CustomerProfile")}
                            >
                                Update Profile
                            </Button>
                        </div>
                    </div>

                    
                    <div className="row mt-6 items-center">
                        <h4 className="text-xl mb-0 mt-1">Skills</h4>

                        <List component="nav">
                            {applicantSkill.map(skill => (
                                <ListItem>
                                    <Typography variant="body1" color="textPrimary">
                                        <span style={{ color: 'blue', marginRight: '8px' }}>&#8226;</span> {skill.SkillName}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
};

export const NotificationIcon = ({ ...rest }) => {
  const [state, setState] = useState([]);
  var DateT = "2023-05-06 10:05:06";
  const dispatch = useDispatch();
  const fetchNotReadedNotification = () => {
    WebService({ dispatch, endPoint: `Notification?Skip=0&Take=500` }).then(
      (r) => {
        setState(
          map(r, (m) => {
            if ((m.Route ?? "") !== "") {
              m.Route = `/${m.Route}?search=${window.btoa(m.UniqueRefNo)}`;
            }
            return m;
          })
        );
      }
    );
  };
  useEffect(() => {
    Promise.all([fetchNotReadedNotification()]);
  }, []);
  const navigate = useNavigate();
  const fnRead = async (dr) => {
    const process = [];
    process.push(
      WebService({
        dispatch,
        endPoint: `Notification/MarkRead/${dr.NotificationId}`,
        method: "PUT",
        requiredLoader: false,
      })
    );
    if ((dr.Route ?? "") !== "") {
      process.push(navigate(dr.Route));
    }
    await Promise.all(process);
    await fetchNotReadedNotification();
  };
  //#region Notofication List

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //#endregion

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 8,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(5),
      marginRight: theme.spacing(4),
      minWidth: 180,
      maxWidth: 650,
      maxHeight: 500,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 15,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));
  const StyledChip = styled(MuiButton)(({ theme }) => ({
    borderRadius: 4,
    fontSize: 11,
    "& .MuiChip-root": {},
  }));
  return (
    <div {...rest}>
      {/* <div>
        <Button
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Dashboard
        </Button>
      </div> */}
      <div className=" d-flex justify-content-center align-items-center text-center">
        <IconButton aria-label="cart" onClick={handleClick}>
          <StyledBadge
            badgeContent={JSON.stringify(
              where(state, { IsReaded: false }).length
            )}
            color="secondary"
          >
            <CircleNotificationsIcon
              sx={{ color: StandardConst.Colors.primary, fontSize: 30 }}
            />
          </StyledBadge>
        </IconButton>

        <StyledMenu
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {map(state, (m, key) => (
              <List
                key={key}
                sx={{
                  width: "100%",
                  maxWidth: 800,
                  className: "d-flex flex-row pb-0 pt-0",
                  bgcolor: "background.paper",
                }}
              >
                <ListItem
                  onClick={handleClose}
                  disableRipple
                  style={{
                    width: "100%",
                    "margin-top": "-19px",
                    "margin-bottom": "-10px",
                  }}
                  className="d-flex flex-row "
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={m?.TriggerName}
                      src={`${StandardConst.uploadImages}${
                        m?.ProfileImage ?? ""
                      }`}
                    ></Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    onClick={() => fnRead(m)}
                    className="font-size-13 text-wrap  w-100"
                  >
                    {!m.IsReaded && (
                      <strong className="font-size-13 text-wrap">
                        {m.Message}
                      </strong>
                    )}
                    {m.IsReaded && (
                      <>
                        <span className="font-size-13">{m.Message}</span>
                      </>
                    )}
                    <br></br>
                    <ReactTimeAgo
                      date={m.CreatedOn}
                      locale="en-US"
                      className="font-size-13 text-wrap"
                    />
                  </ListItemText>
                  <MuiButton
                    color="primary"
                    size="small"
                    onClick={() => fnRead(m)}
                  >
                    {findWhere(StandardConst.TriggerMaster, {
                      Trigger: m.TriggerId,
                    })?.Action ?? "Read"}
                  </MuiButton>
                </ListItem>
                <Divider variant="inset" component="li" />
              </List>
              /* <MenuItem
                onClick={handleClose}
                disableRipple
                style={{ width: "100%" }}
              >
                <div className="row w-100 p-0">
                  <div className="col-md-1  d-flex flex-column justify-content-center">
                    <Avatar
                      className="ps-2"
                      alt={m?.TriggerName}
                      src={`${StandardConst.uploadImages}${
                        m?.ProfileImage ?? ""
                      }`}
                      sx={{
                        width: 40,
                        height: 40,
                      }}
                    />
                  </div>
                  <div className="col-md-11 p-1 ps-4">
                    <span
                      className="font-size-13 text-wrap"
                      onClick={() => fnRead(m)}
                    >
                      {!m.IsReaded && <strong>{m.Message}</strong>}
                      {m.IsReaded && <>{m.Message}</>}
                    </span>
                    <div>
                      <div className="font-size-13 text-muted float-start">
                        <ReactTimeAgo date={m.CreatedOn} locale="en-US" />
                      </div>
                      <div className="font-size-13 float-end">
                        <MuiButton
                          color="primary"
                          size="small"
                          onClick={() => fnRead(m)}
                        >
                          {findWhere(StandardConst.TriggerMaster, {
                            Trigger: m.TriggerId,
                          })?.Action ?? "Read"}
                        </MuiButton>
                      </div>
                    </div>
                  </div>
                </div>
              </MenuItem>

              <Divider sx={{ mx: 2, backgroundColor: "#9a9a9a" }} /> */
          ))}
          {/* <List
            sx={{
              width: "100%",
              maxWidth: 560,
              bgcolor: "background.paper",
            }}
          >
            <ListItem>
              {state.length > 0 && (
                <ListItem>
                  <ListItemText>
                    <MuiButton
                      className="font-size-13 text-center"
                      color="primary"
                      size="small"
                      onClick={() => navigate(`/Common/Notification`)}
                    >
                      All Notification(s)
                    </MuiButton>
                  </ListItemText>
                </ListItem>
              )}
            </ListItem>
          </List> */}
          {state.length > 0 && (
            <div className="d-flex justify-content-center align-items-center my-1 ">
              <MuiButton
                color="primary"
                size="small"
                onClick={() => navigate(`/Common/Notification`)}
              >
                All Notification(s)
              </MuiButton>
            </div>
          )}
        </StyledMenu>
      </div>
    </div>
  );
};

export const SystemMaintenanceShowYellowBar = () => {

  const [remainingItemsCount, setRemainingItemsCount] = useState(0);
  const dispatch = useDispatch();
  const [AllSystemNotifications, setAllSystemNotifications] = useState([]);

  const fetchNotReadedNotification = async () => {
    const data = await WebService({
      dispatch,
      endPoint: `Notification?Skip=0&Take=500`
    }).then(r => r.filter(item => item.IsReaded === false && item.TriggerId === null ));
    setRemainingItemsCount(data.length);
    setAllSystemNotifications(data.slice(0,5));
  };
  useEffect(() => {
    fetchNotReadedNotification();
  }, [remainingItemsCount]);

  const CloseSystemMaintenanceYellowBar = (removedIndex) => {
    const filteredAllSystemNotifications = AllSystemNotifications.filter((r, index) => index !== removedIndex ?? []);
    setAllSystemNotifications(filteredAllSystemNotifications);
  }

  const fnRead = async (dr) => {
    await WebService({
      dispatch,
      endPoint: `Notification/MarkRead/${dr.NotificationId}`,
      method: "PUT",
      requiredLoader: false,
    })
    await fetchNotReadedNotification();
  };

  return (
    <>
      {(remainingItemsCount > 0) && (
        AllSystemNotifications.map((res, index) => (
          <React.Fragment key={index}>
            <div className="card card-cd mb-3 mt-3 bg-warning">
              <div className="card-body p-0 p-2">
                <div className="d-flex flex-lg-row flex-sm-row">
                  <div className="col-11">
                    <span>
                    {res.Message}
                    </span>
                    <ReactTimeAgo
                      date={res.CreatedOn}
                      locale="en-US"
                      className="font-size-13 text-wrap ms-2"
                    />
                    <MuiButton
                    color="primary"
                    size="small"
                    onClick={() => fnRead(res)}
                  >
                    Read
                  </MuiButton>
                  </div>
                  <div className="col-1 pe-auto text-end">
                    <CloseIcon className="cursor-pointer" onClick={() => CloseSystemMaintenanceYellowBar(index)} />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))
      )}
    </>
  )
};

export default JobApplicantDashboard;
