import Button from "react-bootstrap/Button";
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";

function JobHeader() {
      const Roles = useSelector((s) => s.auth.AssignRole ?? {});
      const isJobApplicant = Roles?.some(
        (element) => element === StandardConst.SystemRole.JobApplicant
      );
      console.log(isJobApplicant)
      console.log(Roles)
    const navigate=useNavigate()
  return (
    <div className="container">
    <nav className="navbar navbar-expand-lg  py-2  bg-white  rounded-bottom">
                  <div class="container-fluid">
                      <Link class="navbar-brand">
                          <img src="https://assets.wiseofficeday.com/wod/project_images/WiseOfficeDayLogo.png" class="img-responsive rounded mx-auto d-block" alt="logo" style={{width: "200px", height:"auto"}} />
                      </Link>
                      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                          <span class="navbar-toggler-icon"></span>
                      </button>
                      <div class="collapse navbar-collapse" id="navbarSupportedContent">
                          <ul class="navbar-nav me-auto mb-lg-0">
                              <li class="nav-item">
                                  <Link class="nav-link disabled">Company reviews</Link>
                              </li>
                              <li class="nav-item">
                                  <Link class="nav-link disabled">Salary guide</Link>
                              </li>
                          </ul>
                          <div class="d-flex flex-row gap-2" role="search">
{                       !isJobApplicant &&   <Button className='btn btn-primary' onClick={()=>navigate('/NonCompanyUserLogin')}>Applicant Sign In </Button>
}                          <Button className='btn btn-primary' onClick={()=>window.location.href = 'https://hrms.wiseofficeday.com'}>Employers / Post Job </Button>
                          </div>
                      </div>
                  </div>
              </nav>
   </div>
  )
}

export default JobHeader
