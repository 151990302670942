import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditRoles from "./AddEditRoles";
import { useDispatch, useSelector } from "react-redux";
import "./Static.css";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { sortBy } from "underscore";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import { useNavigate } from "react-router-dom";
import { Chip } from "@mui/material";
const Roles = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  const [appData, setAppData] = React.useState({
    searchPlaceHolder: "Search Role",
    addMenuTitle: "Add Role",
  });
  PageInfo({ pageTitle: "Manage Roles and Permissions" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  var nevigate = useNavigate();
  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  const LoginRoles = useSelector((s) => s.auth.AssignRole ?? {});
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Roles - Add"),
      ManageEdit: ActionPermission("Roles - Edit"),
      ManageDelete: ActionPermission("Roles - Delete"),
    });
  }, [permissionList]);
  const fetchRole = async () => {
    const data = await WebService({
      endPoint: "Roles/getRoleDetails/staticroles",
      dispatch,
    });
    setRecords(data);
    dispatch(ConfigurationflowStatusUpdate());
  };
  const onDelete = async (RoleId) => {
    await WebService({
      endPoint: `CommonUtility/staticroles?RoleId=${RoleId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchRole();
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchRole();
    }
    renderAfterCalled.current = true;
  }, []);
  const columns = [
    {
      Text: "Role Name",
      Value: "RoleName",
      render: (dr) => {
        return <><span>{dr.RoleName}</span>  &nbsp;&nbsp; 
        {(dr.CompanyId === null) && (
          <Chip 
              label="Reserved"
              sx={{ color: "white", padding: "0px"}}
              color= "primary"
              size="small"
          />
        )}
        </>
      }
    },
    {
      Text:"Invite Link",
      Value:"InviteLink"
    },
    {
      Text:'User  Type',
      Value:'UserType', 
    }
  
  ];
    
    if(LoginRoles[0] === StandardConst.SystemRole.SuperAdmin){
      columns.push(
      {
        Text: "Company Name",
        Value: "CompanyName",
        render: (dr) => dr.CompanyId !== null ? dr.CompanyName : "All Companies"
      });
    }

    columns.push(
    {
      Text: "Action",
      cssClass: "text-center td-width-100",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <>
          {(dr.CompanyId !== null || LoginRoles[0] === StandardConst.SystemRole.SuperAdmin) && (
            <>
              <ActionButton
                onClick={() => fnEdit(dr.RoleId)}
                disabled={!permission.ManageEdit}
                IconName="Edit"
                id="btnRoleEdit"
                IconTooltip="Edit"
              />

              <ActionButton
                onClick={(e) => deleteConfirmation(dr)}
                disabled={!permission.ManageDelete}
                IconName="Delete"
                IconTooltip="Delete"
                id="btnRoleDelete"
              />
              <ActionButton
                onClick={async () => {nevigate(`/ManagePermissions/${dr.RoleId}`);}}
                // disabled={!permission.ManageEdit}
                IconName="Permission"
                IconTooltip="Manage Permissions"
                id="btnRoleEdit"
              />
            </>
          )}
        </>
      ),
    });

    const deleteConfirmation = async (dr) => {
        var msg = "Do you want to delete " + MasterPageName;
        await WebService({
          // FetchAssociatedEmployeeAndCompanyName
          endPoint: `Roles/FetchAssociatedData?RoleId=${dr.RoleId}`,
          method: 'GET',
          dispatch,
        }).then((res) => {
          if(res.length > 0) {
            msg = `This Role is associated with ${
              res.map((company) => `${company.CompanyName} (${company.EmployeeList.join(', ')})`).join(', ')
            }`;
          }
        });

        ref.current.confirmAlert(
          "Delete", //Confirm button text
          "Are You Sure", // Text if Alert
          msg, // Message of Alert
          dr.RoleId // Endpoint to hit for delete
        )
    };

  const [bData, setBData] = React.useState([
    {
      title: "Security Management",
      hrefLink: "#",
    },
    {
      title: "Manage Roles and Permissions",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Manage Roles and Permissions";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Role";

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.ManageRolesheaderValueNoResults}
            subHeaderValue={StandardConst.ManageRolesSubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button id="NoRecordFoundAddRole" variant="outline-primary" onClick={() => fnEdit(0)}>
                    Add {AddButtonText}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditRoles
        callBackEvent={() => fetchRole()}
        ref={addEditModalRef}
      ></AddEditRoles>
    </>
  );
};
export default Roles;
