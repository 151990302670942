import * as React from "react";
import { styled, alpha, ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import AddCircle from "@mui/icons-material/AddCircle";
import { Button, colors } from "@mui/material";
import { grey } from "@mui/material/colors";
import theme from "./WsMuiTheme";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import _ from "underscore";
import { ExcelExport } from "./ImportExport";
import { useDispatch } from "react-redux";
import { StandardConst } from "./StandardConst";
import {
  AutoCompleteDropdownCard,
  InputDropdown,
  InputText,
} from "../Component/Form";
import { DateRange } from "../Component/Dashboard/Company/CompanyDashboard";
import { format } from "date-fns";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: grey,
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  height: "30px",
  minheight: "30px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchDefault = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: grey,
  },
  backgroundColor: grey[300],

  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    fontSize: ".8rem",
    width: "100%",
    border: "none",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const AppbarComponent = ({
  title,
  isSearchRequired,
  isAddButtonRequired,
  setSearchContent,
  searchContent,
  onAddEvent,
  Exception,
  hideDefaultAddLabel,
  exportData,
  columns,
  excelExportFileName,
  isExcelExportButtonVisible,
  filterData,
  setFilterValues,
  filterValues,
  ExcluedFieldFromColumns,
  ExcelExportFieldNames,
  callBackEvent,
}) => {
  callBackEvent ??= false;
  isExcelExportButtonVisible ??= false;
  ExcluedFieldFromColumns ??= [];
  ExcelExportFieldNames ??= [];
  filterData ??= [];
  filterValues ??= {};
  title ??= "";
  hideDefaultAddLabel ??= false;
  isSearchRequired ??= false;
  isAddButtonRequired ??= false;
  setSearchContent ??= (v) => {};
  searchContent ??= "";
  var inputBase = (
    <StyledInputBase
      placeholder={`Search ${title}`}
      id={`Search_${title}`}
      inputProps={{ "aria-label": "search" }}
      onChange={(e) => setSearchContent(e.target.value)}
      value={searchContent}
    />
  );

  const dispatch = useDispatch();

  // ExcluedFieldFromColumns, ExcelExportFieldNames ye hum us case me use krte hai jaha pr humne kuchh field jo directly available nahi the unko render ki madad se display kraya hai lekin excel me hume data se directly values ko lena hai
  // -- example - START --
  // ExcluedFieldFromColumns={["From", "Message"]}
  // ExcelExportFieldNames={[
  //   {ExcelColumnHeading: "From", DataColumnName: "CreatedBy"},
  //   {ExcelColumnHeading: "Message", DataColumnName: "Message"},
  //   {ExcelColumnHeading: "Notification Creation Date", DataColumnName: "CreatedOn", DataType: "DateTime"},
  // ]}
  // -- example - END --
  const ExportToExcel = () => {
    var columnsToBeExported = columns.filter(
      (f) =>
        (f.NotUseInExport ?? false) === false &&
        !StandardConst.ExcludeFieldsFromExcelExport.includes(f.Text)
    );

    // Which files are exclued from excel export # hum ye field ko data me se le rahe hai is liye columns me se in fields ko exclued kiya hai
    if (ExcluedFieldFromColumns.length > 0) {
      columnsToBeExported = columnsToBeExported.filter(
        (item) => !ExcluedFieldFromColumns.includes(item.Text)
      );
    }

    var dataOfColumnsToBeExported = _.map(exportData, (m) => {
      var object = {};

      // Additional columns and data inside the Excel file are created
      _.each(ExcelExportFieldNames, (oneObj) => {
        if (
          oneObj.DataType !== undefined &&
          oneObj.DataType === StandardConst.dataTypes.DateTime
        ) {
          object[oneObj.ExcelColumnHeading] =
            m[oneObj.DataColumnName] !== null && m[oneObj.DataColumnName] !== ""
              ? format(new Date(m[oneObj.DataColumnName]), "dd-MM-yyyy HH:mm a")
              : "";
        } else if (
          oneObj.DataType !== undefined &&
          oneObj.DataType === StandardConst.dataTypes.Date
        ) {
          object[oneObj.ExcelColumnHeading] =
            m[oneObj.DataColumnName] !== null && m[oneObj.DataColumnName] !== ""
              ? format(new Date(m[oneObj.DataColumnName]), "dd-MM-yyyy")
              : "";
        } else {
          object[oneObj.ExcelColumnHeading] =
            m[oneObj.DataColumnName] !== undefined &&
            m[oneObj.DataColumnName] !== null
              ? m[oneObj.DataColumnName]
              : "";
        }
      });

      _.each(columnsToBeExported, (singleColumn) => {
        // ye code achha nahi laga isko dubara dekhkr sahi krna hai (aisa sir ne kaha hai)
        if (
          singleColumn.Text === "Working Days" &&
          Array.isArray(m.workingDays)
        ) {
          object[singleColumn.Text] = m.workingDays
            .map((Val) => `${Val.Day}`)
            .join(",");
        } else {
          if (singleColumn.Value !== undefined && singleColumn.Value !== null) {
            if (Array.isArray(singleColumn.Value)) {
              // [M,T,W,Th,F,Sa]
              object[singleColumn.Text] = singleColumn.Value.join("");
            } else {
              object[singleColumn.Text] =
                m[singleColumn.Value ?? singleColumn.Text];
            }
          } else if (singleColumn.render) {
            if (singleColumn.render(m).props.Number) {
              object[singleColumn.Text] = singleColumn.render(m).props.Number;
            } else {
              object[singleColumn.Text] = Array.isArray(
                singleColumn.render(m).props.children
              )
                ? singleColumn.render(m).props.children.join("")
                : singleColumn.render(m).props.children;
            }
          }
        }

        // object[singleColumn.Text] = singleColumn.render ?
        // (Array.isArray(singleColumn.render(m).props.children)) ? singleColumn.render(m).props.children.join('') : singleColumn.render(m).props.children
        // : m[singleColumn.Value ?? singleColumn.Text]
      });

      return object;
    });
    excelExportFileName = excelExportFileName
      ? excelExportFileName + "-" + Date.now().toString()
      : Date.now().toString();
    ExcelExport(dataOfColumnsToBeExported, excelExportFileName, dispatch);
  };

  const [appBarFilterState, setAppBarFilterState] = React.useState({});
  const handleChangeFilters = (val, filedName) => {
    setAppBarFilterState((prev) => ({
      ...prev,
      [filedName]: val,
    }));
  };

  React.useEffect(() => {
    if (callBackEvent) {
      callBackEvent(appBarFilterState);
    }
  }, [appBarFilterState]);

  return (
    <>
      {(isSearchRequired ||
        isAddButtonRequired ||
        isExcelExportButtonVisible) && (
        <Box sx={{ p: 0 }}>
          <ThemeProvider theme={theme}>
            <AppBar
              style={{ minHeight: "40px", height: "40px",padding:0 }}
              position="static"
              elevation={0}
              color={`${
                isAddButtonRequired ||
                isExcelExportButtonVisible ||
                (onAddEvent || null) !== null
                  ? "primary"
                  : ""
              }`}
            >
              <Toolbar
                className="ms-0 ps-0"
                style={{ minHeight: "40px", height: "40px" }}
              >
                {isSearchRequired &&
                  (isAddButtonRequired ||
                    isExcelExportButtonVisible ||
                    (onAddEvent || null) !== null) && (
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon sx={{ fontSize: 20 }} />
                      </SearchIconWrapper>
                      {inputBase}
                    </Search>
                  )}
                {isSearchRequired &&
                  !(
                    isAddButtonRequired ||
                    isExcelExportButtonVisible ||
                    (onAddEvent || null) !== null
                  ) && (
                    <SearchDefault>
                      <SearchIconWrapper>
                        <SearchIcon sx={{ fontSize: 20 }} />
                      </SearchIconWrapper>
                      {inputBase}
                    </SearchDefault>
                  )}

                {filterData.length === 0 && <Box sx={{ flexGrow: 1 }} />}

                {filterData.length > 0 && (
                  <Box sx={{ flexGrow: 1 }}>
                    <Row className="d-flex   justify-content-center align-items-center">
                      {filterData.map((item) => {
                        if (item.FilterType === "INPUT") {
                          return (
                            <Col
                              key={item.FieldName}
                              className="d-flex justify-content-center align-items-center mt-1 col-auto"
                            >
                              <InputText
                                className="text-white border-0"
                                style={{
                                  backgroundColor: "rgba(255, 255, 255, 0.15)",
                                  height: "30px",
                                  borderRadius: "5px",
                                }}
                                placeholder={item.FilterPlaceholder}
                                name={item.FieldName}
                                setValue={(val) =>
                                  handleChangeFilters(val, item.FieldName)
                                }
                                value={appBarFilterState[item.FieldName] ?? ""}
                              />
                            </Col>
                          );
                        } else if (item.FilterType === "DATE") {
                          return (
                            <Col
                              key={item.FieldName}
                              className="d-flex justify-content-center align-items-center col-auto"
                            >
                              <DateRange
                                DefaultDate={
                                  item?.DefaultDate !== undefined
                                    ? item?.DefaultDate
                                    : ""
                                }
                                // ShowDefaultDate={true}
                                ResetDateRange={
                                  Object.keys(appBarFilterState).length === 0
                                }
                                type="day"
                                ButtonColor="inherit"
                                setDateRange={({ StartDate, EndDate }) => {
                                  if (StartDate) {
                                    handleChangeFilters(
                                      format(new Date(StartDate), "yyyy-MM-dd"),
                                      item.FieldName
                                    );
                                  }
                                }}
                              />
                            </Col>
                          );
                        } else if (item.FilterType === "AUTOCOMPLETE") {
                          return (
                            <Col
                              key={item.FieldName}
                              className="white-autocomplete"
                            >
                              <AutoCompleteDropdownCard
                                data={item.FilterData}
                                optionText={item?.OptionText}
                                labelCss="text-light"
                                setValue={(val) =>
                                  handleChangeFilters(val, item.FieldName)
                                }
                                // value={item.FilterData[0]}
                                value={{
                                  EmployeeId: 0,
                                  heading: "All Person",
                                }}
                              />
                            </Col>
                          );
                        } else {
                          return (
                            <Col
                              key={item.FieldName}
                              className="d-flex justify-content-center align-items-center col-auto"
                            >
                              <InputDropdown
                                className="border-0"
                                style={{
                                  backgroundColor: "rgba(255, 255, 255, 0.15)",
                                  height: "30px",
                                  borderRadius: "5px",
                                }}
                                name={item.FieldName}
                                setValue={(val) =>
                                  handleChangeFilters(val, item.FieldName)
                                }
                                ddOpt={[
                                  {
                                    text: item.DefaultAllValuesText ?? "All",
                                    value: "",
                                  },
                                ].concat(item.FilterData)}
                                value={appBarFilterState[item.FieldName] ?? ""}
                              />
                            </Col>
                          );
                        }
                      })}
                      <Col className="d-flex col-auto">
                        <Button
                          className="text-white"
                          onClick={() => setAppBarFilterState({})}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </Box>
                )}

                {!Exception && (
                  <>
                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                      {isExcelExportButtonVisible && (
                        <Row className="p-1 m-0">
                          <Col className="d-flex col-auto">
                            <div className="d-flex justify-content-md-end justify-content-sm-end">
                              <Button
                                variant="outline-success"
                                onClick={() => ExportToExcel()}
                              >
                                <i
                                  className="fa fa-file-excel-o me-2"
                                  aria-hidden="true"
                                ></i>{" "}
                                Export
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Box>

                    {isAddButtonRequired && (
                      <>
                        <Box sx={{ display: { xs: "none", md: "flex" } }}>
                          <Button
                            color="inherit"
                            id={`Add_${title}`}
                            onClick={() => {
                              if ((onAddEvent || null) !== null) {
                                onAddEvent();
                              }
                            }}
                          >
                            {" "}
                            <div className=" d-flex align-items-center">
                              <i className="fa fa-plus-circle me-2"></i>{" "}
                              {`${hideDefaultAddLabel ? "" : "Add"} ${title}`}
                            </div>
                          </Button>
                        </Box>
                        <Box sx={{ display: { xs: "flex", md: "none" } }}>
                          <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-haspopup="true"
                            onClick={() => {
                              if ((onAddEvent || null) !== null) {
                                onAddEvent();
                              }
                            }}
                            color="inherit"
                          >
                            <AddCircle />
                          </IconButton>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Toolbar>
            </AppBar>
          </ThemeProvider>
        </Box>
      )}
    </>
  );
};
export default AppbarComponent;
