import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { WebService } from "../../../Services/WebService";
import { Form, FormInputText } from "../../Form";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { WSErrorAlert, WSSuccessAlert } from "../../../Services/WSAlert";
const apiUrl = "CommonUtility/statickra";
const { forwardRef, useState, useImperativeHandle } = React;

const InviteUser = (prop, ref) => {
  const cookies = new Cookies();
  const [companyInfo, setCompanyInfo] = useState();
  useEffect(() => {
    setCompanyInfo(cookies.get("company") ?? {});
  }, []);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState();
  const [role, setRole] = useState();
  const [callId, setCallId] = useState();
  const [rolesModalTitle, setRolesModalTitle] = useState('');

  const SuccessAlert = (Title, Message) => {
  Swal.fire(Title, Message, "success");
  };

  useImperativeHandle(ref, () => ({
    openModal: async (id, roleId, rolesModalTitle) => {
      rolesModalTitle ??= null;
      setRolesModalTitle(rolesModalTitle);
      setData(id);
      setRole(roleId);
      setCallId(id);
      setShow(true);
    },
  }));
  const handleClose = () => setShow(false);
  const requiredMessage = "This is a required field";
  const schema = yup
    .object()
    .shape({
      To: yup.string().email().required(requiredMessage),
    })
    .required();
  const onSubmitInviteUser = async (data) => {

    data.Role = role;
   let apiresult= await WebService({
      endPoint: `User/InviteUser`,
      method: "POST",
      body: data,
      dispatch,
    }).then((r) => {
      WSSuccessAlert("User Invitation", `'${data.To}' Successfully Invited`)
    }).catch((err)=>{
      WSErrorAlert('',`${err}`)
    })
    handleClose();
    prop.callBackEvent();
  };

  const onSubmitDomainConfig = async (data) => {
    await WebService({
      dispatch,
      endPoint: `CompanyProfile/DomainConfiguration`,
      method: "PATCH",
      body: data,
    });
    SuccessAlert(
      "Domain configuration",
      `Domain configuration Instructions have been sent to your email id ${data.To}`
    );
    handleClose();
  };

  {
    switch (data) {
      case 1:
        return (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {(rolesModalTitle !== null) ? rolesModalTitle : "Invite"}
              </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmitInviteUser} validationSchema={schema}>
              <Modal.Body className="p-4">
                <h6>Enter Work Email ID to Invite</h6>
                <FormInputText
                  name="To"
                  type="text"
                  isRequired="true"
                  placeholder="Enter email"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-primary"
                  id="btnKRASubmit"
                  type="submit"
                >
                  {(data || 0) === 0 ? "Invite Now" : "Invite Now"}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        );
      case 3:
        return (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Domain Configuration</Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmitDomainConfig} validationSchema={schema}>
              <Modal.Body className="p-4">
                <h6>
                  Enter your Work Email ID to receive your Organization Domain
                  Configurations
                </h6>
                <FormInputText
                  name="To"
                  type="text"
                  isRequired="true"
                  placeholder="Enter email"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-primary"
                  id="btnKRASubmit"
                  type="submit"
                >
                  {(data || 0) === 3 ? "Email Config" : "Submit"}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        );
      default:
        return null;
    }
  }
};

export default forwardRef(InviteUser);
