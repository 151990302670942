import React, { forwardRef, useImperativeHandle, useState } from "react";
import Swal from "sweetalert2";

const AlertComponent = forwardRef(({ confirmEvent }, ref) => {
  confirmEvent ??= () => {};
  const [data, setData] = useState();

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "mx-1 btn btn-outline-primary",
      cancelButton: "mx-1 btn btn-outline-danger",
    },
    buttonsStyling: false,
  });

  useImperativeHandle(ref, () => ({
    confirmAlert: (confirmButtonText, title, text, data) => {
      confirmButtonText ??= "Ok";
      title ??= "Are you sure?";
      text ??= "Want to delete";
      swalWithBootstrapButtons
        .fire({
          title: title,
          text: text,
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: confirmButtonText,
        })
        .then((result) => {
          if (result.isConfirmed) {
            confirmEvent(data);
          }
        });
        setTimeout(() => {
          const confirmButton = document.querySelector('.btn-outline-primary');
          const cancelButton = document.querySelector('.btn-outline-danger');
          if (confirmButton) confirmButton.id = 'confirmButtonId';
          if (cancelButton) cancelButton.id = 'cancelButtonId';
        }, 0);
      setData(data);
    },
  }));

  return <></>;
});

export default AlertComponent;
