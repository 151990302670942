import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import './ProfileSearch.css'
import { useLocation, useNavigate } from "react-router-dom";
import { WebService } from "../../Services/WebService";
import { ActionPermission, PageInfo } from "../PageInfo";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import {
  Box,
  Grid,
  Chip,
  CardHeader,
  IconButton,
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Pagination,
  backdropClasses,
} from "@mui/material";
import Topbar from "../../Services/TopbarComponent";
import { StandardConst } from "../../Services/StandardConst";
import { Form, FormInputText, InputDropdown } from "../Form";
import Button from "react-bootstrap/Button";
import { NoRecordTemplate } from "../../Services/TableComponent";
import ActionButton from "../../Services/ActionButton";
import { WSSuccessAlert } from "../../Services/WSAlert";
import BootstrapModal from "react-bootstrap/Modal";
import { format } from "date-fns";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AppbarComponent from "../../Services/AppbarComponent";
import { ExcelExport } from "../../Services/ImportExport";
import _ from "underscore";

const ProfileSearch = () => {
  PageInfo({ pageTitle: "Applicants List" });

  const refSendRejectionMailModal = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SelectedJobId = searchParams?.get("JobId") ?? null;

  // ye original data ko store krti hai
  const [allApplicantsData, setAllApplicantsData] = useState([]);
  // ye state filter state hai jo ki JobApplicant ke time me filter se or first time data get krne ki liye set hoti hai
  const [filterapplicantsData, setFilterApplicantsData] = useState([]);

  const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
  const [permission, SetPermission] = useState({});
  const [jobList, setJobList] = useState([]);
  const [filterJob, setFilterJob] = useState(
    SelectedJobId !== null ? SelectedJobId : "All"
  );
  const [filterStatus, setFilterStatus] = useState("All");
  const [showStatusMenu, setShowStatusMenu] = useState(null);
  const [page, setPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [BasicApplicantFilter, setBasicApplicantFilter] = useState({
    Skills: "",
    Position: "",
    Location: "",
  });

  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Manage Job Posting - Add"),
      ManageEdit: ActionPermission("Manage Job Posting - Edit"),
      ManageDelete: ActionPermission("Manage Job Posting - Delete"),
    });
  }, [permissionList]);

  const fetchAllJobApplicants = async () => {
    console.log("Function called");
    const limit = 10;

    try {
      const response = await WebService({
        endPoint: `ProfileSearch/fetchAllJobApplicants?page=${page}&limit=${limit}`,
        method: "post",
        body: BasicApplicantFilter,
        dispatch,
      });

      setTotalPages(response.pagination.totalPages);
      setAllApplicantsData(response.data);
      setFilterApplicantsData(response.data);
    } catch (error) {
      console.error("Error fetching job applicants:", error);
    }
  };

  // Combined `useEffect` to handle both `page` and `BasicApplicantFilter`
  useEffect(() => {
    fetchAllJobApplicants();
  }, [page, BasicApplicantFilter]);

  const onBasicSearchSubmit = (data) => {
    const { Skills = "", Position = "", Location = "",YearOfExperience="" } = data;

    // Update both states in a single batch
    setBasicApplicantFilter((prevFilter) => ({
      ...prevFilter,
      Skills,
      Position,
      Location,
      YearOfExperience,
    }));

    setPage(1); // Triggers the `useEffect`
  };

  console.log(BasicApplicantFilter);
  const columns = [
    {
      Text: "FullName",
      Value: "FullName",
    },
    {
      Text: "Position",
      Value: "Position",
    },
    {
      Text: "Location",
      Value: "Location",
    },
    {
      Text: "Phone",
      Value: "Phone",
    },
    {
      Text: "Email",
      Value: "Email",
    },

    {
      Text: "ApplicantSkills",
      Value: "ApplicantSkills",
    },
    {
      Text: "YearOfExperience",
      Value: "YearOfExperience",
    },
    {
      Text: "ExpectedCTC",
      Value: "ExpectedCTC",
    },
    {
      Text: "Age",
      Value: "Age",
    },

    {
      Text: "Address",
      Value: "Address",
    },
    {
      Text: "Description",
      Value: "Description",
    },
  ];

  const handleToggleMenu = (JobApplicationId) => {
    if (showStatusMenu === JobApplicationId) {
      setShowStatusMenu(null);
    } else {
      setShowStatusMenu(JobApplicationId);
    }
  };

  const updateApplicationStatus = async (JobApplicationId, updatedStatus) => {
    await WebService({
      endPoint: `CommonUtility/jobapplications?JobApplicationId=${JobApplicationId}`,
      body: { StatusId: updatedStatus },
      method: "PUT",
      dispatch,
    }).then((res) => {
      setShowStatusMenu(null);
      fetchAllJobApplicants();
      WSSuccessAlert("Success", "Status successfully Updated");
    });

    if (updatedStatus === StandardConst.Status.Reject) {
      openModalSendRejectionMail(JobApplicationId);
    }
  };
  const [bData] = useState([
    {
      title: "Recruitment",
      hrefLink: "#",
    },
    {
      title: "Profile Search",
      hrefLink: "#",
    },
  ]);

  const openModalSendRejectionMail = async (JobApplicationId) =>
    refSendRejectionMailModal.current.openModal(JobApplicationId);

  const ExportToExcel = () => {
    var columnsToBeExported = columns.filter(
      (f) =>
        (f.NotUseInExport ?? false) === false &&
        !StandardConst.ExcludeFieldsFromExcelExport.includes(f.Text)
    );

    // Which files are exclued from excel export # hum ye field ko data me se le rahe hai is liye columns me se in fields ko exclued kiya hai
    // if (ExcluedFieldFromColumns.length > 0) {
    //   columnsToBeExported = columnsToBeExported.filter(
    //     (item) => !Exclued  FieldFromColumns.includes(item.Text)
    //   );
    // }
    let ExcelExportFieldNames = null;
    var dataOfColumnsToBeExported = _.map(filterapplicantsData, (m) => {
      var object = {};

      // Additional columns and data inside the Excel file are created
      _.each(ExcelExportFieldNames, (oneObj) => {
        if (
          oneObj.DataType !== undefined &&
          oneObj.DataType === StandardConst.dataTypes.DateTime
        ) {
          object[oneObj.ExcelColumnHeading] =
            m[oneObj.DataColumnName] !== null && m[oneObj.DataColumnName] !== ""
              ? format(new Date(m[oneObj.DataColumnName]), "dd-MM-yyyy HH:mm a")
              : "";
        } else if (
          oneObj.DataType !== undefined &&
          oneObj.DataType === StandardConst.dataTypes.Date
        ) {
          object[oneObj.ExcelColumnHeading] =
            m[oneObj.DataColumnName] !== null && m[oneObj.DataColumnName] !== ""
              ? format(new Date(m[oneObj.DataColumnName]), "dd-MM-yyyy")
              : "";
        } else {
          object[oneObj.ExcelColumnHeading] =
            m[oneObj.DataColumnName] !== undefined &&
            m[oneObj.DataColumnName] !== null
              ? m[oneObj.DataColumnName]
              : "";
        }
      });

      _.each(columnsToBeExported, (singleColumn) => {
        // ye code achha nahi laga isko dubara dekhkr sahi krna hai (aisa sir ne kaha hai)
        if (
          singleColumn.Text === "Working Days" &&
          Array.isArray(m.workingDays)
        ) {
          object[singleColumn.Text] = m.workingDays
            .map((Val) => `${Val.Day}`)
            .join(",");
        } else {
          if (singleColumn.Value !== undefined && singleColumn.Value !== null) {
            if (Array.isArray(singleColumn.Value)) {
              // [M,T,W,Th,F,Sa]
              object[singleColumn.Text] = singleColumn.Value.join("");
            } else {
              object[singleColumn.Text] =
                m[singleColumn.Value ?? singleColumn.Text];
            }
          } else if (singleColumn.render) {
            if (singleColumn.render(m).props.Number) {
              object[singleColumn.Text] = singleColumn.render(m).props.Number;
            } else {
              object[singleColumn.Text] = Array.isArray(
                singleColumn.render(m).props.children
              )
                ? singleColumn.render(m).props.children.join("")
                : singleColumn.render(m).props.children;
            }
          }
        }

        // object[singleColumn.Text] = singleColumn.render ?
        // (Array.isArray(singleColumn.render(m).props.children)) ? singleColumn.render(m).props.children.join('') : singleColumn.render(m).props.children
        // : m[singleColumn.Value ?? singleColumn.Text]
      });

      return object;
    });
    let excelExportFileName = "ProfileList -" + Date.now().toString();
    ExcelExport(dataOfColumnsToBeExported, excelExportFileName, dispatch);
  };

  return (
    <>
      <Container
        fluid
        className="base-container"
        style={{ minHeight: "800px" }}
      >
        <Topbar bData={bData} HeadingText="Profile Search"></Topbar>
        {/* <AppbarComponent
          isSearchRequired={false}
          isAddButtonRequired={false}
          Exception={false}
          isExcelExportButtonVisible={true}
          exportData={filterapplicantsData}
          columns={columns}
        /> */}
       <Form
  onSubmit={onBasicSearchSubmit}
  className="w-100"
  style={{ backgroundColor: "#0D6EF1" }} // Minimize form height
>
  <div style={{paddingLeft:'1rem',paddingRight:'1.5rem'}} className="  d-flex flex-row w-100 flex-wrap justify-content-between align-items-center text-center">
    <div className="pt-1 pb-1 rounded m-0 w-auto">
      <div className="m-0 d-flex flex-column flex-md-row flex-lg-row gap-1 w-auto justify-content-center text-align-center">
       
        <div className="">
          <FormInputText
            name="Skills"
            placeholder="Skills"
            type="text"
            isRequired="true"
            className="border-0 form-control blue_input"
            style={{
             
              padding: "10px",
              borderRadius: "5px",
              border: "none",
            }}
          />
        </div>
        <div className="">
          <FormInputText
            name="Position"
            placeholder="Position"
            type="text"
            isRequired="true"
            className="border-0 form-control blue_input"
            style={{
             
              padding: "10px",
              borderRadius: "5px",
              border: "none",
            }}
          />
        </div>
        <div className="">
          <FormInputText
            name="Location"
            placeholder="Location"
            type="text"
            isRequired="true"
            className="border-0 form-control blue_input"
            style={{
              
              padding: "10px",
              borderRadius: "5px",
              border: "none",
            }}
          />
        </div>
        <div className="">
          <FormInputText
            name="YearOfExperience"
            placeholder="Experience"
            type="number"
            isRequired="true"
            className="border-0 form-control blue_input"
            style={{
              
              padding: "10px",
              borderRadius: "5px",
              border: "none",
            }}
          />
        </div>
        <div className="  col-auto p-1">
          <Button
            type="submit"
            className=""
          >
            Search Profiles
          </Button>
        </div>
      </div>
    </div>

    <Button
      onClick={() => ExportToExcel()}
      className=" d-flex justify-content-end align-items-center"
    >
      <i className="fa fa-file-excel-o me-2" aria-hidden="true"></i> EXPORT
    </Button>
  </div>
</Form>


        <div className="m-4">
          <Box sx={{ flexGrow: 1 }}>
            {filterapplicantsData.length > 0 ? (
              <Grid
                container
                spacing={1}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ display: "flex" }}
              >
                {filterapplicantsData.map((Applicant, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    key={index}
                    sx={{ display: "flex" }}
                  >
                    <Card
                      sx={{
                        width: "100%", // Make the card take up full width of its grid item
                        display: "flex", // Enable flexbox for the card
                        flexDirection: "column", // Ensure contents are stacked vertically

                        // Ensure card takes up the full height
                      }}
                    >
                      <CardHeader
                        avatar={
                          <Avatar
                            alt={Applicant.FullName}
                            src={`${StandardConst.uploadImages}${
                              Applicant.ProfileImage ?? "user_placeholder.jpg"
                            }`}
                            sx={{ width: 70, height: 90, borderRadius: "8px" }}
                            variant="square"
                          />
                        }
                        title={Applicant?.FullName}
                        titleTypographyProps={{
                          sx: {
                            fontSize: "1rem", // Adjust font size
                            fontWeight: "bold", // Adjust font weight
                            color: "#0D6EF1", // Adjust text color
                          },
                        }}
                        subheader={
                          Applicant?.Position && (
                            <Chip
                              label={Applicant?.Position}
                              sx={{
                                color: "#EE9626",
                                padding: "0px",
                                borderRadius: "5px",
                                fontWeight: "bold",
                                backgroundColor: "rgba(255, 255, 0, 0.2)",
                                marginBottom: "8px",
                              }}
                              size="small"
                            />
                          )
                        }
                        className="pb-0"
                      />
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography variant="body2" color="text.secondary">
                          {Applicant?.Address}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {Applicant?.Phone}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {Applicant?.Email}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {Applicant?.Location}
                        </Typography>
                        <br />
                        {Applicant.ApplicantSkills && (
                          <div>
                            <span>Skill : </span>
                            <br />
                            {Applicant.ApplicantSkills?.split(",").map(
                              (ApplicantSkill) => (
                                <>
                                  <Chip
                                    label={ApplicantSkill}
                                    sx={{
                                      color: "green",
                                      padding: "0px",
                                      borderRadius: "5px",
                                      fontWeight: "bold",
                                      backgroundColor: "rgba(0,125,0,0.1)",
                                      marginBottom: "8px",
                                    }}
                                    size="small"
                                  />
                                  &nbsp;
                                </>
                              )
                            )}
                          </div>
                        )}
                      </CardContent>

                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <a href={`tel:${Applicant?.Phone}`}>
                            <ActionButton
                              IconName="Phone"
                              id="btnIdJobView"
                              title="Call"
                            />
                          </a>
                          <a href={`mailto:${Applicant?.Email}`}>
                            <ActionButton
                              IconName="Mail"
                              id="btnIdJobView"
                              title="Email"
                            />
                          </a>
                          <ActionButton
                            onClick={() =>
                              navigate("/JobApplicantProfile", {
                                state: {
                                  JobApplicantId: Applicant.JobApplicantId,
                                  JobApplicationId: Applicant.JobApplicationId,
                                },
                              })
                            }
                            IconName="View"
                            id="btnIdJobView"
                            title="View Profile"
                            IconColor="primary"
                          />
                          <ActionButton
                            onClick={() =>
                              navigate("/FixInterview", {
                                state: {
                                  JobId: Applicant.JobId,
                                  JobApplicantId: Applicant.JobApplicantId,
                                },
                              })
                            }
                            IconName="FixInterview"
                            id="btnIdFixInterview"
                            title="Schedule Interview"
                          />
                        </div>
                      </div>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <NoRecordTemplate
                headerValue={"No Applicants Found"}
                subHeaderValue={"There are no Applicants"}
                imageUrl={StandardConst.imageNoRecordsFound}
              />
            )}
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            <Pagination
              count={totalPages} // Total number of pages
              page={page} // Current page
              onChange={(e, v) => setPage(v)} // Handle page change
              color="primary"
              size="large"
            />
          </Box>
        </div>
      </Container>
    </>
  );
};

// this is for fetch job posting time
export const CalculatePostedDate = ({ dateTime }) => {
  const [difference, setDifference] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const targetDate = new Date(dateTime);

      const diffInMs = now - targetDate;
      const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );

      let differenceString = "";
      if (days > 0) {
        differenceString = `${days} day${days > 1 ? "s" : ""} ago`;
      } else if (hours > 0) {
        differenceString = `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else {
        differenceString = "Just now";
      }

      setDifference(differenceString);
    }, 1000);

    return () => clearInterval(interval);
  }, [dateTime]);

  return (
    <div>
      <p>{difference}</p>
    </div>
  );
};

export default ProfileSearch;
