import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { WSSuccessAlert } from "../../Services/WSAlert";
import { StandardConst } from "../../Services/StandardConst";
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditOfficelocation = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [openModalCount, setOpenModalCount] = useState(0);

  const companyInfo = useSelector((s) => s.auth.companyInfo ?? {});

  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setShow(true);
      if(id > 0){
        setShow(false);
        await fetchLocationData(id);
        setShow(true);
      }
    },
  }));

  useEffect(() => { getCompanyList(); }, []);

  const getCompanyList = async () => {
    await WebService({
      endPoint: `CommonUtility/Edit/companyinformation?CompanyId=${companyInfo.CompanyId}`,
      method: 'GET'
    }).then(res => {
      if(res.length > 0) {
        setCompanyList(res);
      }
    });
  }

  const fetchLocationData = async (id) => {
    await WebService({
      // endPoint: `Officelocation/Fetch/${id || 0}`,
      endPoint: `CommonUtility/Edit/officelocation?OfficeLocationId=${id || 0}`,
      dispatch,
    }).then(res => res.length > 0 ? setData(res[0]) : null);
  }

  const handleClose = () => {
    setShow(false);
    setData({});
    setOpenModalCount(openModalCount+1);
  };

  const requiredMessage = "This is a required field";

  const schema = yup
    .object()
    .shape({
      Location: yup.string().trim().required(requiredMessage),
      Address: yup.string().trim().required(requiredMessage),
    })
    .required();

  const onSubmit = async (data) => {
    if(data.OfficeLocationId !== undefined){
      await WebService({
        endPoint: `CommonUtility/officelocation?OfficeLocationId=${data.OfficeLocationId}`,
        method: "PUT",
        body: data,
        dispatch,
      }).then(res => WSSuccessAlert("Office Location", StandardConst.OfficeLocationUpdateRecord));
    }else{
      await WebService({
        endPoint: "CommonUtility/officelocation",
        body: data,
        dispatch,
      }).then(res => WSSuccessAlert("Office Location", StandardConst.OfficeLocationAddRecord));
    }

    handleClose();
    prop.callBackEvent();
  };
  console.log(data)

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(data?.location?.OfficeLocationId || 0) === 0
            ? "Add Office location"
            : "Edit Office location"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row">
            <FormInputDropdown
              name="CompanyId"
              ddOpt={companyList?.map(m=>({
                text: m.CompanyName,
                value: m.CompanyId
              }))}
              label="Company Name"
            ></FormInputDropdown>
          </div>
          <div className="row mt-2">
            <FormInputText
              name="Location"
              label="Location"
              type="text"
              isRequired="true"
            />
          </div>
          <div className="row">
            <FormInputText
              label="Address"
              name="Address"
              type="text"
              isRequired="true"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btnlocationSubmit"
            variant="outline-primary"
            type="submit"
          >
            {" "}
            {(data?.location?.OfficeLocationId || 0) === 0
              ? "Submit"
              : "Save Changes"}{" "}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditOfficelocation);
