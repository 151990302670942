import React, { useEffect, memo, useState, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditPayslip from "./AddEditPayslip";
import { ActionPermission, PageInfo } from "../PageInfo";
import { useDispatch, useSelector } from "react-redux";
import ViewPaySlip from "./ViewPaySlip";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import SnackbarComponent from "../../Services/SnackbarComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { chain, map } from "underscore";
import { useSearchParams } from "react-router-dom";
import DisplayNumberFormatComponent from "../../Services/DisplayNumberFormatComponent";
import { format } from "date-fns";

const PayslipComponent = () => {
  const [searchParams] = useSearchParams();
  const MasterPageName = "Employee Payslips";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const dispatch = useDispatch();
  PageInfo({ pageTitle: "Employee Payslips" });
  const [records, setRecords] = useState([]);

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
      if(permissionList.length > 0) {
        SetPermission({
          ManageAdd: ActionPermission("PaySlip - Add"),
          ManageDelete: ActionPermission("PaySlip - Delete"),
          ManageEdit: ActionPermission("PaySlip - Edit"),
          ManagePublish: ActionPermission("PaySlip - Publish"),
          ManageSearchAllUser: ActionPermission('PaySlip - Search AllUser'),
        });
        fetchPayslipList();
      }
  }, [permissionList]);

  const ref = useRef();
  const refSnackbar = useRef();

  const fetchPayslipList = async () => {
    const permissionSearchAllUser = ActionPermission('PaySlip - Search AllUser');

    var onlyPublishInvoices =  permissionSearchAllUser ? "N" : "Y";
    var allUsersData = permissionSearchAllUser ? "Y" : "N";

    var endPoint = `Payslip/Fetch?AllUser=${allUsersData}&OnlyPublish=${onlyPublishInvoices}`;
    const data = await WebService({ endPoint, dispatch }).then((r) =>
      map(r, (m) => ({ ...m, EncodedId: window.btoa(m.PayslipId) }))
    );
    setRecords(data);
  };

  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  const viewModalRef = useRef();
  const onDelete = async (PermissionId) => {
    await WebService({
      endPoint: `Payslip/${PermissionId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchPayslipList();
  };
  // useEffect(() => {
  //   if (!renderAfterCalled.current) {
  //     fetchPayslipList();
  //   }
  //   renderAfterCalled.current = true;
  // }, []);
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  const fnView = async (id, isPublish) => await viewModalRef.current.openModal(id || 0, isPublish);
  const [bData, _] = React.useState([
    {
      title: "Payroll Management",
      hrefLink: "#",
    },
    {
      title: "Employee Payslips",
      hrefLink: "#",
    },
  ]);

  const columns = [
    { Text: "EncodedId", Value: "EncodedId", isVisiable: false },
    {
      Text: "Name",
      Value: "FullName",
      IsSearch: true,
      cssClass: "text-center",
    },
    {
      Text: "Month",
      Value:  "FromDate",
      DateFormat: "MMM-yy"
    },
    {
      Text: "Working Days",
      Value: "TotalWorkingDays",
      IsSearch: true,
      cssClass: "text-center",
      //Searchable:false
    },
    {
      Text: "Gross Salary",
      Value: "GrossSalary",
      IsSearch: true,
      style: {textAlign: "right"},
      render: (dr) => {
        return (
          <>
          <div style={{textAlign: "right"}}>
            <DisplayNumberFormatComponent Number={dr.GrossSalary} Currency={dr.Symbol}/>
          </div>
          </>
        )
      }
    },
    {
      Text: "Deduction",
      Value: "TotalDeductions",
      IsSearch: true,
      style: {textAlign: "right"},
      render: (dr) => {
        return (
          <>
            <div style={{ textAlign: "right" }}>
              <DisplayNumberFormatComponent Number={dr.TotalDeductions} Currency={dr.Symbol} />
            </div>
          </>
        )
      }
    },
    {
      Text: "Net Salary",
      Value: "NetSalary",
      IsSearch: true,
      style: {textAlign: "right"},
      render: (dr) => {
        return (
          <>
            <div style={{ textAlign: "right" }}>
              <DisplayNumberFormatComponent Number={dr.NetSalary} Currency={dr.Symbol} />
            </div>
          </>
        )
      }
    },
    {
      Text: "Action",
      cssClass: "text-center td-width-100",
      render: (dr) => (
        <span className="d-flex col-auto">

          {dr.StatusId === StandardConst.Status.Published && (
            <ActionButton
              onClick={() => fnView(dr.PayslipId, false)}
              IconName="View"
              id={`btn_view_${dr.PayslipId}`}
            />
          )}

          {dr.StatusId !== StandardConst.Status.Published && permission.ManageEdit && (
            <ActionButton
              onClick={() => fnEdit(dr.PayslipId)}
              disabled={false}
              IconName="Edit"
              id={`btn_edit_${dr.PayslipId}`}
            />
          )}
          {permission.ManageDelete && (
            <ActionButton
              onClick={(e) =>
                ref.current.confirmAlert(
                  "Delete", //Confirm button text
                  "Are You Sure", // Text if Alert
                  "Do you want to delete " + MasterPageName, // Message of Alert
                  dr.PayslipId // Endpoint to hit for delete
                )
              }
              IconName="Delete"
              id={`btn_delete_${dr.PayslipId}`}
            />
          )}
          {dr.StatusId !== StandardConst.Status.Published && permission.ManagePublish && (
            <ActionButton
              onClick={() => fnView(dr.PayslipId, true)}
              IconName="Publish"
              id={`btn_publish_${dr.PayslipId}`}
            />
          )}
        </span>
      ),
    },
  ];

  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        initialSearchContent={searchParams.get("search") ?? ""}
        records={chain(records)
          .sortBy((s) => s.PayslipId)
          .reverse()
          .value()}
        bData={bData}
        MasterPageName={MasterPageName}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.EmployeePayslipsheaderValueNoResults}
            subHeaderValue={StandardConst.EmployeePayslipsSubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button id="NoRecordFoundAddEmployeePaySlip" variant="outline-primary" onClick={() => fnEdit(0)}>
                    Add {MasterPageName}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        isSearchRequired={true}
        allowSerialNo={true}
        IsAddButtonVisible={permission.ManageAdd}
      ></StaticListComponent>

      <AddEditPayslip
        callBackEvent={() => fetchPayslipList()}
        ref={addEditModalRef}
      ></AddEditPayslip>
      <ViewPaySlip ref={viewModalRef} callBackEvent={() => fetchPayslipList()} />
    </>
  );
};

export default memo(PayslipComponent);
