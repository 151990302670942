import React from "react";
import { useNavigate } from "react-router-dom";
import SupportImage from "../../Images/Customer_Support-removebg-preview.png";
import Button from "react-bootstrap/esm/Button";
import { BugReport, Comment, SupportAgent, WhatsApp } from "@mui/icons-material";

function SupportHome() {
  const navigate = useNavigate();

  const supportOptions = [
    {
      icon: <SupportAgent sx={{ fontSize: "xxx-large",backgroundColor:'white' }} color="primary" />,
      title: "Support Ticket",
      description: "Chat with us during operating hours.",
      buttonText: "Raise a Ticket",
      onClick: () => navigate("/ManageTickets"),
      bgColor: "#FFFFFF",
      ButtonDisabled: false,
    },
    {
      icon: <WhatsApp sx={{ fontSize: "xxx-large",backgroundColor:'white' }} color="success" />,
      title: "WhatsApp Support",
      description: "Chat with us on WhatsApp.",
      buttonText: "Chat Now",
      onClick: () => console.log("WhatsApp"),
      bgColor: "#FFFFFF",
      ButtonDisabled: true,
    },
    {
      icon: <Comment sx={{ fontSize: "xxx-large",backgroundColor:'white' }} color="info" />,
      title: "Live Chat",
      description: "Text us at 1-888-733-5874.",
      buttonText: "Text Now",
      onClick: () => console.log("Live Chat"),
      bgColor: "#FFFFFF",
      ButtonDisabled: true,
    },
  ];

  return (
    <div className=" pb-1 mx-2 h-100 bg-white shadow-top rounded-2">
      <div className="row flex-md-row-reverse">
        {/* Image Section */}
        <div className="col-12 col-md-6 d-flex  justify-content-center align-items-center bg-info-subtle">
          <img
            className="img-fluid shadow-b"
            style={{'box-shadow':' h-shadow v-shadow blur spread color inset'}}
            src={SupportImage}
            alt="support"
          />
        </div>

        {/* Support Options Section */}
        <div className="col-12 col-md-6 d-flex flex-column align-items-center gap-4 py-5">
          {supportOptions.map((option, index) => (
            <div key={index} className="w-100 px-3">
              <SupportCard {...option} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SupportHome;

// SupportCard Component
const SupportCard = ({
  icon,
  title,
  description,
  buttonText,
  onClick,
  bgColor,
  ButtonDisabled,
}) => {
  return (
    <div
      className="shadow-lg p-4 bg-white rounded text-center"
      style={{ backgroundColor: bgColor }}
    >
      <div className="card-body d-flex flex-column">
        {/* Icon Section */}
        <div
          className="rounded-circle mx-auto mb-3 d-flex align-items-center justify-content-center"
          style={{
            width: "60px",
            height: "60px",
          }}
        >
          {icon}
        </div>
        {/* Title */}
        <h5 className="card-title">{title}</h5>
        {/* Description */}
        <p className="card-text text-muted">{description}</p>
        {/* Button */}
        <Button
          className="mt-auto btn-primary "
          onClick={onClick}
          variant="outlined"
          disabled={ButtonDisabled}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
