import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Form, FormInputDropdown, FormInputText } from "../Form";
import { StandardConst } from "../../Services/StandardConst";
import { WebService } from "../../Services/WebService";

const { forwardRef, useState, useImperativeHandle } = React;

const AddEditManageGroup = (prop, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [data, setData] = useState({ StaticGroup: {} });

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id === 0) {
                setData({ StaticGroup: {} })
            } else {
                setShow(false);
                const data = {
                    StaticGroup: await WebService({
                        endPoint: `CommonUtility/Edit/StaticGroups?StaticGroupId=${id || 0}`,
                        dispatch,
                    }).then((c) => (c.length > 0 ? c[0] : {}))
                }
                
                setData(data);
                setShow(true);
            }
        },
    }));
    const handleClose = () => setShow(false);
  

    const onSubmit = async (data) => {
        if (data.StaticGroupId === undefined) {
            await WebService({
                endPoint: 'CommonUtility/StaticGroups ',
                body: data,
                dispatch,
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/StaticGroups?StaticGroupId=${data.StaticGroupId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
        }
        handleClose();
        prop.callBackEvent();
    };
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {(data?.StaticGroup?.StaticGroupId || 0) === 0
              ? "Add Group"
              : "Edit Group"}
          </Modal.Title>
        </Modal.Header>
        <Form defaultValues={data.StaticGroup} onSubmit={onSubmit}>
          <Modal.Body className="p-4">
            <div className="row">
              <div className="col-md-12">
                <FormInputText
                  label="Group Name"
                  name="GroupName"
                  type="text"
                  isRequired="true"
                />
              </div>
              <div className="col-md-12">
                <FormInputText
                  label="Group Description"
                  name="GroupDescription"
                  type="text"
                  as="textarea"
                  isRequired="true"
                />
              </div>
              <div className="col-md-12">
                <FormInputText
                  label="Group Made For"
                  name="GroupMadeFor"
                  type="text"
                  isRequired="true"
                />
              </div>
              <div className="col-md-12">
                <FormInputDropdown
                  name="IsReserved"
                  ddOpt={StandardConst.YesOrNoReserved}
                  label="Is Reserved"
                  isRequired="true"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button id="btnSubmit" variant="outline-primary" type="submit">
              {" "}
              {(data?.StaticGroup?.StaticGroupId || 0) === 0
                ? "Submit"
                : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
};

export default forwardRef(AddEditManageGroup);
