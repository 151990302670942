import React, { useEffect, useRef, useState } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton';
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { ActionPermission, PageInfo } from '../PageInfo';
import { useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/esm/Button";
import AddEditTicketCategory from './AddEditTicketCategory';

const ProductTicketCategories = () => {

  const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    PageInfo({ pageTitle: " Product Ticket Categories" });
    useEffect(() => {
        SetPermission({
            ManageAdd: true , // ActionPermission("Ticket Categories - Add"),
            ManageEdit: true, // ActionPermission("Ticket Categories - Edit"),
            ManageDelete: true, // ActionPermission("Ticket Categories - Delete"),
        });
    }, [permissionList]);

    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Product Ticket Categories";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const addEditModalRef = useRef();

    const AddButtonText = "Ticket Categories";
    const dispatch = useDispatch();
    const onDelete = async (TicketCategoryId) => {
        await WebService({
            endPoint: `CommonUtility/ticketcategories?TicketCategoryId=${TicketCategoryId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchTicketCategories();
    };
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

    const fetchTicketCategories = async() => {
        const data = await WebService({
            endPoint: `Tickets/getAllProductTicketCategories`,
            dispatch,
        });
        console.log(data)
        const modifiedArray = data.map((res) => {
            if(res.ParentId){
                const [ParentDetails] = data.filter((item) => item.TicketCategoryId === res.ParentId);
                res.ParentId = ParentDetails?.CategoryName;
            }
            return res;
        });
        setRecords(modifiedArray);
        dispatch(ConfigurationflowStatusUpdate());
    }
console.log(records)
    useEffect(() => {
        fetchTicketCategories();
    }, []);

    const [bData,] = React.useState([
        {
            title: "Helpdesk",
            hrefLink: "#",
        },
        {
            title: "Ticket Categories",
            hrefLink: "#",
        },
    ]);

    const columns = [
        {
            Text: "Category Name",
            Value: "CategoryName"
        },
        {
            Text: "Parent",
            Value: "ParentId",
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => fnEdit(dr.TicketCategoryId)}
                        disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit Ticket Category"}
                        id={`btnAssetTypeEdit_${dr.TicketCategoryId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.CategoryName, // Message of Alert
                                dr.TicketCategoryId  // Endpoint to hit for delete
                            )}
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Ticket Category"}
                        id={`btnAssetTypeDelete_${dr.TicketCategoryId}`}
                    />
                </>
            ),
        },
    ];

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

  return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.TicketCategoriesheaderValueNoResults}
                        subHeaderValue={StandardConst.TicketCategoriesSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {permission.ManageAdd && (
                                    <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_${AddButtonText}`}>
                                        Add {AddButtonText}
                                    </Button>
                                )}
                            </>
                        }
                    />
                }
                onAddEvent={() => fnEdit()}
                IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
            <AddEditTicketCategory
                callBackEvent={async () => await fetchTicketCategories()}
                ref={addEditModalRef}
            ></AddEditTicketCategory>
        </>
    )
}

export default ProductTicketCategories