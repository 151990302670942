import React, { useEffect, useRef, useState } from "react";
import { StandardConst } from "../../Services/StandardConst";

import { useDispatch, useSelector } from "react-redux";
import SnackbarComponent from "../../Services/SnackbarComponent";
import { WebService } from "../../Services/WebService";
import ActionButton from "../../Services/ActionButton";
import { Container, Box } from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";

import TableComponent, { NoRecordTemplate } from "../../Services/TableComponent";
import AddEditSkills from "./AddEditSkills";
import { ActionPermission } from "../PageInfo";

const ManageSkills = () => {
  const [Skills, setSkills] = useState();
  const refSnackbar = useRef();
  const MasterPageName = "Manage Skills";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const dispatch = useDispatch();
  const addEditModalRef=useRef()
  const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
  const [permission, SetPermission] = useState({});
  const ref = useRef();
  const AddButtonText = "Add Skills";
  const FetchSkills = async () => {
    await WebService({
      endPoint: `CommonUtility/fetchAll/staticskill`,
      method: "get",
    }).then((r) => setSkills(r));
  };

  useEffect(() => {
    FetchSkills();
  }, []);
console.log(permissionList)
  useEffect(() => {
        SetPermission({
            ManageAdd: ActionPermission("Manage Skills - Add"),
            ManageEdit:ActionPermission("Manage Skills - Edit"), // ActionPermission("Visitor Type - Edit"),
            ManageDelete:ActionPermission("Manage Skills - Delete"), // ActionPermission("Visitor Type - Delete"),
        });
    }, [permissionList]);
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  const columns = [
    {
      Text: "SkillName",
      Value: "SkillName",
    },
    {
        Text: "Action",
        cssClass: "text-center w-2",
        isVisiable: permission.ManageEdit || permission.ManageDelete,
        render: (dr) => (
            <span className="d-flex ">
                <ActionButton
                    onClick={() => fnEdit(dr.SkillId)}
                    disabled={!permission.ManageEdit}
                    IconName="Edit"
                    IconTooltip={"Edit Interview Type"}
                    id={`btnAccessLevelEdit_${dr.SkillId}`}
                />
                <ActionButton
                    onClick={(e) =>
                        ref.current.confirmAlert(
                            "Delete", //Confirm button text
                            "Are You Sure", // Text if Alert
                            "Do you want to delete " + dr?.SkillName, // Message of Alert
                            dr.SkillId  // Endpoint to hit for delete
                        )}
                    disabled={!permission.ManageDelete}
                    IconName="Delete"
                    IconTooltip={"Delete skill"}
                    id={`btnAccessLevelDelete_${dr.SkillId}`}
                />
            </span>
        ),
    },
   
  
  ];

  const [bData] = React.useState([
    {
      title: "Manage Skills",
      hrefLink: "#",
    },
    {
      title: "",
      hrefLink: "#",
    },
  ]);

    const onDelete = async (SkillId) => {
          await WebService({
              endPoint: `CommonUtility/staticskill?SkillId=${SkillId}`,
              method: "DELETE",
              dispatch,
          });
          refSnackbar.current.setOpenSnackBar();
          await FetchSkills();
      };
  

  return (
    <>
      <Container fluid className="base-container">
        <Box
          sx={{
            width: 1,
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <h5 className="ms-4 mt-2">{MasterPageName}</h5>
              <div className="ms-4">
                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
              </div>
            </div>
          </div>
        </Box>
        <div></div>
        {/* {filterComponent}  @shubham95shrivas please review this */}
        <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />

        <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
        <TableComponent
          columns={columns}
          data={Skills}
          noRecordCss="p-0"
          AddButtonText={AddButtonText}
          noRecordFound={
            <NoRecordTemplate
              headerValue={StandardConst.ManageMyDocumentNoResult}
              subHeaderValue={StandardConst.ManageMyDocumetSubHeaderRole}
              imageUrl={StandardConst.imageNoRecordsFound}
            />
          }
          onAddEvent={() => fnEdit()}
          IsAddButtonVisible={permission?.ManageAdd}
          isSearchRequired={true}
          allowSerialNo={true}
        />
         <AddEditSkills
        callBackEvent={async () => await FetchSkills()}
        ref={addEditModalRef}
        ></AddEditSkills>
      </Container>
      
       
    </>
  );
};

export default ManageSkills;
