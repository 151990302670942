import forgotPasswordSvg from "../asset/Forgot-password-cuate.svg";
import userLoginSvg from "../asset/Forgot-password-cuate.svg";

export const StandardConst = {
  apiBaseUrl: process.env.REACT_APP_API_URL,
  images: `${process.env.REACT_APP_API_URL}/images/`,
  uploadImages: `${process.env.REACT_APP_API_URL}/uploads/`,
  vehicleInsuranceDocument: `${process.env.REACT_APP_API_URL}/uploads/VehicleInsuranceDocument/`,
  vehicleDocuments: `${process.env.REACT_APP_API_URL}/uploads/VehicleDocuments/`,
  VehicleCertificationDocuments: `${process.env.REACT_APP_API_URL}/uploads/VehicleCertificationDocuments/`,
  payslipPDF: `${process.env.REACT_APP_API_URL}/uploads/PayslipPDF/`,
  JobApplicantResume: `${process.env.REACT_APP_API_URL}/uploads/Recruitment/Resume`,
  JobApplicantCertification: `${process.env.REACT_APP_API_URL}/uploads/Recruitment/Certification`,
  ProjectImagesInPublicAssets: `${process.env.PUBLIC_ASSETS_URL}/wod/project_images`,

  // RazorpayKeys ko config.js me bhi change kro
  RazorpayKeys :{
    KeyId : 'rzp_live_WfRvpp6A2RNST6',
  },
  //Server Base URL
  //Changed 08/05/2023
  // apiBaseUrl: "https://uatapi.wiseofficeday.com",
  // images: "https://uatapi.wiseofficeday.com/images/",
  // uploadImages: "https://uatapi.wiseofficeday.com/uploads/",
  employeeId: "1",
  forgotPassword: forgotPasswordSvg,
  userLoginSvg: userLoginSvg,
  requiredMessage: "This field is required",
  numberError: "Character is not valid",
  mobileMessage: "Mobile should be 10 Digit",
  InvoiceStatusArray: [
    { value: 13, text: "Paid" },
    { value: 14, text: "Unpaid" },
    { value: 15, text: "Canceled" },
    { value: 16, text: "Sent" },
    { value: 7, text: "Published" },
  ],

  InvoiceStatus:[
    { value: 13, text: "Paid" },
    { value: 14, text: "Unpaid" },
    { value: 15, text: "Canceled" },
    { value: 16, text: "Sent" },
    { value: 7, text: "Published" },
  ],
  

  // HtmlTemplate: [
  //   { value: "sample.html", text: "Badge format - Potrait" },
  //   { value: "QR.html", text: "Badge Format 2 - Potrait" },
  //   { value: "landscape01.html", text: "Badge format - Landscape" },
  // ],
  BadgeType :[
    {value: 1, text: "Visitor Badge"},
    {value: 2, text: "Employee Id Card"},
    {value: 3, text: "Event Visitor Badge"}
  ],
  CoordinatorType :[
    {value: 1, text: "Company Employee"},
    {value: 2, text: "External Person"},
  ],
  EventTime :[
    {value: 1, text: "Whole Day"},
    {value: 2, text: "Specific Time"},
  ],
  Access: [
    { value: 1, text: "All Companies" },
    { value: 2, text: "Specific Company" },
  ],

  OrientationVisitorBadge: [
    { value: "l", text: "Landscape" },
    { value: "p", text: "Portrait" },
  ],

  DataEntryType: [
    { value: 0, text: "Manual Entry" }
  ],

  invoiceFieldType: [
    { value: "String", text: "String" },
    { value: "Number", text: "Number" },
    { value: "Date", text: "Date" },
    { value: "Currency", text: "Currency" },
  ],

  dataTypes: {
    String: "String",
    Number: "Number",
    Date: "Date",
    Currency: "Currency",
    DateTime: "DateTime"
  },

  DiscountType: [
    {value: "Manual", text: "Manual" },
    { value: "Before Tax Percentage", text: "Before Tax - Percentage" },
    { value: "Before Tax Fixed", text: "Before Tax - Fixed" },
    { value: "After Tax Percentage", text: "After Tax - Percentage" },
    { value: "After Tax Fixed", text: "After Tax - Fixed" },
  ],

  InvoiceNumberFormat: [
    { value: "000000", text: "000001" },
    { value: "YYYY/000000", text: "YYYY/000001" },
    { value: "000000/MM/YYYY", text: "000001/MM/YYYY" },
    { value: "000000/MM/YY", text: "000001/MM/YY" },
    { value: "YY/MM/000000", text: "YY/MM/000001" },
    { value: "Manual", text: "Manual" },
  ],

  Currency : [
    { value: 1, text: "Dollar - $" },
    { value: 2, text: "Euro - €" },
    { value: 3, text: "Pound Sterling - £" },
    { value: 4, text: "Yen - ¥" },
    { value: 5, text: "Rupee - ₹" },
    { value: 6, text: "Dinar - د.ك" },
  ],
  Status: {
    Applied: 1,
    Cancel: 2,
    Approved: 3,
    Reject: 4,
    Pending: 5,
    Published: 7,
    Completed: 8,
    Unpaid: 11,
    Canceled: 12,
    Paid: 13,
    InvoiceUnpaid:14,
    Active: 18,
    Inactive: 19,
    Delete: 20,
    Open: 21,
    Close: 22,
    Paused: 23,
    Hired: 24,
    Reopened: 25,
    Scheduled: 26,
    Selected: 27,
    CallNotPicked : 28,
    DeniedToTakeInterview : 29,
    SelectedForNextRound : 30,
    NotFitForPosition : 31,
    OnHold : 32,
    MakeAnOffer : 33,
    Interviewed : 34,

  },

  // Used in AddEditBankDetails.jsx page    also in AddEditEmailTemplate
  ActiveInActiveStatusIdDropdown: [
    { value: 18, text: "Active" },
    { value: 19, text: "In Active" },
  ],

  JobApplicationStatusDropdown : [
    {text: "Applied", value: 1},
    {text: "Interview Scheduled", value: 26},
    {text: "Interviewed", value: 34},
    {text: "Rejected", value: 4},
    {text: "Selected", value: 8},
    {text: "Hired", value: 24},
  ],

  JobStatusDropdown : [
    { value: 21, text: "Open" },
    { value: 22, text: "Close" },
    { value: 23, text: "Paused" },
    { value: 24, text: "Hired" },
    { value: 25, text: "Reopened" },
    { value: 12, text: "Cancelled" },
  ],

  InterviewStatusDropdown: [
    { value: 26, text: "Scheduled" },
    { value: 8, text: "Completed" },
    { value: 12, text: "Cancelled" },
    { value: 27, text: "Selected" },
    { value: 4, text: "Rejected" },
    { value: 28, text: "Call not Picked" },
    { value: 29, text: "Denied to take Interview" },
    { value: 30, text: "Selected for next round" },
    { value: 31, text: "Not fit for Position" },
    { value: 32, text: "On Hold" },
    { value: 33, text: "Make an Offer" },
  ],
  
  UserType: {
    User: "U",
    Employee: "E",
    SuperAdmin: "S",
    Visitor: "V",
    EventVisitor: "EV",
    Customer : "C",
    HelpdeskAgent : "HA",
    EventCoordinator : "EC",
  },

  EmployeeIdCardStatus: {
    Active: 1,
    InActive: 2,
  },
  YesNo: {
    Yes: 1,
    No: 0,
  },
  YesOrNo: [
    { value: 1, text: "Yes" },
    { value: 0, text: "No" },
  ],
  YesOrNoReserved: [
    { value: 1, text: "Visible to all companies. Admin can edit" },
    { value: 0, text: "Visible to all companies" },
  ],
  StatusDropdown: [
    { value: 1, text: "Active" },
    { value: 0, text: "Inactive" },
  ],
  TicketStatusDropdown: [
    { value: 1, text: "Open" },
    { value: 2, text: "In Progress" },
    { value: 3, text: "Resolved" },
  ],
  TicketPriorityDropdown: [
    { value: 1, text: "Low" },
    { value: 2, text: "Medium" },
    { value: 3, text: "High" },
  ],
  AssetStatusDropdown: [
    { value: 5, text: "Available" },
    { value: 1, text: "In Use" },
    { value: 2, text: "Lost" },
    { value: 3, text: "Damaged" },
    { value: 4, text: "Returned" },
    {value: 6, text: "Given For Repair"},
    {value: 7, text: "Retired"}
  ],
  AssetAssign: [
    { value: 1, text: "In Use" },
    { value: 2, text: "Lost" },
    { value: 3, text: "Damaged" },
    { value: 4, text: "Returned" },
  ],
  AssetCondition: [
    { value: 1, text: "New" },
    { value: 2, text: "Refurbished" },
    { value: 3, text: "Used" },
  ],
  defaultSubscrptionPlan: 1,
  IsActive: 1,
  //#region System Role
  SystemRole: {
    HR: 1,
    Manager: 2,
    Employee: 3,
    EventCoordinator: 124,
    SuperAdmin: 4,
    Admin: 5,
    Company: 7,
    Customer: 8,
    Receptionist: 9,
    Agent: 153,
    JobApplicant: 10,
  },
  ShowOrHide: {
    Show: "Show",
    Hide: "Hide"
  },
  //#endregion

  VisitorGuestMsgForReceptionist : "Guests are allowed inside campus",
  defaultIntValue: 0,
  //No Results found message details
  imageNoRecordsFound: `${process.env.PUBLIC_ASSETS_URL}/wod/project_images/no-records-j.png`,
  headerValueNoResults: "No results found",
  subHeaderValueStandard: "Looks like you don't have any results!!!",
  subHeaderQualification:
    "Looks like you don't have any results for qualification!!!",
  subHeaderAttandanceReport:
    "Looks like you don't have any results for Attendance Report!!!",
  subHeaderLeaveAllocation:
    "Looks like you don't have any results for Leave Allocation!!!",
  subHeaderProject: "Looks like you don't have any results for Project!!!",
  subHeaderTaskCategory:
    "Looks like you don't have any results for Task Category!!!",
  subHeaderRole: "Looks like you don't have any results for Role!!!",

  HolidayheaderValueNoResults: "Holiday calendar is not set yet!",
  HolidayubHeaderRole:
    "You have not set the holiday calendar for the company yet.",

  AccessLevelheaderValueNoResults: "No Access Levels added in the system.",
  AccessLevelSubHeader: "Please add Access Levels",

  ManageAgentsheaderValueNoResults: "No Agents added in the system.",
  ManageAgentsSubHeader: "Please add Agent",

  AssetCustomFieldheaderValueNoResults: "No Asset Custom Fields added in the system.",
  AssetCustomFieldSubHeader: "Please add Asset Custom Fields",

  AssetAssignedReportheaderValueNoResults: "No Assets in the system.",
  AssetAssignSubHeader: "Please Add Asset",

  AccessLevelTypeheaderValueNoResults: "No Access Level Types added in the system.",
  AccessLevelTypesSubHeader: "Please add Access Level Types",

  AssetTypeheaderValueNoResults: "No Asset Types added in the system.",
  AssetTypesSubHeader: "Please add Asset Types",

  ManageSubcriptionheaderValueNoResults: "No Subscription added in the system.",
  ManageSubcriptionSubHeader: "Please add Subscription",

  SubscriptionTypeheaderValueNoResults: "No Subscription Type added in the system.",
  SubcriptionTypeSubHeader: "Please add Subscription Type",

  UserTypeheaderValueNoResults: "No User Type added in the system.",
  UserTypeSubHeader: "Please add User Type",

  ManageAttachheaderValueNoResults: "No Attach added in the system.",
  ManageAttachSubHeader: "Please add Attach",

  AssignTicketCategoryheaderValueNoResults: "No Assign Ticket Category in the system.",
  AssignTicketCategorySubHeader: "Please Assign Ticket Category",

  TicketCategoriesheaderValueNoResults: "No Ticket Categories added in the system.",
  TicketCategoriesSubHeader: "Please add Ticket Categories",

  ManageTicketsheaderValueNoResults: "No Tickets added in the system.",
  ManageTicketsSubHeader: "Please add Ticket",

  ScheduledInterviewheaderValueNoResults: "No Scheduled Interview in the system.",
  ScheduledInterviewSubHeader: "Please Schedule Interview",

  AssetheaderValueNoResults: "No Assets added in the system.",
  AssetSubHeader: "Please add Assets",

  ManageVehicleheaderValueNoResults: "No vehicle added in the system.",
  ManageVehicleSubHeader: "Please add Vehicle",

  VehicleMaintenanceheaderValueNoResults: "No vehicle maintenance and repairs scheduled in the system.",
  VehicleMaintenanceSubHeader: "Please add maintenance and repairs",

  VehicleDriverAssignmentheaderValueNoResults: "No vehicle assigned to any driver in the system.",
  VehicleDriverAssignmentSubHeader: "Please assign vehicle",

  VehicleCustomFieldheaderValueNoResults: "No Vehicle Custom Fields added in the system.",
  VehicleCustomFieldSubHeader: "Please add Vehicle Custom Fields",

  BankDetailCustomFieldheaderValueNoResults: "No Bank Detail Custom Fields added in the system.",
  BankDetailCustomFieldSubHeader: "Please add Bank Detail Custom Fields",

  EmployeeCustomFieldheaderValueNoResults: "No Employee Custom Fields added in the system.",
  EmployeeCustomFieldSubHeader: "Please add Employee Custom Fields",

  EmployeePayslipCustomFieldheaderValueNoResults: "No Employee Payslip Custom Fields added in the system.",
  EmployeePayslipCustomFieldSubHeader: "Please add Employee Payslip Custom Fields",

  AccessGroupheaderValueNoResults: "No Access Group added in the system.",
  AccessGroupSubHeader: "Please add Access Group",

  ManageOfficeLocationheaderValueNoResults:
    "Office locations are not defined yet!",
  ManageOfficeLocationubHeaderRole: "You can enter the office locations now.",

  ManageContactForOfficeLocationheaderValueNoResults:
    "No contacts found for this office location",
  ManageContactForOfficeLocationSubheaderValueNoResults:
    "You can add contacts for the location. These contacts will be visible to the employees on their dashboards for immediate help as needed.",

  ManageBadgeTemplateheaderValueNoResults:
    "Badge Templates are not defined yet!",
  ManageBadgeTemplateHeaderRole: "You can enter the Badge Templates now.",

  ManageProjectheaderValueNoResults: "No projects defined yet!",
  ManageProjectHeaderRole: "It's time to create your first project.",

  ProjectDesignationheaderValueNoResults:
    "Project team hierarchy is not defined yet.",
  ProjectDesignationHeaderRole:
    "Create your project team structure so that team members can be onboarded on projects as per this structure. e.g. Project Manager, Project lead etc.",

  ManageJobFunctionheaderValueNoResults: "No Job Functions names defined yet!",
  ManageJobFunctionHeaderRole:
    "Job functions are important to define the role that you are performing during the work. It helps you define key set of responsibilities for the job function. e.g. DEVELOPER - Will write code, Electrician - Will handle electricity related works.",

  DocumentListheaderValueNoResults:
    "No mandatory documents defined for employees",
  DocumentListHeaderRole:
    "Do you require employees to submit some mandatory documents during the self registration process? Please create the list of mandatory documents here.",

  AssignTeamheaderValueNoResults: "Project team is not assigned yet.",
  AssignTeamSubHeaderRole:
    "Let's assign the team members to the project and the role that they will play in the project.",

  CustomerheaderValueNoResults: "No customers added yet.",
  CustomerSubHeaderRole:
    "When you add customers, you can give them visibility to project insights online. e.g. You can publish timesheets for the customer to view online.",

  BankDetailsheaderValueNoResults:
    "You have not provided your bank details yet.",
  BankDetailsSubHeaderRole:
    "Please add your bank details so that your payroll records are updated for smooth transactions.",

  GeneratedEmployeeIDheaderValueNoResults:
    "We did not find any employee ID cards",

  AssignRoleheaderValueNoResults: "No roles assigned for the employees.",
  AssignRoleSubHeaderRole: "Please assign a role to the employee.",

  QualificationMasterheaderValueNoResults:
    "List of qualifications for employees is not created yet.",
  QualificationMasterSubHeaderRole:
    "Please create a list of qualification so that employees can easily choose their qualifications while completing their registrations.",

  DesignationheaderValueNoResults:
    "Designations Master list for employees is not created.",
  DesignationSubHeaderRole:
    "Please create a list of designations available in your organization so that you can assign the designations to employees while onboarding the employees.",

  AllocateLeavesheaderValueNoResults:
    "You have not allocated any leaves to the employees",
  AllocateLeavesSubHeaderRole:
    "Please allocate leaves to the employees so that they can apply for leaves from their leave balances. Like, you can allocate 3 sick leaves to an employee and they will be able to use it while taking the leaves.",

  ApplyLeavesheaderValueNoResults: "You have not applied for any leaves yet.",
  ApplyLeavesSubHeaderRole:
    "It is always a good practice to apply for a leave in advance to avail it with approvals.",

  ApproveLeavesheaderValueNoResults:
    "There are no leaves PENDING your approvals.",
  ApproveLeavesSubHeaderRole:
    "Once the employee applies for any leave, they will come for approval and can be APPROVED here.",

  ManageLeaveheaderValueNoResults: "No Leave Types added in the system.",
  ManageLeaveSubHeaderRole:
    "Please add leave types like Sick Leave, Casual Leaves etc so that you can assign some leave balances for each type of leave to the employees. Leave balances will tie to the type of leave for which they are allocated.",

  ManageVisitorheaderValueNoResults: "No Visitor Types added in the system.",
  ManageVisitorSubHeaderRole: "Please add visitor type",

  ManageEventheaderValueNoResults: "No Events added in the system.",
  ManageEventSubHeaderRole: "Please add Event",

  ManageDocumentTempalteNoResult:'No Document Template added in the system',
  ManageDocumetTempalateSubHeaderRole:`Document templates can be used to send one document to mulitiple recipients. e.g. Offer letter, Appointment letter etc. customized with their name and other details. Use your choice of placeholders like {name},{email} etc. in the document template to replace the recipient data while sending the document. You will be asked for this information while sending the document.`,
  ManageDocumentTempalteHistoryNoResult:'No Document History added in the system',

  ManageMyDocumentNoResult:'No Document  added in the system',
  ManageMyDocumetSubHeaderRole:'No Document found',
  ManageMyDocumentHistoryNoResult:'No Document added in the system',

  ManageSkillsNoResult:'No skill added in the system',
  ManageSkillsSubHeaderRole:'No skill found',
  

  ManageEventCoordinatorsheaderValueNoResults: "No Events Coordinators added in the system.",
  ManageEventCoordinatorsSubHeaderRole: "Please add Event Coordinator",

  AssetLocationheaderValueNoResults: "No asset on this office location in the system.",
  AssetLocationSubHeaderRole: "Please asset on this office location",

  AssetAssignheaderValueNoResults: "No Asset Assign in the system.",
  AssetAssignSubHeaderRole: "Please Asset Assign",

  OfficeLocationMovementValueNoResults: "No Office Location in the Asset.",
  OfficeLocationMovementSubHeaderRole: "Please Add Office Location",

  SubscriptionDetailsheaderValueNoResults: "No Subscription Details in the system.",
  SubscriptionDetailsSubHeaderRole: "Please Add Subscription Details",

  DocumentsUploadheaderValueNoResults: "No Documents upload in the system.",
  DocumentsUploadSubHeaderRole: "Please upload Documents",

  TicketReplyheaderValueNoResults: "No Replies in the system.",
  TicektReplySubHeaderRole: "Please give some reply",

  ManageEventAttributesheaderValueNoResults: "No Events Attributes added in the system.",
  ManageEventAttributesSubHeaderRole: "Please add Event Attributes",

  ManageEventVisitorheaderValueNoResults: "No Events Visitors added in the system.",
  ManageEventVisitorsSubHeaderRole: "Please add Event Visitors",

  TeamTaskheaderValueNoResults:
    "No high level tasks are defined for the project.",
  TeamTaskSubHeaderRole:
    "Please define high level tasks for the project so that team can use these to fill the timesheets.",

  ManageKRAheaderValueNoResults: "No Key Rating Areas defined.",
  ManageKRASubHeaderRole:
    "Please define some KRA (Key Rating areas) so that you can assign KRA to the employees and rate their performance. e.g. Train the interns - Onboard the interns and guide them to make them productive in their everyday work and be successful in their jobs.",

  PerformanceAssessmentheaderValueNoResults:
    "No Employee Performance Assessments done.",
  PerformanceAssessmentSubHeaderRole:
    "It is critical to evaluate and assess the performance of employees from time to time. This helps them to get the feedback on their work and improve everyday at work.",

  PredefinedSalaryStructuresheaderValueNoResults:
    "No salary structures defined.",
  PredefinedSalaryStructuresSubHeaderRole:
    "When salary structures are defined, they act as templates to allocate the salary components while defining the salary breakup for the employees. You will be able to reuse each of these salary structures as template while giving the salary breakup for the newly joined employees.",

  EarningheaderValueNoResults: "Earnings are not defined",
  EarningSubHeaderRole:
    "Salary components which are earnings for the employees like Basic Pay are defined in this section.",

  DeductionheaderValueNoResults: "Deductions are not defined",
  DeductionSubHeaderRole:
    "Salary components which are deductions for the employees like Tax deduct at Source are defined in this section.",

  FeedEmployeeSalaryheaderValueNoResults:
    "No salary is entered for the employees",
  FeedEmployeeSalarySubHeaderRole:
    "Employees are offered a compensation for the work that they do. This section helps the HR to feed the compensation paid to the employee in the system.",

  EmployeePayslipsheaderValueNoResults:
    "You have not added the payslip for the employees.",
  EmployeePayslipsSubHeaderRole:
    "Please add a payslip for the employee while making payment and publish the payslip so that employees can download them as and when needed.",

  EmployeeDeleteConfirmationMsg : "Employee Deleted successfully",

  AttendanceReportheaderValueNoResults: "No attendance records found",
  AttendanceReportSubHeaderRole:
    "Attendance report is generated when the employee does check in and check outs. Attendance reports gives a quick insight to the employee attendance.",

  ManageRolesheaderValueNoResults: "No roles are added yet.",
  ManageRolesSubHeaderRole:
    "Role is a security group to which you can add permissions for various actions within the system. Create a Role (Security Group) and add permissions.",

  ManagePermissionsheaderValueNoResults: "No permissions assigned.",
  ManagePermissionsSubHeaderRole:
    "Please assign some permissions to the Role (Security Group).  A role without any permissions is not operational.",

  AssignMenuRoleheaderValueNoResults: "No menus assigned to role.",
  AssignMenuRoleSubHeaderRole:
    "Please assign some menus to roles (security groups) so that menus are visible for the employees assigned to this role.",
  OfficeLocationAddRecord: "Office address Successfully Added !",
  OfficeLocationUpdateRecord: "Office address Successfully Updated !",
  InvalidVisitor : "Invalid visitor",
  //#region TimeFrame Options
  timeFrame: {
    Week: "Week",
    Semimonth: "Semimonth",
    Month: "Month",
    Quarter: "Quarter",
    Year: "Year",
    //AllTime   : "All time",
    Custom: "Custom",
  },
  //#endregion

  //#region TimeFrame Options
  timeFrameForLeaves: {
    // Week: "Week",
    // Semimonth: "Semimonth",
    Month: "Month",
    Quarter: "Quarter",
    Year: "Year",
    //AllTime   : "All time",
    // Custom: "Custom",
  },
  //#endregion

  //#region Subscription preiod
  subscriptionPeriod: {
    Monthly: { Month: 1, Name: "Monthly" },
    Quarterly: { Month: 3, Name: "Quarterly" },
    HalfYearly: { Month: 6, Name: "Half Yearly" },
    Yearly: { Month: 12, Name: "Yearly" },
  },
  //#endregion
  //No Results found message details End
  //#region Company Type
  companyTypes: [
    {
      value: "Sole Proprietorship",
      text: "Sole Proprietorship",
    },
    {
      value: "Partnership",
      text: "Partnership",
    },
    {
      value: "Pvt Ltd",
      text: "Pvt Ltd",
    },
    {
      value: "Public",
      text: "Public",
    },
  ],
  //#endregion
  //#region Color
  wsSecondaryColor: "#ABD3F9",
  wsPrimaryColor: "#1976D2",
  wsGreyColor: "#9E9E9E",
  wsBillableColor: "#0092F0",
  wsNonBillableColor: "#ABD3F9",
  wsBillableAlertColor: "#F30E30",
  wsNonBillableAlertColor: "#FF9A9F",

  //#endregion

  //#region WeekDays

  weekDays : [
    {
        value: 1,
        text: "Monday",
        ShortCode: "M"
    },
    {
        value: 2,
        text: "Tuesday",
        ShortCode: "T",
    },
    {
        value: 3,
        text: "Wednesday",
        ShortCode: "W",
    },
    {
        value: 4,
        text: "Thursday",
        ShortCode: "Th",
    },
    {
        value: 5,
        text: "Friday",
        ShortCode: "F",
    },
    {
        value: 6,
        text: "Saturday",
        ShortCode: "Sa"
    },
    {
        value: 7,
        text: "Sunday",
        ShortCode: "Su"
    }
  ],
  //#endregion

  //#region TimeSpan

  timeSpan: [
    {
      value: "Fixed",
      text: "Fixed",
    },
    {
      value: "Per Hour",
      text: "Per Hour",
    },
  ],
  //#endregion
  //#region TimeSpan

  billTypes: [
    {
      value: 1,
      text: "Billable",
    },
    {
      value: 0,
      text: "Non-Billable",
    },
  ],
  //#endregion

  //#region TimeSpan

  Relationship: [
    {
      value: "Married",
      text: "Married",
    },
    {
      value: "UnMarried",
      text: "UnMarried",
    },
  ],
  //#endregion

  Colors: {
    primary: "#0D6EFD",
    warning: "#f4c414",
    danger: "#f54394",
    success: "#08b97b",
    info: "#2196f3",
    grey: "#A4A4A4",
  },
  GraphColors: {
    green: "#00C49F",
    blue: "#0088FE",
    red: "#FF6879",
    yellow: "#F4C414",
    orange: "#FF8042",
    info: "#2196f3",
    grey: "#A4A4A4",
    lightGrey: "#CFCFCF",
  },
  EmailProtocols: [
    {
      value: "SMTP",
      text: "SMTP",
    },
    {
      value: "IMAP",
      text: "IMAP",
    },
    {
      value: "POP3",
      text: "POP3",
    },
  ],

  TeamSize: [
    {
      value: "1-50",
      text: "1-50",
    },
    {
      value: "51-100",
      text: "51-100",
    },
    {
      value: "101-200",
      text: "101-200",
    },
    {
      value: "201-500",
      text: "201-500",
    },
    {
      value: "501-1000",
      text: "501-1000",
    },
    {
      value: "1000+",
      text: "1000+",
    },
  ],
  //#region Countries
  Countries: [
    {
      CountryId: 1,
      Country: "India",
      CountryCode: "IN",
    },
    {
      CountryId: 3,
      Country: "Afghanistan",
      CountryCode: "AF",
    },
    {
      CountryId: 4,
      Country: "Albania",
      CountryCode: "AL",
    },
    {
      CountryId: 5,
      Country: "Algeria",
      CountryCode: "DZ",
    },
    {
      CountryId: 6,
      Country: "American Samoa",
      CountryCode: "DS",
    },
    {
      CountryId: 7,
      Country: "Andorra",
      CountryCode: "AD",
    },
    {
      CountryId: 8,
      Country: "Angola",
      CountryCode: "AO",
    },
    {
      CountryId: 9,
      Country: "Anguilla",
      CountryCode: "AI",
    },
    {
      CountryId: 10,
      Country: "Antarctica",
      CountryCode: "AQ",
    },
    {
      CountryId: 11,
      Country: "Antigua and Barbuda",
      CountryCode: "AG",
    },
    {
      CountryId: 12,
      Country: "Argentina",
      CountryCode: "AR",
    },
    {
      CountryId: 13,
      Country: "Armenia",
      CountryCode: "AM",
    },
    {
      CountryId: 14,
      Country: "Aruba",
      CountryCode: "AW",
    },
    {
      CountryId: 15,
      Country: "Australia",
      CountryCode: "AU",
    },
    {
      CountryId: 16,
      Country: "Austria",
      CountryCode: "AT",
    },
    {
      CountryId: 17,
      Country: "Azerbaijan",
      CountryCode: "AZ",
    },
    {
      CountryId: 18,
      Country: "Bahamas",
      CountryCode: "BS",
    },
    {
      CountryId: 19,
      Country: "Bahrain",
      CountryCode: "BH",
    },
    {
      CountryId: 20,
      Country: "Bangladesh",
      CountryCode: "BD",
    },
    {
      CountryId: 21,
      Country: "Barbados",
      CountryCode: "BB",
    },
    {
      CountryId: 22,
      Country: "Belarus",
      CountryCode: "BY",
    },
    {
      CountryId: 23,
      Country: "Belgium",
      CountryCode: "BE",
    },
    {
      CountryId: 24,
      Country: "Belize",
      CountryCode: "BZ",
    },
    {
      CountryId: 25,
      Country: "Benin",
      CountryCode: "BJ",
    },
    {
      CountryId: 26,
      Country: "Bermuda",
      CountryCode: "BM",
    },
    {
      CountryId: 27,
      Country: "Bhutan",
      CountryCode: "BT",
    },
    {
      CountryId: 28,
      Country: "Bolivia",
      CountryCode: "BO",
    },
    {
      CountryId: 29,
      Country: "Bosnia and Herzegovina",
      CountryCode: "BA",
    },
    {
      CountryId: 30,
      Country: "Botswana",
      CountryCode: "BW",
    },
    {
      CountryId: 31,
      Country: "Bouvet Island",
      CountryCode: "BV",
    },
    {
      CountryId: 32,
      Country: "Brazil",
      CountryCode: "BR",
    },
    {
      CountryId: 33,
      Country: "British Indian Ocean Territory",
      CountryCode: "IO",
    },
    {
      CountryId: 34,
      Country: "Brunei Darussalam",
      CountryCode: "BN",
    },
    {
      CountryId: 35,
      Country: "Bulgaria",
      CountryCode: "BG",
    },
    {
      CountryId: 36,
      Country: "Burkina Faso",
      CountryCode: "BF",
    },
    {
      CountryId: 37,
      Country: "Burundi",
      CountryCode: "BI",
    },
    {
      CountryId: 38,
      Country: "Cambodia",
      CountryCode: "KH",
    },
    {
      CountryId: 39,
      Country: "Cameroon",
      CountryCode: "CM",
    },
    {
      CountryId: 40,
      Country: "Canada",
      CountryCode: "CA",
    },
    {
      CountryId: 41,
      Country: "Cape Verde",
      CountryCode: "CV",
    },
    {
      CountryId: 42,
      Country: "Cayman Islands",
      CountryCode: "KY",
    },
    {
      CountryId: 43,
      Country: "Central African Republic",
      CountryCode: "CF",
    },
    {
      CountryId: 44,
      Country: "Chad",
      CountryCode: "TD",
    },
    {
      CountryId: 45,
      Country: "Chile",
      CountryCode: "CL",
    },
    {
      CountryId: 46,
      Country: "China",
      CountryCode: "CN",
    },
    {
      CountryId: 47,
      Country: "Christmas Island",
      CountryCode: "CX",
    },
    {
      CountryId: 48,
      Country: "Cocos (Keeling) Islands",
      CountryCode: "CC",
    },
    {
      CountryId: 49,
      Country: "Colombia",
      CountryCode: "CO",
    },
    {
      CountryId: 50,
      Country: "Comoros",
      CountryCode: "KM",
    },
    {
      CountryId: 51,
      Country: "Democratic Republic of the Congo",
      CountryCode: "CD",
    },
    {
      CountryId: 52,
      Country: "Republic of Congo",
      CountryCode: "CG",
    },
    {
      CountryId: 53,
      Country: "Cook Islands",
      CountryCode: "CK",
    },
    {
      CountryId: 54,
      Country: "Costa Rica",
      CountryCode: "CR",
    },
    {
      CountryId: 55,
      Country: "Croatia (Hrvatska)",
      CountryCode: "HR",
    },
    {
      CountryId: 56,
      Country: "Cuba",
      CountryCode: "CU",
    },
    {
      CountryId: 57,
      Country: "Cyprus",
      CountryCode: "CY",
    },
    {
      CountryId: 58,
      Country: "Czech Republic",
      CountryCode: "CZ",
    },
    {
      CountryId: 59,
      Country: "Denmark",
      CountryCode: "DK",
    },
    {
      CountryId: 60,
      Country: "Djibouti",
      CountryCode: "DJ",
    },
    {
      CountryId: 61,
      Country: "Dominica",
      CountryCode: "DM",
    },
    {
      CountryId: 62,
      Country: "Dominican Republic",
      CountryCode: "DO",
    },
    {
      CountryId: 63,
      Country: "East Timor",
      CountryCode: "TP",
    },
    {
      CountryId: 64,
      Country: "Ecuador",
      CountryCode: "EC",
    },
    {
      CountryId: 65,
      Country: "Egypt",
      CountryCode: "EG",
    },
    {
      CountryId: 66,
      Country: "El Salvador",
      CountryCode: "SV",
    },
    {
      CountryId: 67,
      Country: "Equatorial Guinea",
      CountryCode: "GQ",
    },
    {
      CountryId: 68,
      Country: "Eritrea",
      CountryCode: "ER",
    },
    {
      CountryId: 69,
      Country: "Estonia",
      CountryCode: "EE",
    },
    {
      CountryId: 70,
      Country: "Ethiopia",
      CountryCode: "ET",
    },
    {
      CountryId: 71,
      Country: "Falkland Islands (Islas Malvinas)",
      CountryCode: "FK",
    },
    {
      CountryId: 72,
      Country: "Faroe Islands",
      CountryCode: "FO",
    },
    {
      CountryId: 73,
      Country: "Fiji",
      CountryCode: "FJ",
    },
    {
      CountryId: 74,
      Country: "Finland",
      CountryCode: "FI",
    },
    {
      CountryId: 75,
      Country: "France",
      CountryCode: "FR",
    },
    {
      CountryId: 76,
      Country: "France, Metropolitan",
      CountryCode: "FX",
    },
    {
      CountryId: 77,
      Country: "French Guiana",
      CountryCode: "GF",
    },
    {
      CountryId: 78,
      Country: "French Polynesia",
      CountryCode: "PF",
    },
    {
      CountryId: 79,
      Country: "French Southern Territories",
      CountryCode: "TF",
    },
    {
      CountryId: 80,
      Country: "Gabon",
      CountryCode: "GA",
    },
    {
      CountryId: 81,
      Country: "Gambia",
      CountryCode: "GM",
    },
    {
      CountryId: 82,
      Country: "Georgia",
      CountryCode: "GE",
    },
    {
      CountryId: 83,
      Country: "Germany",
      CountryCode: "DE",
    },
    {
      CountryId: 84,
      Country: "Ghana",
      CountryCode: "GH",
    },
    {
      CountryId: 85,
      Country: "Gibraltar",
      CountryCode: "GI",
    },
    {
      CountryId: 86,
      Country: "Guernsey",
      CountryCode: "GK",
    },
    {
      CountryId: 87,
      Country: "Greece",
      CountryCode: "GR",
    },
    {
      CountryId: 88,
      Country: "Greenland",
      CountryCode: "GL",
    },
    {
      CountryId: 89,
      Country: "Grenada",
      CountryCode: "GD",
    },
    {
      CountryId: 90,
      Country: "Guadeloupe",
      CountryCode: "GP",
    },
    {
      CountryId: 91,
      Country: "Guam",
      CountryCode: "GU",
    },
    {
      CountryId: 92,
      Country: "Guatemala",
      CountryCode: "GT",
    },
    {
      CountryId: 93,
      Country: "Guinea",
      CountryCode: "GN",
    },
    {
      CountryId: 94,
      Country: "Guinea-Bissau",
      CountryCode: "GW",
    },
    {
      CountryId: 95,
      Country: "Guyana",
      CountryCode: "GY",
    },
    {
      CountryId: 96,
      Country: "Haiti",
      CountryCode: "HT",
    },
    {
      CountryId: 97,
      Country: "Heard and Mc Donald Islands",
      CountryCode: "HM",
    },
    {
      CountryId: 98,
      Country: "Honduras",
      CountryCode: "HN",
    },
    {
      CountryId: 99,
      Country: "Hong Kong",
      CountryCode: "HK",
    },
    {
      CountryId: 100,
      Country: "Hungary",
      CountryCode: "HU",
    },
    {
      CountryId: 101,
      Country: "Iceland",
      CountryCode: "IS",
    },
    {
      CountryId: 103,
      Country: "Isle of Man",
      CountryCode: "IM",
    },
    {
      CountryId: 104,
      Country: "Indonesia",
      CountryCode: "ID",
    },
    {
      CountryId: 105,
      Country: "Iran",
      CountryCode: "IR",
    },
    {
      CountryId: 106,
      Country: "Iraq",
      CountryCode: "IQ",
    },
    {
      CountryId: 107,
      Country: "Ireland",
      CountryCode: "IE",
    },
    {
      CountryId: 108,
      Country: "Israel",
      CountryCode: "IL",
    },
    {
      CountryId: 109,
      Country: "Italy",
      CountryCode: "IT",
    },
    {
      CountryId: 110,
      Country: "Ivory Coast",
      CountryCode: "CI",
    },
    {
      CountryId: 111,
      Country: "Jersey",
      CountryCode: "JE",
    },
    {
      CountryId: 112,
      Country: "Jamaica",
      CountryCode: "JM",
    },
    {
      CountryId: 113,
      Country: "Japan",
      CountryCode: "JP",
    },
    {
      CountryId: 114,
      Country: "Jordan",
      CountryCode: "JO",
    },
    {
      CountryId: 115,
      Country: "Kazakhstan",
      CountryCode: "KZ",
    },
    {
      CountryId: 116,
      Country: "Kenya",
      CountryCode: "KE",
    },
    {
      CountryId: 117,
      Country: "Kiribati",
      CountryCode: "KI",
    },
    {
      CountryId: 118,
      Country: "North Korea",
      CountryCode: "KN",
    },
    {
      CountryId: 119,
      Country: "South Korea",
      CountryCode: "KS",
    },
    {
      CountryId: 120,
      Country: "Kosovo",
      CountryCode: "XK",
    },
    {
      CountryId: 121,
      Country: "Kuwait",
      CountryCode: "KW",
    },
    {
      CountryId: 122,
      Country: "Kyrgyzstan",
      CountryCode: "KG",
    },
    {
      CountryId: 123,
      Country: "Laos",
      CountryCode: "LA",
    },
    {
      CountryId: 124,
      Country: "Latvia",
      CountryCode: "LV",
    },
    {
      CountryId: 125,
      Country: "Lebanon",
      CountryCode: "LB",
    },
    {
      CountryId: 126,
      Country: "Lesotho",
      CountryCode: "LS",
    },
    {
      CountryId: 127,
      Country: "Liberia",
      CountryCode: "LR",
    },
    {
      CountryId: 128,
      Country: "Libyan Arab Jamahiriya",
      CountryCode: "LY",
    },
    {
      CountryId: 129,
      Country: "Liechtenstein",
      CountryCode: "LI",
    },
    {
      CountryId: 130,
      Country: "Lithuania",
      CountryCode: "LT",
    },
    {
      CountryId: 131,
      Country: "Luxembourg",
      CountryCode: "LU",
    },
    {
      CountryId: 132,
      Country: "Macau",
      CountryCode: "MO",
    },
    {
      CountryId: 133,
      Country: "North Macedonia",
      CountryCode: "MK",
    },
    {
      CountryId: 134,
      Country: "Madagascar",
      CountryCode: "MG",
    },
    {
      CountryId: 135,
      Country: "Malawi",
      CountryCode: "MW",
    },
    {
      CountryId: 136,
      Country: "Malaysia",
      CountryCode: "MY",
    },
    {
      CountryId: 137,
      Country: "Maldives",
      CountryCode: "MV",
    },
    {
      CountryId: 138,
      Country: "Mali",
      CountryCode: "ML",
    },
    {
      CountryId: 139,
      Country: "Malta",
      CountryCode: "MT",
    },
    {
      CountryId: 140,
      Country: "Marshall Islands",
      CountryCode: "MH",
    },
    {
      CountryId: 141,
      Country: "Martinique",
      CountryCode: "MQ",
    },
    {
      CountryId: 142,
      Country: "Mauritania",
      CountryCode: "MR",
    },
    {
      CountryId: 143,
      Country: "Mauritius",
      CountryCode: "MU",
    },
    {
      CountryId: 144,
      Country: "Mayotte",
      CountryCode: "TY",
    },
    {
      CountryId: 145,
      Country: "Mexico",
      CountryCode: "MX",
    },
    {
      CountryId: 146,
      Country: "Micronesia, Federated States of",
      CountryCode: "FM",
    },
    {
      CountryId: 147,
      Country: "Moldova, Republic of",
      CountryCode: "MD",
    },
    {
      CountryId: 148,
      Country: "Monaco",
      CountryCode: "MC",
    },
    {
      CountryId: 149,
      Country: "Mongolia",
      CountryCode: "MN",
    },
    {
      CountryId: 150,
      Country: "Montenegro",
      CountryCode: "ME",
    },
    {
      CountryId: 151,
      Country: "Montserrat",
      CountryCode: "MS",
    },
    {
      CountryId: 152,
      Country: "Morocco",
      CountryCode: "MA",
    },
    {
      CountryId: 153,
      Country: "Mozambique",
      CountryCode: "MZ",
    },
    {
      CountryId: 154,
      Country: "Myanmar",
      CountryCode: "MM",
    },
    {
      CountryId: 155,
      Country: "Namibia",
      CountryCode: "NA",
    },
    {
      CountryId: 156,
      Country: "Nauru",
      CountryCode: "NR",
    },
    {
      CountryId: 157,
      Country: "Nepal",
      CountryCode: "NP",
    },
    {
      CountryId: 158,
      Country: "Netherlands",
      CountryCode: "NL",
    },
    {
      CountryId: 159,
      Country: "Netherlands Antilles",
      CountryCode: "AN",
    },
    {
      CountryId: 160,
      Country: "New Caledonia",
      CountryCode: "NC",
    },
    {
      CountryId: 161,
      Country: "New Zealand",
      CountryCode: "NZ",
    },
    {
      CountryId: 162,
      Country: "Nicaragua",
      CountryCode: "NI",
    },
    {
      CountryId: 163,
      Country: "Niger",
      CountryCode: "NE",
    },
    {
      CountryId: 164,
      Country: "Nigeria",
      CountryCode: "NG",
    },
    {
      CountryId: 165,
      Country: "Niue",
      CountryCode: "NU",
    },
    {
      CountryId: 166,
      Country: "Norfolk Island",
      CountryCode: "NF",
    },
    {
      CountryId: 167,
      Country: "Northern Mariana Islands",
      CountryCode: "MP",
    },
    {
      CountryId: 168,
      Country: "Norway",
      CountryCode: "NO",
    },
    {
      CountryId: 169,
      Country: "Oman",
      CountryCode: "OM",
    },
    {
      CountryId: 170,
      Country: "Pakistan",
      CountryCode: "PK",
    },
    {
      CountryId: 171,
      Country: "Palau",
      CountryCode: "PW",
    },
    {
      CountryId: 172,
      Country: "Palestine",
      CountryCode: "PS",
    },
    {
      CountryId: 173,
      Country: "Panama",
      CountryCode: "PA",
    },
    {
      CountryId: 174,
      Country: "Papua New Guinea",
      CountryCode: "PG",
    },
    {
      CountryId: 175,
      Country: "Paraguay",
      CountryCode: "PY",
    },
    {
      CountryId: 176,
      Country: "Peru",
      CountryCode: "PE",
    },
    {
      CountryId: 177,
      Country: "Philippines",
      CountryCode: "PH",
    },
    {
      CountryId: 178,
      Country: "Pitcairn",
      CountryCode: "PN",
    },
    {
      CountryId: 179,
      Country: "Poland",
      CountryCode: "PL",
    },
    {
      CountryId: 180,
      Country: "Portugal",
      CountryCode: "PT",
    },
    {
      CountryId: 181,
      Country: "Puerto Rico",
      CountryCode: "PR",
    },
    {
      CountryId: 182,
      Country: "Qatar",
      CountryCode: "QA",
    },
    {
      CountryId: 183,
      Country: "Reunion",
      CountryCode: "RE",
    },
    {
      CountryId: 184,
      Country: "Romania",
      CountryCode: "RO",
    },
    {
      CountryId: 185,
      Country: "Russian (Federation)",
      CountryCode: "RU",
    },
    {
      CountryId: 186,
      Country: "Rwanda",
      CountryCode: "RW",
    },
    {
      CountryId: 187,
      Country: "Saint Kitts and Nevis",
      CountryCode: "KN",
    },
    {
      CountryId: 188,
      Country: "Saint Lucia",
      CountryCode: "LC",
    },
    {
      CountryId: 189,
      Country: "Saint Vincent and the Grenadines",
      CountryCode: "VC",
    },
    {
      CountryId: 190,
      Country: "Samoa",
      CountryCode: "WS",
    },
    {
      CountryId: 191,
      Country: "San Marino",
      CountryCode: "SM",
    },
    {
      CountryId: 192,
      Country: "Sao Tome and Principe",
      CountryCode: "ST",
    },
    {
      CountryId: 193,
      Country: "Saudi Arabia",
      CountryCode: "SA",
    },
    {
      CountryId: 194,
      Country: "Senegal",
      CountryCode: "SN",
    },
    {
      CountryId: 195,
      Country: "Serbia",
      CountryCode: "RS",
    },
    {
      CountryId: 196,
      Country: "Seychelles",
      CountryCode: "SC",
    },
    {
      CountryId: 197,
      Country: "Sierra Leone",
      CountryCode: "SL",
    },
    {
      CountryId: 198,
      Country: "Singapore",
      CountryCode: "SG",
    },
    {
      CountryId: 199,
      Country: "Slovakia",
      CountryCode: "SK",
    },
    {
      CountryId: 200,
      Country: "Slovenia",
      CountryCode: "SI",
    },
    {
      CountryId: 201,
      Country: "Solomon Islands",
      CountryCode: "SB",
    },
    {
      CountryId: 202,
      Country: "Somalia",
      CountryCode: "SO",
    },
    {
      CountryId: 203,
      Country: "South Africa",
      CountryCode: "ZA",
    },
    {
      CountryId: 204,
      Country: "South Georgia South Sandwich Islands",
      CountryCode: "GS",
    },
    {
      CountryId: 205,
      Country: "South Sudan",
      CountryCode: "SS",
    },
    {
      CountryId: 206,
      Country: "Spain",
      CountryCode: "ES",
    },
    {
      CountryId: 207,
      Country: "Sri Lanka",
      CountryCode: "LK",
    },
    {
      CountryId: 209,
      Country: "St. Pierre and Miquelon",
      CountryCode: "PM",
    },
    {
      CountryId: 210,
      Country: "Sudan",
      CountryCode: "SD",
    },
    {
      CountryId: 211,
      Country: "Suriname",
      CountryCode: "SR",
    },
    {
      CountryId: 212,
      Country: "Svalbard and Jan Mayen Islands",
      CountryCode: "SJ",
    },
    {
      CountryId: 213,
      Country: "Swaziland",
      CountryCode: "SZ",
    },
    {
      CountryId: 214,
      Country: "Sweden",
      CountryCode: "SE",
    },
    {
      CountryId: 215,
      Country: "Switzerland",
      CountryCode: "CH",
    },
    {
      CountryId: 216,
      Country: "Syria (Syrian Arab Republic)",
      CountryCode: "SY",
    },
    {
      CountryId: 217,
      Country: "Taiwan",
      CountryCode: "TW",
    },
    {
      CountryId: 218,
      Country: "Tajikistan",
      CountryCode: "TJ",
    },
    {
      CountryId: 219,
      Country: "Tanzania, United Republic of",
      CountryCode: "TZ",
    },
    {
      CountryId: 220,
      Country: "Thailand",
      CountryCode: "TH",
    },
    {
      CountryId: 221,
      Country: "Togo",
      CountryCode: "TG",
    },
    {
      CountryId: 222,
      Country: "Tokelau",
      CountryCode: "TK",
    },
    {
      CountryId: 223,
      Country: "Tonga",
      CountryCode: "TO",
    },
    {
      CountryId: 224,
      Country: "Trinidad and Tobago",
      CountryCode: "TT",
    },
    {
      CountryId: 225,
      Country: "Tunisia",
      CountryCode: "TN",
    },
    {
      CountryId: 226,
      Country: "Turkey",
      CountryCode: "TR",
    },
    {
      CountryId: 227,
      Country: "Turkmenistan",
      CountryCode: "TM",
    },
    {
      CountryId: 228,
      Country: "Turks and Caicos Islands",
      CountryCode: "TC",
    },
    {
      CountryId: 229,
      Country: "Tuvalu",
      CountryCode: "TV",
    },
    {
      CountryId: 230,
      Country: "Uganda",
      CountryCode: "UG",
    },
    {
      CountryId: 231,
      Country: "Ukraine",
      CountryCode: "UA",
    },
    {
      CountryId: 232,
      Country: "United Arab Emirates",
      CountryCode: "AE",
    },
    {
      CountryId: 233,
      Country: "United Kingdom",
      CountryCode: "GB",
    },
    {
      CountryId: 234,
      Country: "United States of America",
      CountryCode: "US",
    },
    {
      CountryId: 235,
      Country: "United States minor outlying islands",
      CountryCode: "UM",
    },
    {
      CountryId: 236,
      Country: "Uruguay",
      CountryCode: "UY",
    },
    {
      CountryId: 237,
      Country: "Uzbekistan",
      CountryCode: "UZ",
    },
    {
      CountryId: 238,
      Country: "Vanuatu",
      CountryCode: "VU",
    },
    {
      CountryId: 239,
      Country: "Vatican City",
      CountryCode: "VA",
    },
    {
      CountryId: 240,
      Country: "Venezuela",
      CountryCode: "VE",
    },
    {
      CountryId: 241,
      Country: "Vietnam",
      CountryCode: "VN",
    },
    {
      CountryId: 242,
      Country: "Virgin Islands (British)",
      CountryCode: "VG",
    },
    {
      CountryId: 243,
      Country: "Virgin Islands (U.S.)",
      CountryCode: "VI",
    },
    {
      CountryId: 244,
      Country: "Wallis and Futuna Islands",
      CountryCode: "WF",
    },
    {
      CountryId: 245,
      Country: "Western Sahara",
      CountryCode: "EH",
    },
    {
      CountryId: 246,
      Country: "Yemen",
      CountryCode: "YE",
    },
    {
      CountryId: 247,
      Country: "Zambia",
      CountryCode: "ZM",
    },
    {
      CountryId: 248,
      Country: "Zimbabwe",
      CountryCode: "ZW",
    },
    {
      CountryId: 249,
      Country: "East Caribbean",
      CountryCode: "EC",
    },
    {
      CountryId: 496,
      Country: "Macedonia",
      CountryCode: "MA",
    },
    {
      CountryId: 497,
      Country: "Saint Helena",
      CountryCode: "SH",
    },
  ],
  //#endregion
  //#region Trigger
  TriggerMaster: [
    { Trigger: 1, Action: "Approve Leave" },
    { Trigger: 2, Action: "View" },
    { Trigger: 3, Action: "View Reason" },
    { Trigger: 4, Action: "Approve Employee" },
  ],
  ProjectStatus: [
    { value: 1, text: "To be started" },
    { value: 2, text: "In progress" },
    { value: 3, text: "Completed" },
    { value: 4, text: "Cancelled" },
  ],
  VisitorRegistration: [
    { value: 0, text: "Only Public Facilities Access" },
    { value: 1, text: "All Public and Restricted areas" },
    { value: 2, text: "High Security Zone" },
  ],

  ComparisonOperators : [
    { value: "==", text: "==" },
    { value: "!=", text: "!=" },
    { value: ">", text: ">" },
    { value: "<", text: "<" },
    { value: ">=", text: ">=" },
    { value: "<=", text: "<=" },
  ],

  LogicalOperators:[
    { value: "AND", text: "AND" },
    { value: "OR", text: "OR" },
  ],

  FormulasType: {
    EquationFormula : 'EquationFormula',
    AggregateFunction : 'AggregateFunction',
    AssignmentFunction : 'AssignmentFunction'
  },

  TypeOfFormulasList:[
    { value: "EquationFormula", text: "Equation Formula" },
    { value: "AggregateFunction", text: "Aggregate Function" },
    { value: "AssignmentFunction", text: "Assignment Function" },
  ],

  EquationFormulaList:[
    {value: "+", text: "+"},
    {value: "-", text: "-"},
    {value: "*", text: "*"},
    {value: "/", text: "/"},
  ],

  AggregateFunctionList:[
    {value: "SUM", text: "Sum of"},
    {value: "AVG", text: "Avg of"}
  ],

  InvoiceSectionList:[
    {value: "DetailsSection", text: "DetailsSection"},
    {value: "BottomSection", text: "BottomSection"},
  ],

  InvoiceSection : {
    DetailsSection : "DetailsSection",
    BottomSection : "BottomSection",
  },

  InvoiceSectionNames : {
    Company : "Company",
    Customer : "Customer",
    Details : "Details",
    Bottom : "Bottom",
    GeneralSettings : "GeneralSettings"
  },
  
  // Rename to CompanyLogoHTMLKey
  InvoiceLogoUrlKeyName : {
    CompanyLogo: "CompanyLogo"
  },

  ArrowClicked :{
    Up : "UP",
    Down : "DOWN"
  },

  FormulaBoxFilledFieldType :{
    DbField : "DbField",
    CalculatedField : "CalculatedField"
  },

  BottomSectionFixedFieldName : {
    TotalAmount : "TotalAmount",
    TaxAmount : "TaxAmount",
    NetAmount : "NetAmount"
  },
  
  DetailsHeadingSuffix : {
    Heading : "Heading"
  },

  //#endregion

  CaptureYourPhoto: "Capture Your Photo",
  CapturePicture: "Capture Picture",

  UploadFolderPaths: {
    uploads: "/uploads",
    userPlaceholderImg: "/assets/user_placeholder.jpg",
    Biometrics: "/uploads/Biometrics",
  },

  AcceptExtensionsInUploads : {
    InvoiceHtmlFileExtension : ".html, .htm",
    JobApplicantFileExtension: ".docx , .doc, .pdf"
  },


  ValidationMessages : {
    RequiredFieldMsg : "This field is required",
    InvalidCharMsg : "Character is not allowed",
    InvalidEmailMsg : "Please enter a valid email",
    PhoneNoLengthMsg : "Contact No should be 10 Digit",
    PhoneNoIsNotValid : "Phone number is not valid"
  },
  PhoneNumberValidateRegex: /^[+]?[0-9]{1,3}[.-]?[0-9]{1,14}$/i,

  ReturnPagePaths : {
    ActionCenter : "/ActionCenter",
  },
  APIMessage : {
    Success: "Success",
    Error: "Error"
  },
  Camera : {
    BackCamera : "environment",
    FrontCamera : "user"
  },
  AttendanceSummaryReportColors : {
    Leave: "red",
    CheckIn: "green",
    Holiday: "yellow",
    LeaveAndCheckIn: "black",
    CheckInAndHoliday: "brown",
    LeaveAndHoliday: "blue",
    LeaveAndCheckInAndHoliday: "pink",
    AllNotFound: "grey",
  },

  UserTypeAgent : [
    { value: "HelpDesk Agent", text: "HelpDesk Agent"}
  ],
  UploadFileSize : 2, // Number 2 is size of the file in MB

  TripStartTripEndErrMsgConstants : {
    TripStart : "TRIPSTART",
    TripEnd : "TRIPEND",
    OfficeLocationNotFound : "OFFICELOCATIONNOTFOUND",
    GuidNotFound : "GUIDNOTFOUND",
    ReportingInTime : "REPORTINGINTIME",
    ReportingOutTime : "REPORTINGOUTTIME",
    ReportingNotFound : "REPORTINGNOTFOUND",

  },
  AgentType: [
    {value: 1, text: "Employee Or User"},
    {value : 2, text: "External Person"}
  ],
  VehicleMaintenanceType : {
    Repair : 1,
    Maintenance : 2
  },
  
  TicketUserType: {
    Employee: 1,
    User: 2,
  },
  TicketRespnseType: {
    AddNote: 1,
    Reply: 2,
  },
  TicketResponseTypeString: {
    AddNote: "AddNote",
    Reply: "Reply"
  },
  NoDataForGraphs: "No Data",
  MUIAutocompleteReasonRemoveOption: "removeOption", // mui autocomplete reason standard constant
  MUIAutoCompleteRasonClearOption: "clear", /// mui autocomplete reason standard constant
  VehicleFuelFormErrMsg: "Odometer reading must be greater than last odometer reading",

  SubType: [
    { text: "UserBased", value: "UserBased"}
  ],

  Duration: [
    {text: "Yearly", value: "Yearly"},
    {text: "Quarterly", value: "Quarterly"},
    {text: "Monthly", value: "Monthly"},
  ],

  DiscountTypeRadio: {
    Fixed: "Fixed",
    Percentage: "Percentage",
  },

  OrderBy: {
    ASC: "asc",
    DESC: "desc",
  },
  AllCountries: "All",

  ExcludeFieldsFromExcelExport: ["Action", "EncodedId"],
  EmployeeCheckInCheckOutSuccessMsg : {
    CheckInMsg: "CheckInSuccess",
    CheckOutMsg: "CheckOutSuccess",
    EmployeeErrMsg: "EmployeeNotFound",
    ErrorMsg: "Error",
  },

  BilingMode: {
    FixedPerMonth: "Fixed Per Month",
    FixedPerWeek: "Fixed Per Week",
    PerHour: "Per Hour",
  },
  FormMode:{
    Add: "Add",
    Edit: "Edit",
    View: "View"
  },

  UserTypeId : {
    JobApplicant : 2,
  },

  JobLocationType : [
    {text : "In person", value : "In person"},
    {text : "Fully remote", value : "Fully remote"},
    {text : "On the road", value : "On the road"},
  ],

  HiringTimelineForJob : [
    {text : "1 to 3 days", value : "1 to 3 days"},
    {text : "3 to 7 days", value : "3 to 7 days"},
    {text : "1 to 2 weeks", value : "1 to 2 weeks"},
    {text : "2 to 4 weeks", value : "2 to 4 weeks"},
    {text : "More than 4 weeks", value : "More than 4 weeks"},
  ],

  SalaryRange : [
    {text : "Range", value : "Range"},
    {text : "Starting Salary", value : "Starting Salary"},
    {text : "Maximum Salary", value : "Maximum Salary"},
    {text : "Exact Salary", value : "Exact Salary"},
  ],

  JobsRate : [
    {text : "per hour", value : "per hour"},
    {text : "per day", value : "per day"},
    {text : "per week", value : "per week"},
    {text : "per month", value : "per month"},
    {text : "per year", value : "per year"},
  ],

  Language : [
    {text : "Hindi", value : "Hindi"},
    {text : "English", value : "English"},
  ],

  ColorPalette : [
    "#FF6680",
    "#B34766",
    "#FF8F66",
    "#FF66F0",
    "#B347A8",
    "#3066BE",
    "#28C2FF"
  ],

  ReceptionistDashboardColorPalette : [
    "#90e0ef",
    "#00b4d8",
    "#0077b6",
  ],

  SkillType: [
    { value: "Technical Skill", text: "Technical Skill" },
    { value: "Soft Skill", text: "Soft Skill" },
  ],

  SkillProficiencyLevel: [
    { value: "Novoice", text: "Novoice" },
    { value: "Basic Knowledge", text: "Basic Knowledge" },
    { value: "Working Knowlege", text: "Working Knowlege" },
    { value: "Expert, Master", text: "Expert, Master" },
  ],
   proficiencyMap :{
    'Novoice': 25,
    "Basic Knowledge": 50,
    "Working Knowlege": 75,
    "Expert, Master": 100,
  },
  WeeklyAndMonthlyPayslipDropdown: [
    { value: "Week", text: "Weekly Payslip - Employees having Weekly Pay" },
    { value: "Month", text: "Monthly Payslip - Employee with Monthly Pay" }
  ],
   employeeApprovalMessages :[
    { value: 0, title: "Manager Not Assigned", text: "Please assign a manager before approving the employee." },
    { value: 1, title: "Office Location Not Assigned", text: "Please assign an office location before approving the employee." },
    { value: 2, title: "Document Approval Pending", text: "Please approve all documents before approving the employee." },
    { value: 3, title: "Employee Rejected", text: "Employee application rejected." },
    { value: 4, title: "Subscription Time Limit Over", text: "The time limit of your subscription is over. Please renew your subscription before proceeding. Contact support for any help." },
    { value: 5, title: "Subscription User Limit Exceeded", text: "The user limit of your subscription is exhausted. Please renew your subscription before proceeding. Contact support for any help." },
    { value: 6, title: "Employee Approved", text: "The employee has been successfully approved." },
    { value: 7, title: "Error", text: "Something went wrong. Please try again later. Contact support if the error persists." },
  ]
  ,
  ReceieverTypeOptions:[
    {value:'Internal',text:'Employees and Internal Users'},
    {value:'External',text:'External User'},
    // {value:'Both',text:'Both'}
  ],

  SendMailDropdown:[
    {value:'upcomingVisitors',text:'All Upcoming Visitors'},
  ]
  
};



