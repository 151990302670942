import { Box, Button, Container } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import SnackbarComponent from '../../Services/SnackbarComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import TableComponent, { NoRecordTemplate } from '../../Services/TableComponent';
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import ActionButton from '../../Services/ActionButton';
import { render } from 'react-dom';
import { map } from 'underscore';
import { TaskAlt } from '@mui/icons-material';

function DocumentHistory() {
    const [DocumentHistoryData,setDocumentHistoryData]=useState();
    const refSnackbar = useRef();
    const dispatch = useDispatch();
    const param=useLocation();
    const MasterPageName='Document History'
    const [searchParams] = useSearchParams();


 const fetchHistory = async () => {
            let data = await WebService({
                endPoint: `Document/GetDocumentHistory/?Id=${param.state?.DocumentTemplateId}&HID=${window.atob(searchParams?.get("search") ?? "")}`,
                method: 'get',
                dispatch,
            });
            
            // Map through the data and add the EncodedId field
            data = data?.DocumentHistoryData?.map((i) => ({
                ...i,
                EncodedId: window.btoa(i.DocumentHistoryId), // Add the EncodedId property
            }));            
            setDocumentHistoryData(data);

          
      
    }

    useEffect(()=>{
        fetchHistory()
    },[searchParams])
    function beautifyLocalTime(utcTimestamp) {
        // Convert the UTC timestamp string to a Date object
        const utcDate = new Date(utcTimestamp);
      
        // Use Intl.DateTimeFormat to format the date in the local time zone
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        };
      
        // Format the date
        return new Intl.DateTimeFormat(undefined, options).format(utcDate);
      }
    const columns = [
        { Text: "EncodedId", Value: "EncodedId", isVisiable: false },

        {
            Text:'Sent Date Time',
            Value:'SentTimeStamp',
            render:(dr)=>(
                <>{dr.SentTimeStamp && beautifyLocalTime(dr.SentTimeStamp)}</>
            )
        },
        {
            Text:'Receiver Email',
            Value:'EmailId'
        },
        {
            Text: "Sent By",
            Value:'FullName'
            
        },
        {
            Text:'Delivery Status',
            render:(dr)=>(
                <span>{dr.DeliveryStatus}</span>
            )       
        },
        {
            Text:'Signature Status',
            value:'SignDateTimestamp',
            render:(dr)=>(
                
<>
          {dr.SignDateTimestamp ? (
            <span className="d-flex align-items-center">
              {beautifyLocalTime(dr.SignDateTimestamp)}{" "}
              <TaskAlt className=" ms-1" color="success" titleAccess="Signed" />
            </span>
          ) : dr.RequiresSignature === 1 ? (
            'peding'
          ) : (
            'Not Required'
          )}
        </>                
            )

        },  
       
        {
            Text:'Action',  
            style:{width:'2px'},
            render:(dr)=>(
                <span className='d-flex col-auto'>
                    {dr.SignedDocumentFilePath && 
                    <ActionButton
                    IconName='View'
                    IconTooltip='view signed document'
                    onClick={() =>
                                         window.open(
                                           `${StandardConst.apiBaseUrl}/uploads/${dr.SignedDocumentFilePath}`,
                                           "_blank"
                                         )
                                       }
                    />
                    }
                </span>
            )
        }
    
       
    ];
       const [bData,] = React.useState([
            {
                title: "Document Template List",
                hrefLink: "/ManageDocument",
            },
            {
                title: "Document History",
                hrefLink: "#",
            },
        ]);

  return (
    <div>
       <>
            <Container fluid className="base-container">
                <Box
                    sx={{
                        width: 1,
                    }}
                >
                    {/* <Button onClick={()=>fetchHistory()}>refresh</Button> */}
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                            <h5 className="ms-4 mt-2">{MasterPageName}</h5>
                            <div className="ms-4">
                                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
                            </div>
                        </div>
                    </div>
                   
                </Box>
                <div></div>
                {/* {filterComponent}  @shubham95shrivas please review this */}
                <TableComponent
                    columns={columns}
                    data={DocumentHistoryData}
                    bData={bData}
                    MasterPageName={MasterPageName}
                    
                    noRecordCss="p-0"
                    noRecordFound={
                        <NoRecordTemplate
                            headerValue={StandardConst.ManageDocumentTempalteHistoryNoResult}
                            subHeaderValue={StandardConst.ManageDocumetTempalateSubHeaderRole}
                            imageUrl={StandardConst.imageNoRecordsFound}
                        />
                    }
                   
                    isSearchRequired={true}
                    allowSerialNo={true}
                    initialSearchContent={searchParams.get("search") ?? ""}

                />
               
            </Container>
        </>
    </div>
  )
}

export default DocumentHistory
