import { Divider, Paper, table } from "@mui/material";
import { Box } from "@mui/system";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import _ from "underscore";
import CompanyInfoWide from "../../Services/CompanyInfoWide";
import Cookies from "universal-cookie";
import DisplayNumberFormatComponent from "../../Services/DisplayNumberFormatComponent";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { format } from "date-fns/esm";
const boxCommonStyles = {
  "& > :not(style)": {
    m: 1,
  },
};
const commonStyles = {
  border: 1,
  borderBottom: 1,
  borderColor: "#A6A6A6",
  p: 2,
};
const ReportTemplate = (props) => {
  const renderCustomField = (item, Symbol) => {
    if (item.FieldValue !== null) {
      if (item.FieldType === StandardConst.dataTypes.Date) {
        return format(new Date(item.FieldValue), "yyyy-MM-dd");
      } else if (item.FieldType === StandardConst.dataTypes.Currency) {
        return (
          <DisplayNumberFormatComponent
            Number={item.FieldValue}
            Currency={Symbol}
          />
        );
      } else {
        return item.FieldValue;
      }
    } else {
      return "-";
    }
  };

  return (
    <>
      {props.data.StatusId === StandardConst.Status.Published ? (
        <iframe
          src={`${StandardConst.payslipPDF}/${props.data.PublishedPayslipPath}`}
          title="PDF Viewer"
          style={{ width: "100%", height: "610px" }}
        ></iframe>
      ) : (
        <>
          <meta charset="UTF-8"></meta>
          <div
            id="report-content"
            style={{ padding: "10px", background: "#fff" }}
          >
            <div style={{border:'1px solid black', padding:'2px'}}>
            <CompanyInfoWide section1={3} section2={9} isMobileVisible={true} />

            </div>

            <div style={{ textAlign: "center",borderLeft:'1px solid black', borderRight:'1px solid black' }}>
              <h2 className="m-0 p-1">Payslip for the Month of {props.data.PayMonth}</h2>
            </div>
            <div className="container" style={{ border: '1px solid black' }}>
  <div className="row pt-3">
    {/* Left side */}
    <div className="col-12 col-md-6" style={{ paddingRight: '15px' }}>
      <div style={{ paddingBottom: '8px' }}>
        <strong>Name</strong>: {props.data.FullName}
      </div>
      <div style={{ paddingBottom: '8px' }}>
        <strong>Joining Date</strong>: {props.data.DOJ}
      </div>
      <div style={{ paddingBottom: '8px' }}>
        <strong>Designation</strong>: {props.data.Designation}
      </div>
      <div style={{ paddingBottom: '8px' }}>
        <strong>Department</strong>: {props.data.DepartmentName || '-'}
      </div>

      {/* Bank Detail Custom Fields */}
      {props.data.BankDetailCustomFieldData.map((item) => (
        <div
          className="d-flex"
          style={{ marginBottom: '8px' }}
          key={item.FieldLabel}
        >
          <div
            style={{
              flex: 1,
              paddingRight: '10px',
              fontWeight: 'bold',
            }}
          >
            {item.FieldLabel}
          </div>
          <div style={{ flex: 1 }}>
            {renderCustomField(item, props.data.Symbol)}
          </div>
        </div>
      ))}
    </div>

    {/* Right side */}
    <div className="col-12 col-md-6" style={{ paddingLeft: '15px' }}>
      <div style={{ paddingBottom: '8px' }}>
        <strong>Employee Code</strong>: {props.data.EmployeeCode}
      </div>
      <div style={{ paddingBottom: '8px' }}>
        <strong>Bank Name</strong>: {props.data.BankName}
      </div>
      <div style={{ paddingBottom: '8px' }}>
        <strong>Bank Account No</strong>: {props.data.BankAccountNo}
      </div>
      <div style={{ paddingBottom: '8px' }}>
        <strong>Location</strong>: {props.data.Location}
      </div>

      {/* Employee Payslip Custom Fields */}
      {props.data.EmployeePayslipCustomFieldData.map((item) => (
        <div
          className="d-flex"
          style={{ marginBottom: '8px' }}
          key={item.FieldLabel}
        >
          <div
            style={{
              flex: 1,
              paddingRight: '10px',
              fontWeight: 'bold',
            }}
          >
            {item.FieldLabel}
          </div>
          <div style={{ flex: 1 }}>
            {renderCustomField(item, props.data.Symbol)}
          </div>
        </div>
      ))}
    </div>
  </div>
</div>


            <div
              className="container   p-2 overflow-hidden"
              style={{ border: "1px solid black" }}
            >
              {/* <div className="row">
                <h6 className="col-md-6">Earnings</h6>
                <h6 className="col-md-6">Deductions</h6>
                <hr />
              </div> */}
              <div className="row d-flex flex-row">
                {/* Earnings Section */}

                <div
                  className="col-md-6"
                  style={{ borderRight: "1px solid black" }}
                >
                                  <h6 className="col-md-6">Earnings</h6>
                                  <hr/>
                  {props.data.Components.filter(
                    (c) => c.EarningOrDeductionType === "Earning"
                  ).map((item, index) => (
                    <div className="d-flex justify-content-between" key={index}>
                      <span>{item.EarningOrDeductionName}</span>
                      <span>
                        <DisplayNumberFormatComponent
                          Currency={props.data.Symbol}
                          Number={item.CalculatedAmount}
                        />
                      </span>
                    </div>
                  ))}
                </div>

                {/* Deductions Section */}
                <div className="col-md-6  col-start-7">
                <h6 className="col-md-6">Deductions</h6>
<hr/>
                  {props.data.Components.filter(
                    (c) => c.EarningOrDeductionType === "Deduction"
                  ).map((item, index) => (
                    <div className="d-flex justify-content-between" key={index}>
                      <span>{item.EarningOrDeductionName}</span>
                      <Divider orientation="vertical" />
                      <span>
                        <DisplayNumberFormatComponent
                          Currency={props.data.Symbol}
                          Number={item.CalculatedAmount}
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Horizontal Divider */}
            </div>

            <div style={{ borderBottom: "1px solid #000", paddingTop: "10px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <strong>Total Earnings:</strong>
                </span>
                <span>
                  <strong>
                    <DisplayNumberFormatComponent
                      Currency={props.data.Symbol}
                      Number={props.data.GrossSalary}
                    />
                  </strong>
                </span>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <strong>Total Deductions:</strong>
                </span>
                <span>
                  <strong>
                    <DisplayNumberFormatComponent
                      Currency={props.data.Symbol}
                      Number={props.data.TotalDeductions}
                    />
                  </strong>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <span>
                  <strong>Net Pay for the Month:</strong>
                </span>
                <span>
                  <strong>
                    <DisplayNumberFormatComponent
                      Currency={props.data.Symbol}
                      Number={props.data.NetSalary}
                    />
                  </strong>
                </span>
              </div>
            </div>
            <center>*This is system generated payslip and does't require signature</center>
          </div>
        </>
      )}
    </>
  );
};

export default ReportTemplate;
