import { Alert, Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { FormInputText, Form, FormInputDropdown } from "../Form";
import { StandardConst } from "../../Services/StandardConst";
import { WebService } from "../../Services/WebService";
import { DateTime } from "luxon";
import Button from "react-bootstrap/Button";
import { WSErrorAlert, WSSuccessAlert } from "../../Services/WSAlert";
import format from "date-fns/format";
import { Details } from "@mui/icons-material";

const BasicProfile = ({
  basicProfile,
  permission,
  ShowExitEmployeeForm,
  setShowExitEmployeeForm,
  fetchDetails,
  dispatch,
  designationData,
  CompanyInfo,
  departmentData,
  qualificationMasterList,
  selectQualificationData,
  customFieldsData,
  loggedUserDetails,
  records,
}) => {

  const [RelationShip, setRelationShip] = useState("");
  const selectedOptionsRef = useRef([]);
  useEffect(() => {
    setRelationShip(basicProfile.RelationShip);
    selectedOptionsRef.current = basicProfile.QualificationData;
  }, [basicProfile]);

  const onSubmitUserProfile = async (data) => {
    if (selectedOptionsRef.current?.length > 0) {
      data.QualificationData = selectedOptionsRef.current;
    } else {
      WSErrorAlert("Error", "Please select atleat one Qualification");
      return;
    }

    if (data.RelationShip === "Married" && data.Anniversary === "") {
      WSErrorAlert("Error", "Please select Anniversary Date");
      return;
    }

    if (data.RelationShip !== "Married") {
      data.Anniversary = "";
    }
    if (data.DOB === "") {
      WSErrorAlert("Error", "Please select Date of Birth");
      return;
    }

    const NewDob = new Date(data.DOB); // creating it a valid js date object
    const NewDoj = new Date(data.DOJ);
    if (data.DOB != null) data.DOB = DateTime.fromJSDate(NewDob).toSQLDate();
    if (data.DOJ != null) data.DOJ = DateTime.fromJSDate(NewDoj).toSQLDate();
    await WebService({
      endPoint: `UserProfile/SubmitUserProfile`,
      body: data,
      dispatch,
    });

    if (data.EmployeeId !== undefined) {
      customFieldsData.map((item) => (item.FieldValue = data[item.FieldName]));
      await saveCustomFieldData(data.EmployeeId);
    }
    WSSuccessAlert("Success", "Profile information updated successfully.");

    fetchDetails();
  };

  const saveCustomFieldData = async (EmployeeId) => {
    if (customFieldsData.length > 0 && EmployeeId !== null) {
      const AllCustomFieldsValueDelete = await WebService({
        endPoint: `CommonUtility/employeecustomfieldvalues?EmployeeId=${EmployeeId}`,
        method: "DELETE",
        // dispatch,
      }).then((res) => true);

      if (AllCustomFieldsValueDelete) {
        customFieldsData.map(async (item) => {
          if (item.FieldType === StandardConst.dataTypes.Date) {
            item.FieldValue = format(new Date(item.FieldValue), "yyyy-MM-dd");
          } else if (item.FieldType === StandardConst.dataTypes.Currency) {
            item.FieldValue = parseInt(
              item.FieldValue.replace(/[^0-9]/g, ""),
              10
            );
          }
          item["EmployeeId"] = EmployeeId;
          await WebService({
            endPoint: `CommonUtility/employeecustomfieldvalues`,
            body: item,
            method: "POST",
            // dispatch,
          });
        });
      }
    }
  };

  const handleQualificationChange = (event, selectedOptions) => {
    selectedOptionsRef.current = selectedOptions;
  };

  return (
    <span>
      <Form defaultValues={basicProfile} onSubmit={onSubmitUserProfile}>
        <div className="card shadow-none border-0  border-start border-bottom footer-widget">
          <Alert severity="info">Personal Information</Alert>
          <div className="card-body">
            <div className="row mt-1">
              <div className="col-md-12">
                <FormInputText
                  label="Name"
                  name="FullName"
                  type="text"
                  isRequired="true"
                  autocomplete="off"
                />
              </div>
            </div>
            <div
              className={`row ${
                basicProfile.StatusId === StandardConst.Status.Reject
                  ? "d-none"
                  : ""
              }`}
            >
              <div className="col-md-12">
                <FormInputText
                  label="Employee Code"
                  name="EmployeeCode"
                  type="text"
                  disabled={
                    basicProfile.StatusId === StandardConst.Status.Pending ||
                    permission.EditEmployeeAllDetails
                      ? false
                      : true
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-1">
                <FormInputText
                  label="Email"
                  name="Email"
                  type="text"
                  isRequired="true"
                  disabled={!permission.EditEmployeeAllDetails}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <FormInputDropdown
                  name="DesignationId"
                  ddOpt={[{}].concat(
                    designationData.map((m) => ({
                      value: m.DesignationId,
                      text: m.Designation,
                    }))
                  )}
                  label="Select Designation"
                  isRequired="true"
                ></FormInputDropdown>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <FormInputDropdown
                  name="Department"
                  ddOpt={[{}].concat(
                    departmentData.map((m) => ({
                      value: m.DepartmentId,
                      text: m.DepartmentName,
                    }))
                  )}
                  label="Select Department"
                  // isRequired="true"
                ></FormInputDropdown>
              </div>
            </div>

            {/* <div className="row">
            <div className="col-md-12">
              <FormInputDropdown
                name="JobFunctionId"
                ddOpt={jobFunctionData.map((m) => ({
                  value: m.JobFunctionId,
                  text: m.JobFunction,
                }))}
                label="Job Function"
                isRequired="true"
              ></FormInputDropdown>
            </div>
          </div> */}
            <div className="row">
              <div className="col-md-12  mt-1">
                <FormInputText
                  label="Date of Birth"
                  name="DOB"
                  type="date"
                  max={DateTime.now().minus({ year: 18 }).toSQLDate()}
                  isRequired="true"
                  portalId="root"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-1">
                <FormInputDropdown
                  name="RelationShip"
                  ddOpt={[{}].concat(StandardConst.Relationship)}
                  label="Relationship"
                  value={RelationShip}
                  setValue={async (v) => {
                    setRelationShip(v);
                  }}
                ></FormInputDropdown>
              </div>
            </div>
            {/* {show == "Married" || */}
            {/* records[0]?.Anniversary > "01-01-1970" ? ( */}
            {RelationShip == "Married" && (
              <div className="mt-1">
                <FormInputText
                  label="Anniversary Date"
                  name="Anniversary"
                  max={DateTime.now().toSQLDate()}
                  min={
                    (basicProfile?.DOB ?? "") === "" ? null : basicProfile.DOB
                  }
                  type="date"
                  portalId="root"
                />
              </div>
            )}

            {/* // ) : ( // "" // )} */}
            <div className="mx-n4 my-2">
              {" "}
              <Alert severity="info">Other Information</Alert>
            </div>
            <div className="row">
              <div className="col-md-12">
                <FormInputText
                  label="Phone"
                  name="Phone"
                  type="text"
                  isRequired="true"
                  autocomplete="off"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <FormInputText
                  label="Emergency Phone"
                  name="EmergencyPhone"
                  type="text"
                  isRequired="true"
                  autocomplete="off"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <FormInputText
                  label="Permanent Address"
                  name="PermanentAddress"
                  as="textarea"
                  isRequired="true"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-1">
                <FormInputText
                  label="Communication Address"
                  name="CommunicationAddress"
                  as="textarea"
                  isRequired="true"
                />
              </div>
            </div>

            <div className="col-md-12 mt-1">
              <div className="row">
                <div
                  className="col-md-5 d-flex align-items-center justify-content-between"
                  style={{ fontSize: "14px" }}
                >
                  <label className="d-flex">
                    Qualification <div className="text-danger">＊</div>
                  </label>
                </div>
                <div className="col-md-7" style={{ paddingLeft: "0px" }}>
                  <Autocomplete
                    multiple
                    id="grouped-demo"
                    options={qualificationMasterList}
                    getOptionLabel={(option) => option?.text}
                    defaultValue={selectQualificationData?.map(
                      (selectedData) =>
                        qualificationMasterList[
                          qualificationMasterList?.findIndex(
                            (pitem) => pitem.value === selectedData.value
                          )
                        ]
                    )}
                    onChange={handleQualificationChange}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Qualification" />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="col-md-12"></div>{" "}
            </div>
            <div className="col-md-12">
              <div>
                <FormInputText
                  label="Joining Date"
                  name="DOJ"
                  min={basicProfile.DOB}
                  // max={DateTime.now().toSQLDate()}
                  type="date"
                  isRequired="true"
                  portalId="root"
                />
              </div>
            </div>
            {customFieldsData.length > 0 &&
              customFieldsData.map((CustomField) => {
                const FieldType = CustomField.FieldType;
                // const FieldValue = (CustomField.FieldValue !== undefined) ? CustomField.FieldValue : "";
                if (FieldType === StandardConst.dataTypes.Date) {
                  return (
                    <div className="col-md-12">
                      <FormInputText
                        label={CustomField.FieldLabel}
                        name={CustomField.FieldName}
                        type="date"
                        portalId="root"
                        isRequired={
                          CustomField.FieldIsRequired ===
                          StandardConst.YesNo.Yes
                            ? "true"
                            : "false"
                        }
                        setValue={(val) => (CustomField["FieldValue"] = val)}
                      />
                    </div>
                  );
                } else if (FieldType === StandardConst.dataTypes.Currency) {
                  return (
                    <div className="col-md-12">
                      <FormInputText
                        label={CustomField.FieldLabel}
                        name={CustomField.FieldName}
                        type="currency"
                        locale={CompanyInfo?.LocaleCode}
                        currencyCode={CompanyInfo?.CurrencyCode}
                        isRequired={
                          CustomField.FieldIsRequired ===
                          StandardConst.YesNo.Yes
                            ? "true"
                            : "false"
                        }
                        setValue={(val) => (CustomField["FieldValue"] = val)}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className="col-md-12">
                      <FormInputText
                        label={CustomField.FieldLabel}
                        name={CustomField.FieldName}
                        type={FieldType}
                        isRequired={
                          CustomField.FieldIsRequired ===
                          StandardConst.YesNo.Yes
                            ? "true"
                            : "false"
                        }
                        setValue={(val) => (CustomField["FieldValue"] = val)}
                      />
                    </div>
                  );
                }
              })}
          </div>

          {((permission.ManageProfileSave &&
            loggedUserDetails === records[0]?.EmployeeId) ||
            permission.ManageAllProfileSave) && (
            <div className="card-footer mt-2 d-flex justify-content-between">
              {!ShowExitEmployeeForm && permission.ExitEmployee && (
                <Button
                  variant="outline-primary"
                  onClick={() => setShowExitEmployeeForm(true)}
                  id="btnSubmit"
                  className="px-4 float-end"
                >
                  Employee Exit
                </Button>
              )}
              <Button
                variant="outline-primary"
                type="submit"
                id="btnSubmit"
                className="px-4 float-end ms-auto"
              >
                Save Changes
              </Button>
            </div>
          )}
        </div>
      </Form>
    </span>
  );
};

export default BasicProfile;
