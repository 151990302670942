import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormInputDropdown, FormInputText } from '../Form';
import { WebService } from '../../Services/WebService';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from '../../Services/StandardConst';
import { WSSuccessAlert } from '../../Services/WSAlert';


const AddEditAssetType = (props, ref) => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ AssetType: {} });
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const isSuperAdmin = Roles?.some(element => element === StandardConst.SystemRole.SuperAdmin);
    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id === 0) {
                const data = {
                    AssetType: {},
                };
                setData(data);
            } else {
                setShow(false);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    const fetchEditValue = async (id) => {
        const data = {
            AssetType: await WebService({
                endPoint: `CommonUtility/Edit/assettype?AssetTypeId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
        setData(data);
    }

    const handleClose = () => {
        setShow(false)
        setData({AssetType : {}});
    };

    const schema = yup
        .object()
        .shape({
            AssetTypeName: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            Status: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
        .required();

    const onSubmit = async (data) => {
        data.CompanyId = companyInfo.CompanyId;
        if(data.isReserved === StandardConst.YesNo.Yes) {
            data.CompanyId = null;
        }

        if (data.AssetTypeId === undefined) {
            await WebService({
                endPoint: "CommonUtility/assettype",
                body: data,
                dispatch,
            }).then(res => {
                WSSuccessAlert("Success", `Asset type name ${data.AssetTypeName} added successfully`);
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/assettype?AssetTypeId=${data.AssetTypeId}`,
                method: "PUT",
                body: data,
                dispatch,
            }).then(res => {
                WSSuccessAlert("Success", `Asset type name ${data.AssetTypeName} updated successfully`);
            });
        }
        handleClose();
        props.callBackEvent();
    }
console.log(data.AssetType)
    
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data && data.AssetType && data.AssetType.AssetTypeId || 0) === 0
                        ? "Add Asset Type"
                        : "Edit Asset Type"
                    }
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.AssetType}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12 pb-1">
                            <FormInputText
                                label="Asset Type Name"
                                name="AssetTypeName"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12 pb-1">
                            <FormInputText
                                name="Description"
                                label="Description"
                                as="textarea"
                                rows="2"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText 
                                label="Icon" 
                                name="Icon" 
                                type="text" 
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputDropdown
                                name="Status"
                                ddOpt={StandardConst.StatusDropdown}
                                label="Status"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            {
                                isSuperAdmin && (
                                    <FormInputDropdown
                                        name="isReserved"
                                        ddOpt={StandardConst.YesOrNoReserved}
                                        label="Reserved"
                                    />
                                )
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAccessLevelSubmit" variant="outline-primary" type="submit">
                        {" "}
                        {(data && data.AssetType && data.AssetType.AssetTypeId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default forwardRef(AddEditAssetType);