import React, { useState } from "react";
import {
  Container,
  Card,
  Chip,
  Autocomplete,
  TextField,
  Box,
  Tabs,
  Tab,
  useTheme,
  CircularProgress,
  Alert,
  Checkbox,
  LinearProgress,
} from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { WebService } from "../../Services/WebService";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { StandardConst } from "../../Services/StandardConst";
import Papa from "papaparse";
import TableComponent from "../../Services/TableComponent";
import AppbarComponent from "../../Services/AppbarComponent";
import {
  Check,
  CheckBox,
  CloudUpload,
  Code,
  Download,
  Error,
  Preview,
  Send,
  TaskAlt,
  Warning,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { WSErrorAlert, WSSuccessAlert } from "../../Services/WSAlert";
import { Form, FormInputDropdown } from "../Form";
import ProgressBar from "../../components/ProgressBar";
import { beautifyLocalTime } from "../../Services/StandardFunctions";

const SendDocumentExternalUser = () => {
  const params = useParams();
  const [DocumentTemplate, setDocumentTemplate] = useState([]);
  const [ReceiverData, setReceiverData] = useState([]);
  const [TableData, setTableData] = useState([]);
  const [csvfileName, setcsvfileName] = useState(false);
  const [EmailTemplateList, setEmailTemplateList] = useState([]);
  const [progress, setprogress] = useState(false);
  const [progressLoading, setprogressLoading] = useState(false);
  // this state handles the preview of the email template and its html  code
  const [value, setValue] = React.useState(1);
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const [SignatureYesorNo, setSignatureYesorNo] = useState([]);

  const navigate = useNavigate();
  const [bData] = useState([
    {
      title: "Document Template List",
      hrefLink: "/ManageDocument",
    },
    {
      title: "Send Document",
      hrefLink: "#",
    },
  ]);

  const [EmailhtmlContent, setEmailHtmlContent] = useState({
    subject: "",
    body: "",
  });

  const MasterPageName = "Send Document";
  const fetchDocumentTempalete = async () => {
    const data = await WebService({
      endPoint: `Document/GetOneDocumentTempalete/?DocumentTempaleteId=${params.documentId}`,
      method: "GET",
    });
    setDocumentTemplate(data?.result[0]);
    setEmailTemplateList(data?.Emailtemplate);
  };

  useEffect(() => {
    if (params.documentId != 0) fetchDocumentTempalete();
  }, [params.documentId]);
  const handleSubmit = async () => {
    setprogressLoading(true);
    let values = {};
    values.documentName = DocumentTemplate.DocumentTemplateName;
    values.documentpath = DocumentTemplate.DocumentTemplatesPath;
    values.DocumentTemplateId = DocumentTemplate.DocumentTemplateId;
    values.RequiresSignature = SignatureYesorNo;
    for (let i = 0; i < ReceiverData.length; i++) {
      const Receiver = ReceiverData[i];

      // Get all key-value pairs as an array
      const entries = Object.entries(Receiver);

      // Remove the first key-value pair and store it in a variable
      const [firstKey, firstValue] = entries.shift();

      // Log the removed value

      values.ReceiverMail = firstValue;
      values.ValuesToBeChangedWithDocumentPlaceholders =
        Object.fromEntries(entries);
      values.EmailHtml = EmailhtmlContent;
      values.SentTimeStamp = new Date().toISOString();

      // Update the table status to 'sending' directly using index
      setTableData((prevTableData) => {
        const updatedTableData = [...prevTableData];
        updatedTableData[i].status = "sending";
        return updatedTableData;
      });

      try {
        // Send the email
        await WebService({
          endPoint: `Document/send`,
          method: "post",
          body: values,
        });

        // Update the table status to 'sent' directly using index
        setprogress((prev) => (prev + 1 / ReceiverData.length) * 100);
        setTableData((prevTableData) => {
          const updatedTableData = [...prevTableData];
          updatedTableData[i].status = "sent";
          return updatedTableData;
        });
      } catch (error) {
        // In case of failure, update the table status to 'failed'
        setTableData((prevTableData) => {
          const updatedTableData = [...prevTableData];
          updatedTableData[i].status = "failed";
          return updatedTableData;
        });
      }
    }
    WSSuccessAlert("", "Document Sending process completed");
    setprogressLoading(false);
  };

  const handleCSVUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    setcsvfileName(file.name);
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (fileExtension !== "csv") {
      return;
    }

    Papa.parse(file, {
      header: true,
      complete: (result) => {
        // Extracting ReceiverMail from each row
        const emails = result.data.filter((row) =>
          Object.values(row).some(
            (value) => value !== "" && value !== null && value !== undefined
          )
        );

        setReceiverData(emails); // Storing the extracted emails
        const tableData = emails.map((row) => ({
          ...row, // Copy existing row data
          status: "pending", // Add the new column with initial value
        }));

        // Now set the table data with the updated array

        setTableData(tableData);
      },
      error: (error) => {
        WSErrorAlert("", "Something went Wrong");
      },
    });
  };

  const generateCSV = () => {
    // Define CSV headers
    let headers = [];
    headers.push("ReciepentEmail");
    headers = headers.concat(DocumentTemplate.placeholders);
    //['name','date']
    // generate dynamic row for name and date ( name1 , date1) (name2,date2)

    const row1 = headers
      ?.map((placeholders, index) => `${placeholders || ""}-${1}`)
      .join(", ");
    const row2 = headers
      ?.map((placeholders, index) => `${placeholders || ""}-${2}`)
      .join(", ");
    // Construct CSV content
    const csvContent = [headers.map((i) => i).join(","), row1, row2].join("\n");

    // Create a Blob object
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.download = "sample.csv"; // Set the file name
    document.body.appendChild(link);

    // Trigger download
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const columns = Object.keys(TableData[0] || {}).map((key) => ({
    Text: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the column header
    Value: key,
    ...(key == "status" && {
      render: (dr) => (
        <span>
          {dr.status === "sent" ? (
            <>
              sent {"  "} <TaskAlt color="success" fontSize="small" />
            </>
          ) : dr.status === "pending" ? (
            <>
              pending {"  "} <Warning color="warning" fontSize="small" />
            </>
          ) : dr.status === "failed" ? (
            <>
              failed {"  "} <Error color="error" fontSize="small" />
            </>
          ) : dr.status === "sending" ? (
            <>
              sending {"  "} <CircularProgress size={10} />
            </>
          ) : null}
        </span>
      ),
    }),
  }));


  return (
    <>
     <Container fluid className="base-container ">
      {/* Header Section */}

      <div className="d-flex justify-content-between align-items-center mt-3">
        <div>
          <h5 className="ms-4 mt-2">{MasterPageName}</h5>
          <div className="ms-4">
            <BreadcrumbsComponent bData={bData} />
          </div>
        </div>
      </div>

      <AppbarComponent
        title={MasterPageName}
        isSearchRequired={false}
        isAddButtonRequired={true}
        Exception={true}
      />
      {/* Document Details */}
      <Card className="shadow-lg py-4 px-4 w-100 mx-auto ">
        <Alert icon={false} severity="info" className="mb-2">
          <ol>
            <li>
              Document can be sent to multiple recipients at once using the CSV
              file.
            </li>
            <li>
              Download recipient data CSV file using the link {""}
              <span
                title="Download Reciepient List Format"
                className="text-primary text-start w-auto cursor-pointer hover:underline"
                onClick={generateCSV}
              >
                Download recipient data CSV file <Download />
              </span>
              and save to local disk. Fill in the recipient email in the first column and other details in the CSV file. Do not change the column headers.
            </li>
            <li>
              Upload the csv file containing recipient data using the "Upload Recipient Data" button.
            </li>
            <li>
              Draft the email content using the placeholders provided in the document template OR using the email template.
            </li>
            <li>
              Click on the "Send Document To Recipients" button to send the document to the recipients.
            </li>
          </ol>
        </Alert>
        <TableComponent
          IsAddButtonVisible={false}
          onAddEvent={null}
          isSearchRequired={false}
          isAddButtonRequired={false}
          isExcelExportButtonVisible={false}
          data={[
            {
              DocumentName: (
                <>
                  <span
                    title="view document"
                    className="text-primary text-capitalize cursor-pointer underline text-decoration-none hover-underline"
                    onClick={() =>
                      window.open(
                        `${StandardConst.apiBaseUrl}/${DocumentTemplate.DocumentTemplatesPath}`,
                        "_blank"
                      )
                    }
                  >
                    {DocumentTemplate.DocumentTemplateName}
                  </span>{" "}
                    <i className="fa fa-download  text-primary fa-1x cursor-pointer" />
                  
                  {DocumentTemplate.IsReserved ? <Chip label="Reserved" /> : ""}
                </>
              ),
              CreatedBy: (
                <span className="text-muted font-weight-bold">
                  {DocumentTemplate.CreaterName}
                </span>
              ),
              LastModifiedBy: (
                <span className="text-muted font-weight-bold">
                  {DocumentTemplate.LastModifiedBy}
                </span>
              ),
               CreatedAt:(
                    <span className="text-muted font-weight-bold">
                    {DocumentTemplate?.CreatedAt && beautifyLocalTime(DocumentTemplate?.CreatedAt || '')}
                </span>
              ),
              Action: (
                <span
                  title="Download Recipient List Format"
                  className="text-primary text-start w-auto cursor-pointer hover:underline"
                  onClick={generateCSV}
                >
                  Download recipient data CSV file <Download />
                </span>
              ),
            },
          ]}
          columns={[
            { Text: "Document Name", Value: "DocumentName" },
            { Text: "Created By", Value: "CreatedBy" },
            { Text: "Last Modified By", Value: "LastModifiedBy" },
            { Text: "Action", Value: "Action" },
          ]}
        />

        <div className="d-flex flex-column flex-lg-row flex-md-row justify-content-end align-items-center my-2">
          {/* Upload button */}
          <div className="">
            <label
              htmlFor="inputGroupFile04"
              className="btn btn-outline-primary d-flex align-items-center gap-2 mb-2 mb-md-0"
            >
              <CloudUpload />
              <span>{csvfileName ? csvfileName : "Upload Recipient Data"}</span>
              <input
                type="file"
                accept=".csv"
                onChange={handleCSVUpload}
                className="form-control d-none"
                id="inputGroupFile04"
              />
            </label>
          </div>
        </div>

        {csvfileName && (
          <div className="">
            <TableComponent
              isSearchRequired={true}
              data={TableData}
              columns={columns}
            />
          </div>
        )}   
      </Card>
    </Container>
    {csvfileName && (
    <Container fluid className="base-container my-2 pb-4">  
        {/* section for email template edit and view at same placeholder */}
      
     <Alert severity="info">Email To Be Send Along With Document</Alert>
      <card className='pb-4 mb-3'>

      
        
          <div className="my-4 px-4">
            {/* top-bar */}
            <div className="col-12 py-2">
                      <Alert severity="warning" className="w-100">
                        Allowed keyword in email -{" "}
                        {DocumentTemplate.placeholders
                          ?.map((placeholder) => `@${placeholder}`)
                          .join(", ")}
                      </Alert>
                    </div>
            <div className="d-flex flex-lg-row flex-md-row  flex-column justify-content-between gap-1">
              <div className="col-md-6 ">
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Email Subject"
                  value={EmailhtmlContent.subject}
                  onChange={(e) =>
                    setEmailHtmlContent((prev) => ({
                      ...prev,
                      subject: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="col-md-5">
                <Autocomplete
                  options={EmailTemplateList}
                  getOptionLabel={(option) => option?.Title || ``}
                  // defaultValue={EmailTemplateList[0]}
                  onChange={(event, newValue) =>
                    setEmailHtmlContent((prev) => ({
                      ...prev,
                      subject: newValue?.Subject || "",
                      body: newValue?.Body || "",
                    }))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Use a mail template to draft email"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex flex-column flex-md-row justify-content-between align-items-start my-2">
              <Box sx={{ width: "100%" }}>
                <Box>
                  <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                    {/* Tabs Section */}
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      className="col-12 col-lg-5 "
                      scrollButtons="auto"
                      sx={{
                        '& .MuiTabs-flexContainer': {
                          height: '55px',
                        },
                      }}
                      style={{}}
                    >
                      <Tab
                        label="HTML Code"
                        icon={
                          <Code className="mb-0" sx={{ marginBottom: 0 ,paddingBottom:0}} />
                        }
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          
                          gap: "5px",
                          alignItems: "center",
                        }}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label="Email Preview"
                        icon={
                          <Preview className="mb-0" sx={{ marginBottom: 0 }} />
                        }
                        sx={{
                          display: "flex",
                          gap: "5px",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                    {/* Alert Section */}
                    
                  </div>
                </Box>

                {/* Tab Panels */}
                <CustomTabPanel value={value} index={0}>
                  <div  className="">
                    <textarea

                          rows={20}              
                       multiline={true}
                      value={EmailhtmlContent.body}
                      placeholder={` Write the email content in the form of HTML. Use @keyword to substitute value from your csv file.
                      <!DOCTYPE html>
                      <html lang="en">
                      <head>
                          <title>Placeholder Example</title>
                      </head>
                      <body>
                          <h2>Sample Email Example</h2>
                          <p>Use placeholders like @userName to represent dynamic data in the Email. These placeholders should be the same as your csv file heading</p>
                      </body>
                      </html>`}
                      onChange={(e) =>
                        setEmailHtmlContent((prev) => ({
                          ...prev,
                          body: e.target.value,
                        }))
                      }
                      // style={{
                      //   fontFamily: "monospace",
                      //   fontSize: "14px",
                      //   border:'1px solid #ccc',
                      //   padding: "10px",
                      //   width: "95%",
                        
                      
                      // }}
                      
                    />
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <div className="border border-1 mt-2">
                    {EmailhtmlContent.body ? (
                      <iframe
                        id="html-preview"
                        title="html-preview"
                        className="w-100"
                        height="600px"
                        srcDoc={EmailhtmlContent.body}
                        frameBorder="0"
                      />
                    ) : (
                      <div
                        class="container d-flex justify-content-center align-items-center"
                        style={{ minHeight: "300px", padding: "20px" }}
                      >
                        <div class="text-center p-4 ">
                          <p>
                            No HTML content available. Please ensure you include
                            placeholders such as @name, @email, etc., in your
                            email template.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </CustomTabPanel>
              </Box>
            </div>
          </div>
        
         
          <div className=" col-md-12 w-100 d-flex flex-column align-items-end  justify-content-end gap-2 mb-2 px-4">
            <div className="">
              <label>
              Recipent is required to sign the document or upload the document with signatures ?
              </label>
              <Checkbox
                checked={SignatureYesorNo}
                onChange={() => setSignatureYesorNo(!SignatureYesorNo)}
                name="signature"
              />
            </div>
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleSubmit}
              id="inputGroupFileAddon04"
              // disabled={progress}
            >
              {!progressLoading ? (
                <>
                  <Send /> Send Document To Recipients
                </>
              ) : (
                <>Sending ...</>
              )}
            </button>

           
          </div>
     
        </card>
     </Container> )}
    </>
   
  );
};

export default SendDocumentExternalUser;

const CustomTabPanel = React.memo((props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <span sx={{}}>{children}</span>}
    </div>
  );
});
