import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { WebService } from "../../Services/WebService";
import { map } from "underscore";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  LineChart,
  Line,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { Box } from "@mui/material";
import {
  ProfileSummary,
} from "../Dashboard/Company/CompanyDashboard";
import { CustomPieChart } from "./CustomPieChart";

const VehicleDashboard = () => {
  return (
      <div className='container'>
        {/* <div className="row mt-3">
          <div className="col-12">
            <div className="card card-cd m-0">
              <div className="card-body p-0 p-2">
                <div className="d-flex flex-column flex-lg-row flex-sm-row">
                  <ProfileSummary className="col-md-5 col-sm-9" />
                  <div className="vr my-2 col-md-1 col-sm-1 d-flex justify-content-end d-none d-md-inline"></div>
                  <div className="vr my-2 col-md-1 col-sm-1 d-flex justify-content-end d-none d-md-inline"></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="row mt-3">
          <div className="col-12 col-md-4 col-sm-6 col-xs-6">
            <CustomPieChart endPoint="Vehicle/getInsuranceGraphData" mappingFields={{name: "ExpiryRange", value: "Count"}} graphHeading="Insurance expiry list" graphRadius={80} />
          </div>

          <div className="col-12 col-md-8 col-sm-6 col-xs-6 mt-3 mt-md-0">
            <MostUsedVehicleChart/>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 col-md-6 col-sm-6 col-xs-6">
            <AverageFuelEfficiencyLineChart />
          </div>

          <div className="col-12 col-md-6 col-sm-6 col-xs-6 mt-3 mt-md-0">
            <AverageMaintenanceAndRepairsChart />
          </div>
        </div>

        <div className="row mt-3 mb-4">
          <div className="col-12 col-md-6 col-sm-6 col-xs-6">
            <MostTripByDriversChart />
          </div>

          <div className="col-12 col-md-6 col-sm-6 col-xs-6 mt-3 mt-md-0">
            <CustomPieChart endPoint="Vehicle/getFuelConsumptionAnalysis" mappingFields={{name: "MileageRange", value: "NumberOfVehicles"}} graphHeading="Fuel Consumption Analysis Graph" graphRadius={80} />
          </div>
        </div>
      </div>
  )
};

const MostUsedVehicleChart = () => {

  const dispatch = useDispatch();
  // const [mostUsedVehicleData, setMostUsedVehicleData] = useState([]);
  const [mostUsedVehicleData, setMostUsedVehicleData] = useState([
    {RegistrationNumber: "Vehicle 1", VehicleTripCount : 0},
    {RegistrationNumber: "Vehicle 2", VehicleTripCount : 0},
    {RegistrationNumber: "Vehicle 3", VehicleTripCount : 0},
    {RegistrationNumber: "Vehicle 4", VehicleTripCount : 0},
    {RegistrationNumber: "Vehicle 5", VehicleTripCount : 0}
  ]);

  const fetchMostUsedVehicles = async () => {
    const data = await WebService({
      endPoint: `Vehicle/getMostUsedVehicleData`,
      dispatch,
    });
    if(data.length > 0) {
      setMostUsedVehicleData(data);
    }
  }

  useEffect(() => {
    fetchMostUsedVehicles();
  }, [])

  return (
    <>
      <div className="card card-cd m-0 p-3">
        <div className="d-flex flex-row">
            <div className="col-md-6 col-sm-6 ms-2 d-flex justify-content-start align-items-center">
            <h5 className="card-title">Most Vehicle Used</h5>
            </div>
            <div className="col-md-6 col-sm-6 d-flex justify-content-end"></div>
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart width={700} height={250} data={mostUsedVehicleData}>
              <XAxis />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="VehicleTripCount" fill="#8884d8">
                <LabelList dataKey="RegistrationNumber" position="top" />
              </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

const AverageFuelEfficiencyLineChart = () => {

    const dispatch = useDispatch();
    const [averageFuelEfficiencyData, setAverageFuelEfficiencyData] = useState([
      {Months: "Jan", avg: 0, TotalVehicleAvg: 0},
      {Months: "Feb", avg: 0, TotalVehicleAvg: 0},
      {Months: "Mar", avg: 0, TotalVehicleAvg: 0},
      {Months: "Apr", avg: 0, TotalVehicleAvg: 0},
      {Months: "May", avg: 0, TotalVehicleAvg: 0},
    ]);
  
    const fetchAverageFuelEfficiency = async () => {
      const data = await WebService({
        endPoint: `Vehicle/getAverageFuelEfficiencyDataInMonths`,
        dispatch,
      });
      if(data.length > 0) {
        setAverageFuelEfficiencyData(data);
      }
    }
  
    useEffect(() => {
      fetchAverageFuelEfficiency();
    }, [])
  
    return (
      <>
        <div className="card card-cd m-0 p-3">
          <div className="d-flex flex-row">
              <div className="col-md-6 col-sm-6 ms-2 d-flex justify-content-start align-items-center">
              <h5 className="card-title">Average Fuel Efficiency</h5>
              </div>
              <div className="col-md-6 col-sm-6 d-flex justify-content-end"></div>
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart width={500} height={300} data={averageFuelEfficiencyData}>
              <XAxis dataKey="Months"/>
              <YAxis domain={[0, 'auto']} />
              <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
              <Line type="monotone" dataKey="avg" stroke="#8884d8" dot={{ stroke: '#8884d8', strokeWidth: 2 }} />
              <Line type="monotone" dataKey="TotalVehicleAvg" stroke="#ff0000" dot={{ stroke: '#ff0000', strokeWidth: 2 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </>
    );
};

const AverageMaintenanceAndRepairsChart = () => {

  const dispatch = useDispatch();
  const [maintenanceAndRepairData, setMaintenanceAndRepairData] = useState([
    {RegistrationNumber: "Vehicle 1", maintenance:0, repair:0},
    {RegistrationNumber: "Vehicle 2", maintenance:0, repair:0},
    {RegistrationNumber: "Vehicle 3", maintenance:0, repair:0},
    {RegistrationNumber: "Vehicle 4", maintenance:0, repair:0},
    {RegistrationNumber: "Vehicle 5", maintenance:0, repair:0}
  ]);

  const fetchMaintenanceAndRepair = async () => {
    const data = await WebService({
      endPoint: `Vehicle/getManitanceAndRepairData`,
      dispatch,
    });
    if(data.length > 0){
      setMaintenanceAndRepairData(data);
    }
  }

  useEffect(() => {
    fetchMaintenanceAndRepair();
  }, [])

  return (
    <>
      <div className="card card-cd m-0 p-3">
        <div className="d-flex flex-row">
            <div className="col-12 ms-2 d-flex justify-content-start align-items-center">
              <h5 className="card-title">Maintenance and Repairs (000's)</h5>
            </div>
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart width={600} height={400} data={maintenanceAndRepairData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="maintenance" stackId="a" fill="#8884d8" />
            <Bar dataKey="repair" stackId="a" fill="#82ca9d" >
              <LabelList dataKey="RegistrationNumber" position="top" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

const MostTripByDriversChart = () => {

  const dispatch = useDispatch();
  const [driverData, setDriverData] = useState([
    {DriverName : 'Driver 1', DriverTripCount : 0},
    {DriverName : 'Driver 2', DriverTripCount : 0},
    {DriverName : 'Driver 3', DriverTripCount : 0},
    {DriverName : 'Driver 4', DriverTripCount : 0},
    {DriverName : 'Driver 5', DriverTripCount : 0}
  ]);

  const fetchAllDriversTrip = async () => {
    const data = await WebService({
      endPoint: `Vehicle/getMostTripByDriversData`,
      dispatch,
    });
    if(data.length > 0) {
      setDriverData(data);
    }
  }

  useEffect(() => {
    fetchAllDriversTrip();
  }, [])

  return (
    <>
      <div className="card card-cd m-0 p-3">
        <div className="d-flex flex-row">
            <div className="col-md-6 col-sm-6 ms-2 d-flex justify-content-start align-items-center">
            <h5 className="card-title">Number Of Trips By Driver</h5>
            </div>
            <div className="col-md-6 col-sm-6 d-flex justify-content-end"></div>
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart width={500} height={250} data={driverData} layout="vertical">
            <XAxis type="number"/>
            <YAxis dataKey="DriverName" type="category" />
            <Tooltip />
            <Legend />
            <Bar dataKey="DriverTripCount" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default VehicleDashboard