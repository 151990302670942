import { useEffect, useState } from 'react';

const ProgressBar = ({ progress, label = false }) => {
  const [currentProgress, setCurrentProgress] = useState(progress);

  useEffect(() => {
    if (currentProgress !== progress) {
      // Simulate progress change with smooth animation
      const interval = setInterval(() => {
        if (currentProgress < progress) {
          setCurrentProgress(prev => Math.min(prev + 1, progress));
        } else if (currentProgress > progress) {
          setCurrentProgress(prev => Math.max(prev - 1, progress));
        } else {
          clearInterval(interval);
        }
      }, 10); // Adjust interval for smoother progress

      return () => clearInterval(interval);
    }
  }, [progress, currentProgress]);

  return (
    <div className="progress" style={{ height: '20px', width: '100%' }}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{
          width: `${currentProgress}%`,
          transition: 'width 0.3s ease-out', // Smooth transition for width change
          // backgroundColor: currentProgress > 50 ? 'green' : 'red', // Change color based on progress
        }}
        aria-valuenow={currentProgress}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {label && `${currentProgress}%`}
      </div>
    </div>
  );
};

export default ProgressBar;
