import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import AddEditAgent from "./AddEditAgent";
const ManageAgents = () => {
    const ref = useRef();
    const refSnackbar = useRef();
    PageInfo({ pageTitle: "Manage Agents" });
    const dispatch = useDispatch();
    const [records, setRecords] = useState([]);
    const [permission, SetPermission] = useState({});
    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    useEffect(() => {
        SetPermission({
            ManageAdd: true, // ActionPermission("Manage Agents - Add"),
            ManageEdit: true, // ActionPermission("Manage Agents - Edit"),
            ManageDelete: true // ActionPermission("Manage Agents - Delete"),
        });
    }, [permissionList]);
    const fetchAllAgents = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/Edit/agents?CompanyId=${StandardConst.YesNo.Yes}`,
            dispatch,
        });
        setRecords(data);
        dispatch(ConfigurationflowStatusUpdate());
    };

    const [deletedName, setDeletedName] = useState(null);
    
    const onDelete = async (Details) => {
        const data = Details.split(',');
        const AgentId = data[0];
        setDeletedName(data[1]);
        await WebService({
            endPoint: `CommonUtility/agents?AgentId=${AgentId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchAllAgents();
    };

    const renderAfterCalled = useRef(false);
    const addEditModalRef = useRef();
    useEffect(() => {
        if (!renderAfterCalled.current) {
            fetchAllAgents();
        }
        renderAfterCalled.current = true;
    }, []);

    const columns = [
        {
            Text: "Full Name",
            Value: "FullName",
        },
        {
            Text: "Email",
            Value: "Email",
        },
        {
            Text: "Contact Number",
            Value: "ContactNumber",
        },
        {
            Text: "Position/Role",
            Value: "PositionOrRole",
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => fnEdit(dr.AgentId)}
                        disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit Agent"}
                        id={`btnEdit_${dr.AgentId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.FullName, // Message of Alert
                                `${dr.AgentId},${dr.FullName}` // Endpoint to hit for delete
                            )
                        }
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Agent"}
                        id={`btnDelete_${dr.HolidayId}`}
                    />
                </>
            ),
        },
    ];
    const [bData] = React.useState([
        {
            title: "Helpdesk",
            hrefLink: "#",
        },
        {
            title: "Manage Agents",
            hrefLink: "#",
        },
    ]);
    const MasterPageName = "Manage Agents";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const AddButtonText = "Agent";

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={`Agent ${(deletedName!== null) ? deletedName : ""} Deleted successfully`} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.ManageAgentsheaderValueNoResults}
                        subHeaderValue={StandardConst.ManageAgentsSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {permission.ManageAdd && (
                                    <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_add_${AddButtonText}`}>
                                        Add {AddButtonText}
                                    </Button>
                                )}
                            </>
                        }
                    />
                }
                onAddEvent={() => fnEdit()}
                IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
            <AddEditAgent
                callBackEvent={async () => await fetchAllAgents()}
                ref={addEditModalRef}
            ></AddEditAgent>
        </>
    );
}

export default ManageAgents