
import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { WSErrorAlert, WSSuccessAlert, WSWarningAlert } from "../../Services/WSAlert";
import { StandardConst } from "../../Services/StandardConst";
import { CloudUpload, Download } from "@mui/icons-material";
import { Alert } from "@mui/material";
import ActionButton from "../../Services/ActionButton";
import { Button as MuiButton } from '@mui/material';
const { forwardRef, useState, useImperativeHandle } = React;


const AddEditDocument = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [openModalCount, setOpenModalCount] = useState(0);
  const [file, setFile] = useState(null);
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const [RecipientType,setRecipientType]=useState(StandardConst.ReceieverTypeOptions[1].value)
  const [PlaceholderInCaseOfInternalUser,SetPlaceholderInCaseOfInternalUser]= useState(['EmployeeCode','Department','Designation','FullName','Email','CommunicationAddress','Phone','WorkLocation','EmployeeManager','ManagerDesignation'])
  
  const companyInfo = useSelector((s) => s.auth.companyInfo ?? {});
  const regex = /\.([0-9a-z]+)$/i
  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setFile(null);
      // FetchEmployeeTableColoum();
      setShow(true);
      if(id > 0){
        setShow(false);
        await fetchDocumentData(id);
        setShow(true);
      }
    },
  }));
  
  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile); // Save the file for backend submission
    } else {
      alert("Please upload a valid .docx file.");
    }
  };

  const fetchDocumentData = async (id) => {
    await WebService({
      // endPoint: `Officelocation/Fetch/${id || 0}`,
      endPoint: `Document/EditDocumentTempalete/?DocumentTempaleteId=${id}`,
     method: "GET", 
     dispatch,
    }).then((res) => {
      if (res.result.length > 0) {
        setData(res.result[0]);
        const extension = res.result[0].DocumentTemplatesPath.match(regex);
        setFile((prev) => ({
          ...prev, // Spread the previous state
          name: res.result[0].DocumentTemplateName+"."+extension[1], // Update the `name` property
        }));
      }
    });
    
  }

  // // function to get placeholders in case of internal users
  // const FetchEmployeeTableColoum=async(id)=>{

  //  const [result]=await WebService({
  //        endPoint: `UserProfile/Fetch/${id || 0}`,
  //        dispatch,
  //      });
  //          SetPlaceholderInCaseOfInternalUser(Object.keys(result))

  // }

  const handleClose = () => {
    setShow(false);
    setData({});
    setOpenModalCount(openModalCount+1);
  };


  const handleSubmit = async (values) => {
    if (data.DocumentTemplateId!==undefined) {
      await  updateDocument(values); // Call update API if editing a document
        handleClose();
        prop.callBackEvent();
    } else {
      await  uploadDocument(values); // Call upload API if adding a document
        handleClose();
        prop.callBackEvent();
    }
  };

  const uploadDocument = async (values) => {
    if (!file ) {
      WSWarningAlert("Please upload a file before saving");
      return;
    }

    try {
      // First API call for file upload

      const filedetails = await WebService({
        endPoint: "upload/File",
        body: file,
        isFile: true,
      })


      // Verify the response structure
      if (!filedetails || !filedetails.filename || !filedetails.originalname) {
        console.error("File details are missing in the response", filedetails);
        return; // Exit if the response is invalid
      }

      // Second API call for document creation
      await WebService({
        endPoint: "Document/DocumentTemplates",
        method: "POST",
        dispatch,
        body: {
          OriginalFileName: values.DocumentTemplateName,
          RecieverType:values.ReceieverType,
          FilePath: filedetails.filename,
          CreatedAt: new Date().toISOString(), // Update the creation date

        },
      }).then((res) =>{ WSSuccessAlert('',`${values.DocumentTemplateName} (${file.name}) successfully uploaded`);});
    } catch (error) {
      WSErrorAlert("",error)
      console.error("Error uploading document:", error);
    }
  };


  const updateDocument = async (values) => {
    try {
      let updatedFileName = null;

      // If a file is provided, upload it first
      if ((file instanceof File)) {
        const fileDetails = await WebService({
          endPoint: "upload/File",
          body: file,
          isFile: true,
          dispatch
        });

        updatedFileName = fileDetails.filename; // Get the uploaded file's name
      }

      // Determine the file path to send in the update request
      const filePath = updatedFileName
        ? `uploads/${updatedFileName}` // Use the new file path if a file was uploaded
        : data.DocumentTemplatesPath; // Use the existing file path if no file was uploaded

      // Update the document
      await WebService({
        endPoint: `Document/UpdateDocument/${data.DocumentTemplateId}`,
        method: "PUT",
        dispatch,
        body: {
          OriginalFileName: values.DocumentTemplateName, // Updated document name
          RecieverType:values.ReceieverType,
          FilePath: filePath, // New or existing file path
          UpdatedAt:new Date().toISOString()
        },
      });

      // Show success alert after updating
      WSSuccessAlert('',`Document Template "${values.DocumentTemplateName}" updated successfully`);
      
    } catch (error) {
      WSErrorAlert("",error)

      console.error("Error updating document:", error);
    }
  };

      const schema = yup.object().shape({
        DocumentTemplateName: yup.string().trim().label("Token").required(),
      });

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(data.DocumentTemplateId ?? 0) === 0
            ? "Add Document Template"
            : "Edit Document Template"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        <Alert icon={false} severity="info">
         {RecipientType ==='External' ?  <ol>
            <li>
              Use placeholders in the document to replace Person Data while
              sending the document.
            </li>
            <li>
              Placeholder format: <code>{"{name}"}</code>,{" "}
              <code>{"{email}"}</code> etc. Any <code>{"{placeholder}"}</code> is allowed.

            </li>
            <li>
              For example, use <code>{"{name}"}</code> where you want the
              recipient's name and <code>{"{email}"}</code> for their email in
              the document.
            </li>
            
          </ol>:
          <ol>
            <li>
              Use placeholders in the document to replace Person Data while
              sending the document.
            </li>
            <li>
         
            Allowed placeholder in the document: {Array.isArray(PlaceholderInCaseOfInternalUser) 
              ? PlaceholderInCaseOfInternalUser.map(item => <code key={item}>{`{${item}}`}</code>).reduce((prev, curr) => [prev, ', ', curr])
              : 'No valid data available'}
      

            </li>
          </ol>
          
        }
        </Alert>

      <Modal.Body className="p-4">
          <div className="row mt-2">
            <FormInputDropdown
              name="ReceieverType"
              ddOpt={StandardConst.ReceieverTypeOptions}
              label="Select Recipient"
              id='ReceieverType'
              setValue={(v)=>{
                setRecipientType(v)
              }}
            ></FormInputDropdown>
          </div>
          <div className="row mt-2">
            <FormInputText
              name="DocumentTemplateName"
              label="Document Template Name"
              type="text"
              isRequired="true"
              id='DocumentTemplateName'
            />
          </div>
          <div className="d-flex flex-column flex-md-row flex-lg-row">
            <div className="col-12  col-md-5 col-lg-5">
            { data.DocumentTemplateId && <MuiButton endIcon={<Download/>} title='downlod document'
              onClick={() =>
              window.open(
                `${StandardConst.apiBaseUrl}/${data.DocumentTemplatesPath}`,
                "_blank"
              )
            }
            size="small"
            className=""
            sx={{ textTransform: 'none', textDecoration: 'underline'  }} 
            > Downlod Existing</MuiButton>}
            
              {/* { (
                <ActionButton
                  IconName="Download"
                  IconTooltip="Download Document Template"
                  onClick={() =>
                    window.open(
                      `${StandardConst.apiBaseUrl}/${data.DocumentTemplatesPath}`,
                      "_blank"
                    )
                  }
                />
              )} */}
            </div>

            {/* Upload button */}
            <div className="col-12  col-md-7 col-lg-17">
              <label
                htmlFor="inputGroupFile04"
                className="btn btn-outline-primary d-flex align-items-center gap-2 mb-2 mb-md-0 overflow-hidden"
              >
                <CloudUpload />
                <span>
                  {file?.name ? file?.name : "Upload Document Template"}
                </span>
                <input
                  type="file"
                  accept=".doc,.docx"
                  onChange={handleFileUpload}
                  className="form-control d-none"
                  id="inputGroupFile04"
                />
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btnlocationSubmit"
            variant="outline-primary"
            type="submit"
          >
            {" "}
            {(data?.location?.OfficeLocationId || 0) === 0
              ? "Submit"
              : "Save Changes"}{" "}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditDocument);
