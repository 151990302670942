import React, { useEffect, useState } from "react";
import { Alert, Button, Container } from "@mui/material";
import { WebService } from "../../Services/WebService";
import { useDispatch } from "react-redux";
import { WSErrorAlert, WSSuccessAlert } from "../../Services/WSAlert";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CloudUpload, Send } from "@mui/icons-material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { StandardConst } from "../../Services/StandardConst";
import { beautifyLocalTime } from "../../Services/StandardFunctions";

function UploadSignedDocument() {
  const [file, setfile] = useState("");
  const [DocumentData,setDocumentName]=useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const subject = queryParams.get('s');

  const fetch_Document_Details=async()=>{
    console.log('called')
    const resultDocument= await WebService({
          endPoint: `Document/GetDocumentNameFromHistoryId/?Id=${id}`,
         method: "GET", 
         dispatch,
        }).then((res) => {
          if (res.DocumentData.length > 0) {
            setDocumentName(res.DocumentData[0]);
          }
        });
  }

  useEffect(() => {
    if(id)
    {    fetch_Document_Details();
    }
  },[id])
const Uploadfile = async () => {
 if(!file)
 {
  WSErrorAlert('','Please upload a file first')
 }
    // Upload the file and get the details
    const fileDetails = await WebService({
      endPoint: "upload/File",
      body: file,
      isFile: true,
      dispatch,
    });
    // Prepare data for updating document sent history
    const updateData = {
      SignDateTimestamp: new Date(),
      SignedDocumentFilePath: fileDetails.filename,
    };

    // Update document sent history
    await WebService({
      endPoint: `CommonUtility/DocumentSentHistory?DocumentHistoryId=${id}`,
      method: "PUT",
      body: updateData,
      dispatch,
    });

    // Send notification
    const notificationData = {
      DocumentHistoryId: id,
      EmployeeId: DocumentData.EmployeeId,
    };

    const notificationResult=await WebService({
      endPoint: `Document/SendNotification`,
      method: "POST",
      body: notificationData,
      dispatch,
    }).then((res)=>{
      WSSuccessAlert('',`Document '${DocumentData?.DocumentTemplateName}' has been  signed successfully `)
    })
};


  return (
    <div className="w-100">
          <div
        className="p-4  bg-light mx-1 mx-lg-5 mx-md-5 shadow rounded d-flex flex-column flex-md-row flex-lg-row align-items-center gap-3 bg-white"
        style={{ borderRadius: '12px', maxWidth: '100%', margin: 'auto' }}
      >
        <img
          src={`${StandardConst.apiBaseUrl}/uploads/${DocumentData?.Logo}`}
          alt="Company Logo"
          className="  rounded-1 d-block border"
          style={{ width: '150px', height: '90px', objectFit: 'cover', border: '2px solid #007BFF' }}
        />
        <h4 className="mt-3 mb-0 text-primary font-weight-bold">
          {DocumentData?.CompanyName || 'Company Name'}
        </h4>
      </div>

    
      <div className=" d-flex justify-content-between align-items-center mx-1 mx-lg-5 mx-md-5">
        <div className="bg-white w-100 rounded-1 mt-1 p-4  ">
       <h5> '{subject}' {' '} sent by {DocumentData?.FullName} on { DocumentData?.SentTimeStamp && beautifyLocalTime(DocumentData?.SentTimeStamp)} </h5> 
       <span>This document requires your signature. Please take a print of the document atteched in email, sign it and upload a signed copy of the document.  </span>  
        <div className="my-4 d-flex flex-column  flex-md-row flex-lg-row justify-content-start gap-2">
           
             <div className="">
                  <label
                    htmlFor="inputGroupFile04"
                    className="btn btn-outline-primary d-flex align-items-center gap-2 mb-2 mb-md-0"
                  >
                    <CloudUpload />
                    <span>{file ? file.name : 'Upload Signed Document'}</span>
                    <input
                      type="file"
                      onChange={(e) => setfile(e.target.files[0])}
                      className="form-control d-none"
                      id="inputGroupFile04"
                    />
                  </label>

               </div>
              <Button variant="contained" startIcon={<Send/>} onClick={Uploadfile}> {" "} Send</Button>

          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadSignedDocument;
