import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { WebService } from "../../Services/WebService";
import { generateQueryStringByObject } from "../../Services/UtilityService";
import { findWhere, map } from "underscore";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Sector,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
} from "recharts";
import { Avatar, Box, TablePagination, alpha, styled, Grid, Paper } from "@mui/material";
import {
  AttendanceSummary,
  ContactUs,
  DateRange,
  EmployeeOnLeave,
  NotificationIcon,
  ProfileSummary,
} from "../Dashboard/Company/CompanyDashboard";

const AssetDashboard = () => {
  return (
    <div className='container'>
        <div className="row mb-2">
          <div className="col-md-5 col-sm-6 col-xs-6 card card-cd mb-3 ms-3" style={{width: window.innerWidth <= 600 ? "93%" : ""}}>
              <AssetTypePieChart/>
          </div>
          <div className="col-md-5 col-sm-6 col-xs-6 card card-cd mb-3 ms-3" style={{width: window.innerWidth <= 600 ? "93%" : ""}}>
              <AssetStatusDonutChart/>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-5 col-sm-6 col-xs-6 card card-cd mb-3 ms-3" style={{width: window.innerWidth <= 600 ? "93%" : ""}}>
            <AssetDepartmentChart/>
          </div>
          <div className="col-md-5 col-sm-6 col-xs-6 card card-cd mb-3 ms-3" style={{width: window.innerWidth <= 600 ? "93%" : ""}}>
            <AssetLocationChart/>
          </div>
          </div>
        <div className="row mb-2">
          <div className="col-md-5 col-sm-6 col-xs-6 card card-cd mb-3 ms-3" style={{width: window.innerWidth <= 600 ? "93%" : ""}}>
            <AssetUpcomingWarrentyExpiryChart />
          </div>
          <div className="col-md-5 col-sm-6 col-xs-6 card card-cd mb-3 ms-3" style={{width: window.innerWidth <= 600 ? "93%" : ""}}>
            <AssetsAssignedTopUsersChart />
          </div>
        </div>
    </div>
  )
}

const AssetTypePieChart = () => {

  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const dispatch = useDispatch();
  const [noData, setNoData] = useState([{ name: "No Asset Type", value: 1}]);
  const [AssetTypeList, setData] = useState([]);
  
  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Report/AssetsSummary?${generateQueryStringByObject({
        CompanyId: companyInfo.CompanyId,
      })}`,
    }).then((r) => {
      if(r.length <= 0){
        setData([{ name: "No Asset Type", value: 1}]);
      }else {
        setData(
          map(r, (m) => ({
            name: m.AssetTypeName,
            value: m.NoOfAssets,
          }))
        );
      }
    });
  };
  useEffect(() => {
    if ((companyInfo.CompanyId ?? 0) !== 0) Promise.all([fetchData()]);
  }, [companyInfo]);

  const COLORS = [
    StandardConst.GraphColors.blue,
    StandardConst.GraphColors.green,
    StandardConst.GraphColors.yellow,
    StandardConst.GraphColors.orange,
    StandardConst.GraphColors.red,
    StandardConst.GraphColors.lightGrey,
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if(AssetTypeList[0].name === "No Asset Type" && AssetTypeList[0].value === 1){
      percent = 0;
    }
    return (
      <text
        className="font-size-13"
        x={x}
        y={y}
        fill="#ffffff"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <>
      <div className="mb-3">
        <div className="d-flex flex-row m-2 mt-2">
          <div className="col-md-6 col-sm-6 ms-2 d-flex justify-content-start align-items-center">
            <h5>Asset Types</h5>
          </div>
          <div className="col-md-6 col-sm-6 d-flex justify-content-end"></div>
        </div>

        <div className="p-0 mb-2">
            <div className="row d-flex flex-row">
              <div className="col-7 d-flex flex-row justify-content-center align-items-center">
                <PieChart width={300} height={150}>
                  <Pie
                    data={AssetTypeList}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={60}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {AssetTypeList.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[entry.name === "No Asset Type" ? 5 : index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </div>
              <div className="col-5">
                {AssetTypeList?.map((m, index) => (
                  <div className="d-flex flex-row align-items-center font-size-13" key={index}>
                    <div>
                      <Box
                        sx={{
                          width: 5,
                          height: 20,
                          backgroundColor: COLORS[m.name === "No Asset Type" ? 5 : index % COLORS.length],
                        }}
                      />
                    </div>

                    <div className="d-flex flex-column justify-content-start align-items-start p-1">
                      <span>
                        {m.name} - <strong> {m.name === "No Asset Type" ? 0 : m.value}</strong>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
        </div>
      </div>
    </>
  );
};


const AssetStatusDonutChart = () => {
  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const dispatch = useDispatch();
  const [assetStatus, setAssetStatus] = useState([]);

  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Report/AssetsStatusSummary?${generateQueryStringByObject({
        CompanyId: companyInfo.CompanyId,
      })}`,
    }).then((r) => {
      const results = checkAllNullOrZero(r[0]);
      if (results) {
        setAssetStatus([{ name: "No", value: 1 }]);
      } else {
        setAssetStatus([
          { name: "InUse", value: parseInt(r[0].InUse ?? 0) },
          { name: "Available", value: parseInt(r[0].Available ?? 0) },
          { name: "Damaged", value: parseInt(r[0].Damaged ?? 0) },
          { name: "Lost", value: parseInt(r[0].Lost ?? 0) },
          { name: "GivenForRepair", value: parseInt(r[0].GivenForRepair ?? 0) },
          { name: "Retired", value: parseInt(r[0].Retired ?? 0) },
        ]);
      }
    });
  };

  function checkAllNullOrZero(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (obj[key] !== null && obj[key] !== 0) {
                return false; // Agar koi value null ya 0 nahi hai toh false return karein
            }
        }
    }
    return true; // Agar saari values null ya 0 hain toh true return karein
}

  useEffect(() => {
    if(companyInfo.CompanyId){
      Promise.all([fetchData()]);
    }
  }, [companyInfo]);

  const COLORS = [
    StandardConst.GraphColors.blue,
    StandardConst.GraphColors.green,
    StandardConst.GraphColors.yellow,
    StandardConst.GraphColors.orange,
    StandardConst.GraphColors.grey,
    StandardConst.GraphColors.red,
    StandardConst.GraphColors.lightGrey
  ];

  return (
    <>
      <div>
        <div className="d-flex flex-row m-2 mt-2">
          <div className="col-5 d-flex justify-content-start">
            <h5 className="card-title">Asset Status</h5>
          </div>
          <div className="col-7 d-flex justify-content-end"></div>
        </div>

        <div>
          <div className="row d-flex flex-row">
            <div className="col-md-7 col-sm-12 d-flex flex-row justify-content-center align-items-center">
              <PieChart width={150} height={150}>
                <Pie
                  data={assetStatus}
                  innerRadius={50}
                  outerRadius={60}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {assetStatus.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[entry.name === "No" ? 6 : index % COLORS.length]}
                    />
                    ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </div>
            <div className="col-md-5 col-sm-12 d-flex flex-md-column justify-content-center">
              <div className="d-flex flex-row align-items-center font-size-13">
                <div>
                  <Box
                    sx={{
                      width: 5,
                      height: 20,
                      backgroundColor: StandardConst.GraphColors.green,
                    }}
                  />
                </div>

                <div className="d-flex flex-column p-1">
                  <span>
                  Available -{" "}
                    <strong>
                      {" "}
                      {
                        findWhere(assetStatus, { name: "Available" })
                          ?.value
                      }
                    </strong>
                  </span>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center font-size-13">
                <div>
                  <Box
                    sx={{
                      width: 5,
                      height: 20,
                      backgroundColor: StandardConst.GraphColors.blue,
                    }}
                  />
                </div>

                <div className="d-flex flex-column p-1">
                  <span>
                  In Use -{" "}
                    <strong>
                      {" "}
                      {findWhere(assetStatus, { name: "InUse" })?.value}
                    </strong>
                  </span>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center font-size-13">
                <div>
                  <Box
                    sx={{
                      width: 5,
                      height: 20,
                      backgroundColor: StandardConst.GraphColors.yellow,
                    }}
                  />
                </div>

                <div className="d-flex flex-column p-1">
                  <span>
                  Damaged -{" "}
                    <strong>
                      {" "}
                      {
                        findWhere(assetStatus, { name: "Damaged" })
                          ?.value
                      }
                    </strong>
                  </span>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center font-size-13">
                <div>
                  <Box
                    sx={{
                      width: 5,
                      height: 20,
                      backgroundColor: StandardConst.GraphColors.orange,
                    }}
                  />
                </div>

                <div className="d-flex flex-column p-1">
                  <span>
                  Lost -{" "}
                    <strong>
                      {" "}
                      {
                        findWhere(assetStatus, { name: "Lost" })
                          ?.value
                      }
                    </strong>
                  </span>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center font-size-13">
                <div>
                  <Box
                    sx={{
                      width: 5,
                      height: 20,
                      backgroundColor: StandardConst.GraphColors.grey,
                    }}
                  />
                </div>

                <div className="d-flex flex-column p-1">
                  <span>
                  Given For Repair -{" "}
                    <strong>
                      {" "}
                      {
                        findWhere(assetStatus, { name: "GivenForRepair" })
                          ?.value
                      }
                    </strong>
                  </span>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center font-size-13">
                <div>
                  <Box
                    sx={{
                      width: 5,
                      height: 20,
                      backgroundColor: StandardConst.GraphColors.red,
                    }}
                  />
                </div>
                <div className="d-flex flex-column p-1">
                  <span>
                  Retired -{" "}
                    <strong>
                      {" "}
                      {
                        findWhere(assetStatus, { name: "Retired" })
                          ?.value
                      }
                    </strong>
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AssetDepartmentChart = () => {

  const dispatch = useDispatch();
  const [departmentData, setDepartmentData] = useState([]);

  const fetchAllDepartmentAssets = async () => {
    const data = await WebService({
      endPoint: `AssetAssignment/AssetAllDepartments`,
      dispatch,
    });
    if(data.length <= 0){
      setDepartmentData([{DepartmentName: "No Department", AssetCount: 0}])
    }else {
      setDepartmentData(data);
    }
  }

  useEffect(() => {
    fetchAllDepartmentAssets();
  }, [])

  return (
    <>
      <div>
        <div className="d-flex flex-row m-2 mt-2">
          <div className="col-12 d-flex justify-content-start">
            <h5 className="card-title">Department Status</h5>
          </div>
        </div>
        <BarChart width={290} height={200} data={departmentData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="DepartmentName" />
          <YAxis tickFormatter={(value) => Math.floor(value)} />
          <Tooltip />
          <Legend />
          <Bar dataKey="AssetCount" fill="#8884d8" />
        </BarChart>
      </div>
    </>
  );
};

const AssetLocationChart = () => {

  const dispatch = useDispatch();

  const [officeLocationData, setOfficeLocationData] = useState([]);

  const fetchAllOfficeLocations = async () => {
    const officeData = await WebService({
      endPoint: `AssetAssignment/fetchAssetAllOfficeLocations`,
      dispatch,
    });
    if(officeData.length <= 0){
      setOfficeLocationData([{Location: "No Office", AssetCount: 0}]);
    }else {
      setOfficeLocationData(officeData);
    }
  }

  useEffect(() => {
    fetchAllOfficeLocations();
  }, []);
  return (
    <>
      <div>
        <div className="d-flex flex-row m-2 mt-2">
          <div className="col-12 d-flex justify-content-start">
            <h5 className="card-title">Office Location Status</h5>
          </div>
        </div>
        <div>
          <BarChart width={290} height={200} data={officeLocationData} layout="vertical" margin={{left: 30}}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" tickFormatter={(value) => Math.floor(value)} />
            <YAxis dataKey="Location" type="category" />
            <Tooltip />
            <Legend />
            <Bar dataKey="AssetCount" fill="#A4A4A4" />
          </BarChart>
        </div>
      </div>
    </>
  )
}

const AssetUpcomingWarrentyExpiryChart = () => {

  const [expiryAssetsData, setExpiryAssetsData] = useState([]);
  const dispatch = useDispatch();
  const fetchTopAssetsExpires = async () => {
    const ExpireAssets = await WebService({
      endPoint: `AssetAssignment/fetchTopAssetExpireUpcomingMonths`,
      dispatch,
    });
    const dataObject = ExpireAssets[0];
    const dataArray = Object.keys(dataObject).map(key => {
        const monthData = parseInt(dataObject[key]);
        return { month: key, monthData: isNaN(monthData) ? 0 : monthData };
    });
    setExpiryAssetsData(dataArray);
  }

  useEffect(() => {
    fetchTopAssetsExpires();
  },[]);

  return (
    <>
      <div>
        <div className="d-flex flex-row m-2 mt-2">
          <div className="col-12 d-flex justify-content-start">
            <h5 className="card-title">Warranty Expiry Month</h5>
          </div>
        </div>
        <RadarChart outerRadius={90} width={360} height={230} data={expiryAssetsData}>
          <PolarGrid />
          <PolarAngleAxis dataKey="month" />
          <PolarRadiusAxis angle={30} domain={[1, 3]} tickFormatter={(value) => Math.floor(value)}/>
          <Radar name="Expiry Assets" dataKey="monthData" stroke="#FF6879" fill="#8884d8" fillOpacity={0.6} />
          <Legend />
          <Tooltip />
        </RadarChart>
      </div>
    </>
  )
}


const AssetsAssignedTopUsersChart = () => {
  const dispatch = useDispatch();

  const [topUsersData, setTopUsersData] = useState([]);

  const fetchTopAssetUsers = async () => {
    const usersData = await WebService({
      endPoint: `AssetAssignment/fetchTopAssetUsers`,
      dispatch,
    });
    if(usersData <= 0){
      setTopUsersData([{TotalAssetsAssigned : "No Users", Employees: 0, Users: 0}])
    }else {
      setTopUsersData(usersData);
    }
  }

  useEffect(() => {
    fetchTopAssetUsers();
  }, []);

  return (
    <>
      <div>
        <div className="d-flex flex-row m-2 mt-2">
          <div className="col-12 d-flex justify-content-start">
            <h5 className="card-title">Assigned Assets</h5>
          </div>
        </div>
        <BarChart width={290} height={200} data={topUsersData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="TotalAssetsAssigned" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Employees" fill="#FF8042" />
          <Bar dataKey="Users" fill="#A4A4A4" />
        </BarChart>
      </div>
    </>
  )
}

export default AssetDashboard